
import React, {useEffect,useState} from 'react';

import PropTypes from "prop-types"
import {
    
    Row,
    Col,
    Card,
    Button,
    CardBody,
    
  } from "reactstrap"
import SortableTree from 'react-sortable-tree';

import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app


export  const  SortTreeRelation =({treeData,saveTreeData,treeDataValue,removeNode})=> {

    return (
  
        <Card>

          <CardBody>
         
               
               <>
               {
                treeData.length !== 0 ? 
                <Row>
                <Col md={10}></Col>
                <Col md={2}><div className="mb-3">
                  <Button
                    type="submit"
                    color="success"
                   onClick={()=>saveTreeData()}
                  >
                   Save
                  </Button>
                  </div>
                  </Col>
              </Row>
                :''
               }
        
        {
          treeData.length !== 0 ?
          <div style={{ height: parseInt(treeData.length * 400) }}>
        <SortableTree
          treeData={treeData}
          onChange={treeData => treeDataValue(treeData)}
          isVirtualized={false}
          generateNodeProps={(rowInfo ,index) => ({
            buttons: [
              <i
              key={index}
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              style={{ cursor: "pointer" ,color: 'red'}}
              onClick={(event) => removeNode(rowInfo)}
          />
                      
                    
                      
                   ],
                })}
        />
      </div>
          
          :''
        }
          
      </>

          </CardBody>
        </Card>
     
    );
  
}
SortTreeRelation.propTypes = {
  isLoading: PropTypes.bool,
  treeData: PropTypes.array,
  saveTreeData:PropTypes.func,
  treeDataValue:PropTypes.func,
  removeNode:PropTypes.func
}
export default SortTreeRelation;