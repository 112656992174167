
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  Button,

} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

import { url } from "routes"
import { Link } from "react-router-dom"

import GalleryCardElementwithEvent from "UI/GalleryCardElementWithEvent"
import ReactTable from "UI/Table"


import { getMiscellaneous, getSingleMiscellaneous, postMiscellaneous,getMiscellaneousStatus } from "data/miscellaneous"
import InputFormElement from "UI/InputFormElement"
import { optionsDropDown } from "constants/layout"
import UiLoading from "UI/loading"
import { isEmpty } from "data/middlefunction"

const Miscellaneous = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [operationsTable, setOperationTable] = useState('')
  const [singleItem, setSingleItem] = useState({status:true})
  const [status, setStatus] = useState(true)
  const [title, setTitle] = useState('')
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const [inputType, setInputType] = useState([])
  const [cardElement, setCardElement] = useState([
    {
      name: 'Academic Year',
      title: 'Academic Year',
      active: 0,
      inactive: 0,
      total: 0,
      getStatus:getMiscellaneousStatus,
      getData:getMiscellaneous,
      postData:postMiscellaneous,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true, formatter: (cell, prod, index) => index + 1
        },
        {

          dataField: "name",
          text: "Academic Year",
          sort: true
        },
        {
          dataField: "from",
          text: "From",
          sort: true,
          formatter: (cell, prod, inde) => (
            `${prod.from.split('T')[0].split('-')[2]}-${prod.from.split('T')[0].split('-')[1]}-${prod.from.split('T')[0].split('-')[0]}`
          )
        },
        {
          dataField: "to",
          text: "To",
          sort: true,
          formatter: (cell, prod, inde) => (
            `${prod.to.split('T')[0].split('-')[2]}-${prod.to.split('T')[0].split('-')[1]}-${prod.to.split('T')[0].split('-')[0]}`
          )
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", cardElement[0].name, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", cardElement[0].name, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", cardElement[0].name, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [{ title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '', }, 
      { title: 'Start Date', name: 'from', type: 'date', value: '', disabled: '', required: true }, 
      { title: 'End Date', name: 'to', type: 'date', value: '', disabled: '', required: true }, 
      { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }],
      isLoading: true,
    },
    {
      name: 'Category',
      title: 'Category',
      active: 0,
      inactive: 0,
      total: 0,
      getStatus:getMiscellaneousStatus,
      getData:getMiscellaneous,
      postData:postMiscellaneous,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Name",
          sort: true,
        },
        {
          dataField: "order",
          text: "Order",
          sort: true,
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", cardElement[1].name, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted",cardElement[1].name, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View",cardElement[1].name, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'image', name: 'uploadtype', type: 'hidden', value: '', disabled: '' },
        { title: 'Name', name: 'name', type: 'text', value: '', disabled: '', required: true },
        { title: 'Celebration Year', name: 'year', type: 'multiple', value: [], outputData: getMiscellaneous, outputDropDown: 'name', table: 'Academic Year', status: true, disabled: '', required: true, gride: 12 },
        { title: 'Order Number', name: 'order', type: 'number', value: '', disabled: '', required: true },
        { title: 'Image', single: true, name: 'image', type: 'file', value: [],label:'Image', uploadtype: 'image', },
        { title: '', single: true, name: 'banner', type: 'file', value: [],label:'Banner Image', uploadtype: 'product',},
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
    },
    {
      name: 'Category Relation',
      title: 'Category Relation',
      active: 0,
      inactive: 0,
      total: 0,
      getStatus:getMiscellaneousStatus,
      getData:getMiscellaneous,
      postData:postMiscellaneous,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Name",
          sort: true,
        },
        {
          dataField: "order",
          text: "Order",
          sort: true,
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", cardElement[2].name, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", cardElement[2].name, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", cardElement[2].name, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Name', name: 'name', type: 'text', value: '', disabled: '', required: true },
        { title: 'Category', name: 'category', type: 'multiple', value: [], outputData: getMiscellaneous, outputDropDown: 'name', table: 'Category', status: true, disabled: '', required: true, gride: 12 },
        { title: 'Order Number', name: 'order', type: 'number', value: '', disabled: '', required: true },
        { title: 'Image', single: true, name: 'image', type: 'file', value: [],label:'Image', uploadtype: 'image', },
        { title: '', single: true, name: 'banner', type: 'file', value: [],label:'Banner Image', uploadtype: 'product',},
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
    },
    {
      name: 'Department',
      title: 'Department',
      active: 0,
      inactive: 0,
      total: 0,
      getStatus:getMiscellaneousStatus,
      getData:getMiscellaneous,
      postData:postMiscellaneous,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Department Name",
          sort: true,
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", cardElement[3].name, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", cardElement[3].name, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", cardElement[3].name, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Name', name: 'name', type: 'text', value: '', disabled: '', required: true },
        { title: 'Image', single: true, name: 'image', type: 'file', value: [],label:'Image', uploadtype: 'image', },
        { title: '', single: true, name: 'banner', type: 'file', value: [],label:'Banner Image', uploadtype: 'product',},
        { title: 'Description', name: 'description', type: 'description', value: '', disabled: '', required: false },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
    },
    {
      name: 'Designation',
      title: 'Designation',
      active: 0,
      inactive: 0,
      total: 0,
      getStatus:getMiscellaneousStatus,
      getData:getMiscellaneous,
      postData:postMiscellaneous,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Designation Name",
          sort: true,
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", cardElement[4].name, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", cardElement[4].name, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", cardElement[4].name, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Name', name: 'name', type: 'text', value: '', disabled: '', required: true },
        { title: 'Image', single: true, name: 'image', type: 'file', value: [],label:'Image', uploadtype: 'image', },
        { title: '', single: true, name: 'banner', type: 'file', value: [],label:'Banner Image', uploadtype: 'product',},
        { title: 'Description', name: 'description', type: 'description', value: '', disabled: '', required: false },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
    },
    {
      name: 'Type of Subject',
      title: 'Type of Subject',
      active: 0,
      inactive: 0,
      total: 0,
      getStatus:getMiscellaneousStatus,
      getData:getMiscellaneous,
      postData:postMiscellaneous,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Subject Name",
          sort: true,
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", cardElement[5].name, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", cardElement[5].name, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", cardElement[5].name, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [{ title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' }, 
      { title: 'Name', name: 'name', type: 'text', value: '', disabled: '', required: true }, 
      { title: 'Image', single: true, name: 'image', type: 'file', value: [],label:'Image', uploadtype: 'image', },
      { title: '', single: true, name: 'banner', type: 'file', value: [],label:'Banner Image', uploadtype: 'product',},
      { title: 'Description', name: 'description', type: 'description', value: '', disabled: '', required: false }, 
      { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }],
      isLoading: true,
    },
    {
      name: 'Subject Unit',
      title: 'Subject Unit',
      active: 0,
      inactive: 0,
      total: 0,
      getStatus:getMiscellaneousStatus,
      getData:getMiscellaneous,
      postData:postMiscellaneous,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "unit",
          text: "Subject Unit",
          sort: true,
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", cardElement[6].name, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", cardElement[6].name, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", cardElement[6].name, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [{ title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' }, 
      { title: 'Unit', name: 'unit', type: 'text', value: '', disabled: '', required: true },
      { title: 'Image', single: true, name: 'image', type: 'file', value: [],label:'Image', uploadtype: 'image', },
      { title: '', single: true, name: 'banner', type: 'file', value: [],label:'Banner Image', uploadtype: 'product',},
       { title: 'Description', name: 'description', type: 'description', value: '', disabled: '', required: true },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }],
      isLoading: true,
    },
    {
      name: 'Class Section',
      title: 'Class Section',
      active: 0,
      inactive: 0,
      total: 0,
      getStatus:getMiscellaneousStatus,
      getData:getMiscellaneous,
      postData:postMiscellaneous,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Section",
          sort: true,
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", cardElement[7].name, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", cardElement[7].name, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", cardElement[7].name, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [{ title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' }, 
      { title: 'Name', name: 'name', type: 'text', value: '', disabled: '', required: true }, 
      { title: 'Image', single: true, name: 'image', type: 'file', value: [],label:'Image', uploadtype: 'image', },
      { title: '', single: true, name: 'banner', type: 'file', value: [],label:'Banner Image', uploadtype: 'product',},
      { title: 'Description', name: 'description', type: 'description', value: '', disabled: '', required: true }, 
      { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }],
      isLoading: true,
    },
    {
      name: 'Type Of Exam',
      title: 'Type Of Exam',
      active: 0,
      inactive: 0,
      total: 0,
      getStatus:getMiscellaneousStatus,
      getData:getMiscellaneous,
      postData:postMiscellaneous,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "exam type",
          sort: true,
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", cardElement[8].name, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", cardElement[8].name, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", cardElement[8].name, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
      { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' }, 
      { title: 'exam', name: 'uploadtype', type: 'hidden', value: 'uploadtype', disabled: '' },
      { title: 'Name', name: 'name', type: 'text', value: '', disabled: '', required: true },
      { title: 'Image', single: true, name: 'image', type: 'file', value: [],label:'Image', uploadtype: 'exam', },
      { title: '', single: true, name: 'banner', type: 'file', value: [],label:'Banner Image', uploadtype: 'exam',},
       { title: 'Description', name: 'description', type: 'description', value: '', disabled: '', required: true },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }],
      isLoading: true,
    },
    {
      name: 'Class',
      title: 'Streams',
      active: 0,
      inactive: 0,
      total: 0,
      getStatus:getMiscellaneousStatus,
      getData:getMiscellaneous,
      postData:postMiscellaneous,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Class",
          sort: true,
        },
     
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", cardElement[9].name, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", cardElement[9].name, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", cardElement[9].name, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'class', name: 'uploadtype', type: 'hidden', value: 'uploadtype', disabled: '' },
        { title: 'Name', name: 'name', type: 'text', value: '', disabled: '', required: true },
     
        { title: 'Image', single: true, name: 'image', type: 'file', value: [],label:'Image', uploadtype: 'class', },
        { title: '', single: true, name: 'banner', type: 'file', value: [],label:'Banner Image', uploadtype: 'class',},
        { title: 'Description', name: 'description', type: 'description', value: '', disabled: '', required: true },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
    },
    {
      name: 'File Upload',
      title: 'File Upload',
      active: 0,
      inactive: 0,
      total: 0,
      getStatus:getMiscellaneousStatus,
      getData:getMiscellaneous,
      postData:postMiscellaneous,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Title",
          sort: true,
        },

        {
          dataField: "url",
          text: "URL",
          sort: true,
          formatter: (cell, prod, index) => (
            <Button className="btn" onClick={() => openUrlLink(prod.url, prod.file)}> Url Link</Button>
          )
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", cardElement[10].name, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", cardElement[10].name, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", cardElement[10].name, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'importtant', name: 'uploadtype', type: 'hidden', value: 'uploadtype', disabled: '' },
        { title: '', name: 'url', type: 'hidden', value: 'importtant', disabled: '' },
        { title: 'Name', name: 'name', type: 'text', value: '', disabled: '', required: true },
        { title: 'Image', multiple: true, name: 'file', type: 'file', value: [], uploadtype: 'importtant' },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
    },
    
    {
      name: 'Social Link',
      title: 'Social Link',
      active: 0,
      inactive: 0,
      total: 0,
      getStatus:getMiscellaneousStatus,
      getData:getMiscellaneous,
      postData:postMiscellaneous,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Social Media",
          sort: true,
        },
        {
          dataField: "image",
          text: "Image",
          sort: true,
          formatter: (cell, prod, index) => (
            <img src={prod.image !== undefined ? `${url.commonUrls}/image/${prod.uploadtype}/${prod.image}` : ''} width='30px' height='30px' />
          )
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", cardElement[11].name, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", cardElement[11].name, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", cardElement[11].name, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'sociallink', name: 'uploadtype', type: 'hidden', value: 'uploadtype', disabled: '' },
        { title: 'Name', name: 'name', type: 'text', value: '', disabled: '', required: true },
        { title: 'SVG Code', name: 'svgcode', type: 'text', value: '', disabled: '', required: '' },
        { title: 'Image', name: 'image', type: 'file', value: [], single: true, uploadtype: 'sociallink' },
        { title: 'Description', name: 'description', type: 'description', value: '', disabled: '', required: true },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
    },
    {
      name: 'Type Of Result',
      title: 'Type Of Result',
      active: 0,
      inactive: 0,
      total: 0,
      getStatus:getMiscellaneousStatus,
      getData:getMiscellaneous,
      postData:postMiscellaneous,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Social Media",
          sort: true,
        },
        {
          dataField: "image",
          text: "Image",
          sort: true,
          formatter: (cell, prod, index) => (
            <img src={prod.image !== undefined ? `${url.commonUrls}/image/${prod.uploadtype}/${prod.image}` : ''} width='30px' height='30px' />
          )
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", cardElement[12].name, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", cardElement[12].name, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", cardElement[12].name, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'mis', name: 'uploadtype', type: 'hidden', value: 'uploadtype', disabled: '' },
        { title: 'Name', name: 'name', type: 'text', value: '', disabled: '', required: true },
        { title: 'Image', name: 'image', type: 'file', value: [], single: true, uploadtype: 'mis' },
        { title: '', single: true, name: 'banner', type: 'file', value: [],label:'Banner Image', uploadtype: 'mis',},
        { title: 'Description', name: 'description', type: 'description', value: '', disabled: '', required: true },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
    },
    {
      name: 'Type Of Managment',
      title: 'Type Of Managment',
      active: 0,
      inactive: 0,
      total: 0,
      getStatus:getMiscellaneousStatus,
      getData:getMiscellaneous,
      postData:postMiscellaneous,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Social Media",
          sort: true,
        },
        {
          dataField: "image",
          text: "Image",
          sort: true,
          formatter: (cell, prod, index) => (
            <img src={prod.image !== undefined ? `${url.commonUrls}/image/${prod.uploadtype}/${prod.image}` : ''} width='30px' height='30px' />
          )
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", cardElement[13].name, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", cardElement[13].name, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", cardElement[13].name, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'mis', name: 'uploadtype', type: 'hidden', value: 'uploadtype', disabled: '' },
        { title: 'Name', name: 'name', type: 'text', value: '', disabled: '', required: true },
        { title: 'Image', single: true, name: 'image', type: 'file', value: [],label:'Image', uploadtype: 'mis', },
        { title: '', single: true, name: 'banner', type: 'file', value: [],label:'Banner Image', uploadtype: 'mis',},
        { title: 'Description', name: 'description', type: 'description', value: '', disabled: '', required: true },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
    },
    {
      name: 'Blog Category',
      title: 'Blog Category',
      active: 0,
      inactive: 0,
      total: 0,
      getStatus:getMiscellaneousStatus,
      getData:getMiscellaneous,
      postData:postMiscellaneous,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Social Media",
          sort: true,
        },
        {
          dataField: "image",
          text: "Image",
          sort: true,
          formatter: (cell, prod, index) => (
            <img src={prod.image !== undefined ? `${url.commonUrls}/image/${prod.uploadtype}/${prod.image}` : ''} width='30px' height='30px' />
          )
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", cardElement[14].name, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", cardElement[14].name, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", cardElement[14].name, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'mis', name: 'uploadtype', type: 'hidden', value: 'mis', disabled: '' },
        { title: 'Name', name: 'name', type: 'text', value: '', disabled: '', required: true },
        { title: 'Image', single: true, name: 'image', type: 'file', value: [],label:'Image', uploadtype: 'mis', },
        { title: '', single: true, name: 'banner', type: 'file', value: [],label:'Banner Image', uploadtype: 'mis',},
        { title: 'Description', name: 'description', type: 'description', value: '', disabled: '', required: true },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
    },
    {
      name: 'Club Category',
      title: 'Club Category',
      active: 0,
      inactive: 0,
      total: 0,
      getStatus:getMiscellaneousStatus,
      getData:getMiscellaneous,
      postData:postMiscellaneous,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Social Media",
          sort: true,
        },
        {
          dataField: "image",
          text: "Image",
          sort: true,
          formatter: (cell, prod, index) => (
            <img src={prod.image !== undefined ? `${url.commonUrls}/image/${prod.uploadtype}/${prod.image}` : ''} width='30px' height='30px' />
          )
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", cardElement[15].name, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", cardElement[15].name, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", cardElement[15].name, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'mis', name: 'uploadtype', type: 'hidden', value: 'mis', disabled: '' },
        { title: 'Name', name: 'name', type: 'text', value: '', disabled: '', required: true },
        { title: 'Image', single: true, name: 'image', type: 'file', value: [],label:'Image', uploadtype: 'mis', },
        { title: '', single: true, name: 'banner', type: 'file', value: [],label:'Banner Image', uploadtype: 'mis',},
        { title: 'Description', name: 'description', type: 'description', value: '', disabled: '', required: true },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
    },
  ])

  useEffect(async () => {
    const cardElements = [...cardElement]
    for (let i = 0; i < cardElements.length; i++) {
      await cardElements[i].getStatus(cardElements[i].name).then(result=>{
        console.log(result)
       if(result.success){
        cardElements[i].total = result.total;
        cardElements[i].active = result.active;
        cardElements[i].inactive = result.inactive;
        cardElements[i].isLoading=false
       }else{
        cardElements[i].total = 0;
        cardElements[i].active = 0;
        cardElements[i].inactive = 0;
        cardElements[i].isLoading=false
       }
      })
    }

    setCardElement([...cardElements])
    setIsLoading(false)
  }, [])
  const changeTable = async (e, type, titles, prod) => {
    setIsLoading(true)
    const cardElements = [...cardElement]

    setTitle(titles)
  if (type == 'Table' || type == true || type == false) {

      setColumns(cardElements.find(d => d.name === titles).HeaderColumns)
      setOperationTable('Table')
      singleItem.status = true
      setSingleItem({ ...singleItem })
      await getMiscellaneous(titles, 'All').then(result => {
        if (result.success) {
          setData(result.data)
        } else {
          setData([])
        }
      })
      setIsLoading(false)
    } else if (type == 'View' || type == 'Deleted' || type == 'Edit') {

    
     


      await getMiscellaneous(titles, prod._id).then(async result => {

        if (result.success) {
          for (let j = 0; j < cardElements.find(d => d.name === titles).inputType.length; j++) {

            if (cardElements.find(d => d.name === titles).inputType[j].type === 'multiple' || cardElements.find(d => d.name === titles).inputType[j].type === 'single') {
              await cardElements.find(d => d.name === titles).inputType[j].outputData(cardElements.find(d => d.name === titles).inputType[j].table, true).then(results => {
                for (let k = 0; k < results.data.length; k++) {
                  Object.keys(results.data[k]).filter(q => q === 'subject')
    
                  let data = {
                    label: results.data[k][Object.keys(results.data[k]).filter(q => q === cardElements.find(d => d.name === titles).inputType[j].outputDropDown)],
                    value: results.data[k]._id,
                  }
                  cardElements.find(d => d.name === titles).inputType[j].value.push(data)
                }
              })
              setInputType([...cardElements.find(d => d.name === titles).inputType])
              setOperationTable(type)
              setSingleItem({ ...result.data })
            }else{
              setInputType([...cardElements.find(d => d.name === titles).inputType])
          setOperationTable(type)
          setSingleItem({ ...result.data }) 
            }
          }
          setInputType([...cardElements.find(d => d.name === titles).inputType])
          setOperationTable(type)
          setSingleItem({ ...result.data })
        } else {
          setSingleItem({})
        }

      })

      setIsLoading(false)
    } else if (type == 'Add') {
      for (let j = 0; j < cardElements.find(d => d.name === titles).inputType.length; j++) {

        if (cardElements.find(d => d.name === titles).inputType[j].type === 'multiple' || cardElements.find(d => d.name === titles).inputType[j].type === 'single') {
          await cardElements.find(d => d.name === titles).inputType[j].outputData(cardElements.find(d => d.name === titles).inputType[j].table, true).then(results => {
            for (let k = 0; k < results.data.length; k++) {
              Object.keys(results.data[k]).filter(q => q === 'subject')

              let data = {
                label: results.data[k][Object.keys(results.data[k]).filter(q => q === cardElements.find(d => d.name === titles).inputType[j].outputDropDown)],
                value: results.data[k]._id,
              }
              cardElements.find(d => d.name === titles).inputType[j].value.push(data)
            }
          })
        }
      }

      setInputType([...cardElements.find(d => d.name === titles).inputType])
      setOperationTable('Add')
      setIsLoading(false)
  
          }
  }


  const postData = (e, values) => {

    const cardElements = [...cardElement]

    if (values[Object.keys(values).filter(k => k == 'status')] === 'false') {
      values[Object.keys(values).filter(k => k == 'status')] = false
    }
    if (operationsTable == 'Add') {
      delete values['_id'];
    }
    if(values.name !== undefined ){
      values['slug'] = values.name.replace(/[^\w\s]/gi, '').split(' ').join('-').toLowerCase()
      values['metatitle'] = values.name
  }
    postMiscellaneous(isEmpty(values), title, operationsTable).then(result => {
      if (result.success) {
        setColumns([...cardElements.find(d => d.name === title).HeaderColumns])
        setOperationTable('Table')
        getMiscellaneous(title, status).then(result => {
          if (result.success) {
            setData(result.data)
          } else {
            setData([])
          }

        })
      } else {
        alert('Hello')
      }
    })
  }
  const openUrlLink = (d, k) => {
    window.open(
      `${url.commonUrls}/image/${d}/${k[0]}`,
      '_blank' // <- This is what makes it open in a new window.
    );

  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Miscellaneous | {url.title}</title>
        </MetaTags>
        <Container fluid>
          {
            operationsTable == '' ?
              <Breadcrumbs
                title={"Dashboard"}
                breadcrumbItem={"Miscellaneous"}
                bredcumlink={"dashboard"}
              />
              : <Breadcrumbs
                title={'Miscellaneous'}
                breadcrumbItem={title}
                bredcumlink={'miscellaneous'}
              />
          }

          <Row>
            {

              operationsTable == '' ?
                cardElement.map((d, index) => (
                  <Col sm={4} md={3} key={index}>
                    {
                      isLoading ?

                        <UiLoading />

                        :
                        <GalleryCardElementwithEvent
                          data={d}
                          changeTable={(e, type, title) => changeTable(e, type, title)}
                        />
                    }

                  </Col>
                ))
                :
                operationsTable == 'Table' ?
                  <Card>
                    {
                      isLoading ?
                        <UiLoading />
                        : <ReactTable
                          title={title}
                          datas={data}
                          changeTable={(e, type, title) => changeTable(e, type, title)}
                          columns={columns}
                        />
                    }

                  </Card>
                  :
                  operationsTable == 'Add' || operationsTable == 'Edit' || operationsTable == 'Deleted' || operationsTable == 'View' ?
                    <>
                      {
                        isLoading ?
                          <UiLoading />
                          :
                          <InputFormElement
                            operationsTable={operationsTable}
                            singleItem={singleItem}

                            bredcumlink={'miscellaneous'}
                            viewIcon={false}
                            filetype='single'

                            title={title}
                            changeTable={(e, type, title) => changeTable(e, type, title)}
                            inputType={inputType}
                            postData={postData}
                          />
                      }
                    </>
                    : ''
            }

          </Row>
        </Container>
      </div>
    </React.Fragment >
  )
}



export default withTranslation()(Miscellaneous)
