import Dashboard from "../pages/Dashboard/index"
import systemadmin from "pages/systemadmin"
import cms from "pages/cms"
import company from "pages/systemadmin/company"
import employee from "pages/systemadmin/employee"
import banner from "pages/cms/banner"
import miscellaneous from "pages/systemadmin/miscellaneous"
import HeaderNavigation from "pages/menumangment/headernavigation"
import FooterNavigation from "pages/menumangment/footernavigation"
import HeaderTree from "pages/menumangment/headerTree"
import FooterTree from "pages/menumangment/footerTree"
import MenuMangmentData from "pages/menumangment"
import DynamicPageStructure from "pages/pageStructure"
import pageStructure from "pages/pageStructure/pageStructure"
import pageStructureLink from "pages/pageStructure/pageStructureLink"
import PageStructureDescription from "pages/pageStructure/pageDescription"
import PublishedLocationList from "pages/pageStructure/publishedArea"
import PromoDashBoard from "pages/Promo"
import LiveNewsDashBoard from "pages/LiveNewsEvent"
import SeoDashboard from "pages/SEO"
import SeoPageStructure from "pages/SEO/AllPageSeo"
import AllPageSeo from "pages/SEO/AllDynamicPages"
import SeoCategoryPageStructure from "pages/SEO/AllPageSeo"
import SeoAllOtherPageStructure from "pages/SEO/AllConstPageSeo"
import BlogPageSeo from "pages/SEO/Blog"
import InformationPageSeo from "pages/SEO/Information"
import LiveStream from "pages/LiveNewsEvent/LiveStream"
import Blog from "pages/cms/blog"
import CustomerDashBoard from "pages/Customer"
import BlogReview from "pages/Customer/BlogReview"
import CareerApply from "pages/Customer/CareerApply"
import NewsPageReview from "pages/Customer/NewsPageReview"
import Customer from "pages/Customer/RegisterUser"
import SalesPromoList from "pages/Promo/SalesPromo"
import PollParticipate from "pages/Customer/PollParticipate"
import CareerSeo from "pages/SEO/Career"
import PollSeo from "pages/SEO/Poll"
import AllEvent from "pages/School/Event"
import ManagMentStaff from "pages/School/Staff"
import Cabinate from "pages/School/Cabinate"
import AllStudent from "pages/School/Student"
import AllResult from "pages/School/Result"
import AllGallery from "pages/School/Gallery"
import EventDescription from "pages/School/EventDescription"
import AllNotice from "pages/School/Notice"
import DepartmentStructure from "pages/Department"
import QuestionBankLink from "pages/Department/QuestionBankLink"
import StudyTourLink from "pages/Department/StudyTourLink"
import SeminarLink from "pages/Department/Seminar"
import Result from "pages/Department/Result"
import AllReport from "pages/School/Report"
import CourseOffered from "pages/Department/courseoffered"
const commonUrl = "https://sciencecollegekonkorada.com"
// const commonUrl = "http://localhost:5000"
const systemadminMenu = [
  {
    path: "/dashboard",
    title: "Home",
  },
  {
    path: "/system-admin",
    title: "Dashboard",
  },
  {
    path: "/company",
    title: "Collage",
  },
  {
    path: "/employee",
    title: "Employee",
  },
  {
    path: "/miscellaneous",
    title: "Miscellaneous",
  },
]
const cmsMenu = [
  {
    path: "/dashboard",
    title: "Dashboard",
  },
  {
    path: "/cms",
    title: "CMS Dashboard",
  },
  {
    path: "/cms-page",
    title: "Page Contents",
  },
 
  {
    path: "/banner",
    title: "Banner",
  },
 
  
]
//Custmer
const customerMenu = [
  {
    path: "/dashboard",
    title: "Dashboard",
  },
  {
    path: "/customer-dashboard",
    title: "Citizen Dashboard",
  },
  {
    path: "/customer",
    title: "Subcriber",
  },
 
  {
    path: "/news-review",
    title: "News",
  },
  {
    path: "/blog-review",
    title: "Blog",
  },
  {
    path: "/career-apply",
    title: "Job",
  },
  {
    path: "/poll-participate",
    title: "Poll",
  },
]
const schoolMenu = [
  {
  path: "/dashboard",
  title: "Dashboard",
  },
  {
    path: "/school-dashboard",
    title: "Collage Dashboard",
  },
  {
    path: "/all-teacher",
    title: "Teacher",
  },
  {
    path: "/all-result",
    title: "Result",
  },
  {
    path: "/all-cabinate",
    title: "Cabinate",
  },
  {
    path: "/all-gallery",
    title: "Gallery",
  },
  {
    path: "/all-report",
    title: "Report",
  },
  {
    path: "/all-event",
    title: "Event",
  },
  {
    path: "/event-description",
    title: "Event Description",
  },
  {
    path: "/all-notice",
    title: "Notice",
  },
]
const seoMenu =[
  {
    path:'/dashboard',
    title:'Dashboard'
  },
  {
    path:'/seo-dashboard',
    title:'Seo Dashboard'
  },
  {
    path:'/category-seo',
    title:'Seo'
  },
  {
    path:'/page-seo',
    title:'Dynamic'
  },
  {
    path:'/all-page-seo',
    title:'Dynamic Constant'
  },
  {
    path:'/constant-page-seo',
    title:'Constant'
  },
  {
    path:'/blog-seo',
    title:'Blog'
  },
  {
    path:'/poll-seo',
    title:'Poll'
  },
  {
    path:'/career-seo',
    title:'Career'
  },
  {
    path:'/information-seo',
    title:'Information'
  },
]
const menuMangment = [
  {
    path: "/dashboard",
    title: "Dashboard",
  },
  {
    path: "/menu-management",
    title: "Navigation Dashboard",
  },
  {
    path: "/header-navigation",
    title: "Header Navigation",
  },

  {
    path: "/header-tree",
    title: "Header Navigation Structure",

  },
  {
    path: "/footer-navigation",
    title: "Footer Navigation",

  },
  {
    path: "/footer-tree",
    title: "Footer Navigation Structure",

  }
]


const DynamicPageStructureMenu=[
  {
    path: "/dashboard",
    title: "Dashboard",
  },
  {
    path: "/dynamic-page-structure",
    title: "Dynamic Pages Dashboard",
  },
  {
    path: "/published-area",
    title: "Publish Area",
  },
  {
    path: "/page-structure",
    title: "Page Maker",
  },
  {
    path: "/page-structure-link",
    title: "Page Manager",
  },
  {
    path: "/page-description",
    title: "Content Manager",
  },

]
const promoMenu=[ 
{
  path: "/dashboard",
  title: "Dashboard",
}, 
{
  path: "/promo-dashboard",
  title: "Adv Dashboard",
},
{
  path: "/sales-promo",
  title: "Advertisements",
}, 

]
const departmentMenu=[
  {
    path: "/dashboard",
    title: "Dashboard",
  }, 
  {
    path: "/department-dashboard",
    title: "Department Dashboard",
  },
  {
  path: "/seminar",
  title: "Seminar",
  },
  {
  path: "/question-bank",
  title: "Question Bank",
  },
  {
    path: "/study-tour",
    title: "Study Tour",
    },
    {
      path: "/result",
      title: "Result",
      },
      {
        path: "/exam-pattern",
        title: "Exam Pattern",
        },
]

const authProtectedRoutes = [
  {
    path: "/dashboard", component: Dashboard, 
    menu: [
      {
        path: "/system-admin",
        title: "System Admin",
      },
      {
        path: "/dynamic-page-structure",
        title: "Dynamic Pages",
      },
      {
        path: "/school-dashboard",
        title: "Collage",
      },
      {
        path: "/department-dashboard",
        title: "Department",
      },
      {
        path: "/menu-management",
        title: "Menu Managemant",
      },
      {
        path: "/cms",
        title: "CMS",
      },
      {
        path: "/customer-dashboard",
        title: "Citizen",
      },
      {
        path: "/promo-dashboard",
        title: "Adv Section",
      },
    {
        path: "/seo-dashboard",
        title: "SEO",
      },
    ],
  },
  //System Admin
  { path: "/system-admin", component: systemadmin, menu: systemadminMenu },
  { path: "/company", component: company, menu: systemadminMenu },
  { path: "/employee", component: employee, menu: systemadminMenu },
  { path: "/miscellaneous", component: miscellaneous, menu: systemadminMenu },
  //Dynamic Section
  { path: "/dynamic-page-structure", component: DynamicPageStructure, menu: DynamicPageStructureMenu },
  { path: "/page-structure", component: pageStructure, menu: DynamicPageStructureMenu },
   { path: "/page-structure-link", component: pageStructureLink, menu: DynamicPageStructureMenu },
  { path: "/page-description", component: PageStructureDescription, menu: DynamicPageStructureMenu },
  { path: "/published-area", component: PublishedLocationList, menu: DynamicPageStructureMenu },
 
  //Promo
  { path: "/promo-dashboard", component:PromoDashBoard, menu: promoMenu },
  { path: "/promo-dashboard", component:PromoDashBoard, menu: promoMenu },
  { path: "/sales-promo", component:SalesPromoList, menu: promoMenu },
  //CMS
  { path: "/cms", component: cms, menu: cmsMenu },
  { path: "/banner", component: banner, menu: cmsMenu },
  { path: "/cms-page", component: Blog, menu: cmsMenu },
  //News
  { path: "/school-dashboard", component: LiveNewsDashBoard, menu: schoolMenu },
  // { path: "/cabinate", component: Cabinate, menu: schoolMenu },
  { path: "/all-event", component: AllEvent, menu: schoolMenu },
  { path: "/event-description", component: EventDescription, menu: schoolMenu },
  { path: "/all-student", component: AllStudent, menu: schoolMenu },
  { path: "/all-result", component: AllResult, menu: schoolMenu },
  { path: "/all-cabinate", component: Cabinate, menu: schoolMenu },
  { path: "/all-teacher", component: ManagMentStaff, menu: schoolMenu },
  { path: "/all-gallery", component: AllGallery, menu: schoolMenu },
  { path: "/all-report", component: AllReport, menu: schoolMenu },
  { path: "/all-notice", component: AllNotice, menu: schoolMenu },
 //Department
  { path: "/department-dashboard", component: DepartmentStructure, menu: departmentMenu },
  { path: "/question-bank", component: QuestionBankLink, menu: departmentMenu },
  { path: "/study-tour", component: StudyTourLink, menu: departmentMenu },
  { path: "/seminar", component: SeminarLink, menu: departmentMenu },
  { path: "/result", component: Result, menu: departmentMenu },
  { path: "/exam-pattern", component: CourseOffered, menu: departmentMenu },
  //Customer
  { path: "/customer-dashboard", component: CustomerDashBoard, menu: customerMenu },
  { path: "/customer-dashboard", component: CustomerDashBoard, menu: customerMenu },
  { path: "/customer", component: Customer, menu: customerMenu },
  { path: "/poll-participate", component: PollParticipate, menu: customerMenu },
  
  { path: "/blog-review", component: BlogReview, menu: customerMenu },
  { path: "/news-review", component: NewsPageReview, menu: customerMenu },
  { path: "/career-apply", component: CareerApply, menu: customerMenu },
  //Menu Mangment
  { path: "/menu-management", component: MenuMangmentData, menu: menuMangment },
  { path: "/header-navigation", component: HeaderNavigation, menu: menuMangment },

  { path: "/footer-navigation", component: FooterNavigation, menu: menuMangment },
  { path: "/footer-tree", component: FooterTree, menu: menuMangment },
  { path: "/header-tree", component: HeaderTree, menu: menuMangment },
  //Seo
  { path: "/seo-dashboard", component: SeoDashboard, menu: seoMenu },
  { path: "/category-seo", component: SeoCategoryPageStructure, menu: seoMenu },
  { path: "/page-seo", component: SeoPageStructure, menu: seoMenu },
  { path: "/all-page-seo", component: SeoAllOtherPageStructure, menu: seoMenu },
  { path: "/blog-seo", component: BlogPageSeo, menu: seoMenu },
  { path: "/information-seo", component: InformationPageSeo, menu: seoMenu },
  { path: "/career-seo", component: CareerSeo, menu: seoMenu },
  { path: "/poll-seo", component: PollSeo, menu: seoMenu },
]

const url = {
  title: "",
  pagination: 2,
  commonUrls: `${commonUrl}`,
  //systemAdmin
  authentication: `${commonUrl}/api/v1/authentication`,
  company: `${commonUrl}/api/v1/company`,

  employee: `${commonUrl}/api/v1/employee`,

  policymaster: `${commonUrl}/api/v1/policymaster`,
  miscellaneous: `${commonUrl}/api/v1/miscellaneous`,
  //Page
  page: `${commonUrl}/api/v1/page`,
  pageLink: `${commonUrl}/api/v1/page-link`,
  pageLocation: `${commonUrl}/api/v1/page-location`,
  pageDescription: `${commonUrl}/api/v1/page-description`,
  lineNews: `${commonUrl}/api/v1/live-news`,
  //Customer
  customer: `${commonUrl}/api/v1/customer`,
  newsreview: `${commonUrl}/api/v1/news-review`,
  blogreview: `${commonUrl}/api/v1/blog-review`,
  careerapply: `${commonUrl}/api/v1/career-apply`,
  pollparticipate: `${commonUrl}/api/v1/poll-participate`,
  //Sales Promo
  salespromo: `${commonUrl}/api/v1/sales-promo`,
  //cms
  banner: `${commonUrl}/api/v1/banner`,
  informationpage: `${commonUrl}/api/v1/informationpage`,
  blog: `${commonUrl}/api/v1/blog`,
  //marketing
  marketing: `${commonUrl}/api/v1/marketing`,
  analytics: `${commonUrl}/api/v1/analytics`,

  //Advon
  dynamicmenu: `${commonUrl}/api/v1/dynamicmenu`,
  service: `${commonUrl}/api/v1/service`,
  //School
  event :`${commonUrl}/api/v1/event`,
  gallery :`${commonUrl}/api/v1/gallery`,
  report :`${commonUrl}/api/v1/report`,
  teacher :`${commonUrl}/api/v1/teacher`,
  student :`${commonUrl}/api/v1/student`,
  cabinate :`${commonUrl}/api/v1/cabinate`,
  result :`${commonUrl}/api/v1/result`,
  notice :`${commonUrl}/api/v1/notice`,
  eventdescription :`${commonUrl}/api/v1/event-description`,
  department:`${commonUrl}/api/v1/department`,
  //Menu Mangmnet 
  menumanagment: `${commonUrl}/api/v1/menumanagment`,
  menutree: `${commonUrl}/api/v1/menutree`,
  footermenumanagment: `${commonUrl}/api/v1/footermenumanagment`,
  footertree: `${commonUrl}/api/v1/footertree`,
  fileUpload: `${commonUrl}/api/v1/fileupload`,
  //SEO Page
  AllPageSeo: `${commonUrl}/api/v1/all-seo-page`,
  AllDynamicPages: `${commonUrl}/api/v1/all-dynamic-page`,
  AllConstPageSeo: `${commonUrl}/api/v1/all-constant-page`,
  informationseo: `${commonUrl}/api/v1/information-seo`,
  blogseo: `${commonUrl}/api/v1/blog-seo`,
  
}

export { authProtectedRoutes, url }
