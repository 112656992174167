import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardBody
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

import { url } from "routes"
import { Link } from "react-router-dom"

import GalleryCardElementwithEvent from "UI/GalleryCardElementWithEvent"
import ReactTable from "UI/Table"


import { getCompany, postCompany } from "data/systemadmin/company"
import InputFormElement from "UI/InputFormElement"
import { associate, validation } from "constants/layout"
import UiLoading from "UI/loading"
import { getMiscellaneous } from "data/systemadmin/miscellaneous"
import { isEmpty } from "data/middlefunctions"
import { getEmployee } from "data/systemadmin/employee"
const Company = () => {
  const [titleData,setTitleData]= useState(localStorage.getItem('company')!== undefined && localStorage.getItem('company')!== null ?JSON.parse(localStorage.getItem('company')) :{})
  const [addButton,setAddButton] = useState(true)
  const [indexValue,setIndexValue] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [operationsTable, setOperationTable] = useState('')
  const [singleItem, setSingleItem] = useState({status:true,bankstatus:'Yes'})
  const [status, setStatus] = useState('All')
  const [title, setTitle] = useState('')
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const [inputType, setInputType] = useState([])
  const [cardElement, setCardElement] = useState([
    {
      name: 'Company Master',
      title: 'School Master',
      active: 0,
      inactive: 0,
      total: 0,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Company Name",
          sort: true,
        },
        {
          dataField: "alias",
          text: "Alias",
          sort: true,
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 0, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 0, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 0, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Name', name: 'name', type: 'text', value: '', disabled: '', required: '', gride: 4 },
        { title: 'Alias', name: 'alias', type: 'text', value: '', disabled: '', required: true, gride: 4 },
        { title: 'Header Logo', name: 'logo', type: 'file', value: [],label:'Logo',uploadtype:'school',single:true, disabled: '', required: '', gride: 4 },
        { title: 'Fav Icon', name: 'favicon', type: 'file', value: [],label:'Fav Icon',uploadtype:'school',single:true, disabled: '', required: true, gride: 4 },
        { title: 'Footer Logo', name: 'footerlogo', type: 'file', value: [],label:'Footer Logo',uploadtype:'school',single:true, disabled: '', required: true, gride: 4 },
        { title: 'Website', name: 'website', type: 'text', value: '', disabled: '', required: true, gride: 4 },
        { title: 'Social Link', name: 'sociallink', type: 'multiple', value: [], outputData: getMiscellaneous, outputDropDown: 'name', table: 'Social Link', status: true, disabled: '', required: true, gride: 4 },
        { title: 'Address', name: 'address', type: 'textarea', value: '', disabled: '', required: true, gride: 4 },
        { title: 'Zip Code', name: 'postcode', type: 'text', value: '', disabled: '', required: true, gride: 4 },
        { title: 'City', name: 'city', type: 'select', value: [], outputData: getMiscellaneous, outputDropDown: 'name', table: 'City', status: true, disabled: '', required: true, gride: 4 },
        { title: 'Country', name: 'country', type: 'select', value: [], outputData: getMiscellaneous, outputDropDown: 'country', table: 'Country', status: true, disabled: '', required: true, gride: 4 },
        { title: 'Email', name: 'email', type: 'text', value: '', disabled: '', required: true, gride: 4 },
        { title: 'Phone 1', name: 'phone1', type: 'text', value: '', disabled: '', required: true, gride: 4 },
        { title: 'Phone 2', name: 'phone2', type: 'text', value: '', disabled: '', required: '', gride: 4 },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
      addButton:false,
    },
    {
      name: 'Branch Setup',
      title: 'Branch Setup',
      active: 0,
      inactive: 0,
      total: 0,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Branch Name",
          sort: true,

        },
        {
          dataField: "alias",
          text: "Branch Type",
          sort: true,

        },
  
        {
          dataField: "name",
          text: "Branch City",
          sort: true,
          formatter: (cell, prod, index) =>
          prod.city !== undefined && prod.city !== null? prod.city.name : 'No City'
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 1, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 1, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 1, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Name', name: 'name', type: 'text', value: '', disabled: '', required: true, gride: 4 },
        { title: 'Branch Type', name: 'alias', type: 'text', value: '', disabled: '', required: true, gride: 4 },
        { title: 'Address', name: 'address', type: 'textarea', value: '', disabled: '', required: true, gride: 4 },
        { title: 'Address', name: 'address', type: 'textarea', value: '', disabled: '', required: true, gride: 4 },
        { title: 'Address', name: 'address', type: 'textarea', value: '', disabled: '', required: true, gride: 4 },
        { title: 'Postcode', name: 'postcode', type: 'text', value: '', disabled: '', required: true, gride: 4 },
        { title: 'City', name: 'city', type: 'select', value: [], outputData: getMiscellaneous, outputDropDown: 'name', table: 'City', status: true, disabled: '', required: true, gride: 4 },
        { title: 'Country', name: 'country', type: 'select', value: [], outputData: getMiscellaneous, outputDropDown: 'country', table: 'Country', status: true, disabled: '', required: true, gride: 4 },
        { title: 'Email', name: 'email', type: 'text', value: '', disabled: '', required: true, gride: 4 },
        // { title: 'Associate', name: 'associate', type: 'multiple', value: [], select: [], disabled: '', required: true, gride: 4 },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
      addButton:true,
    },
    {
      name: 'Our Principal',
      title: 'Incumbencies',
      active: 0,
      inactive: 0,
      total: 0,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Name",
          sort: true,

        },
      {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 2, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 2, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 2, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
       
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '',  },
        { title: 'teacher', name: 'uploadtype', type: 'hidden', value: 'uploadtype', disabled: '', },
         { title: 'Name', name: 'name', type: 'text', value: '', disabled: '', required: true },
        { title: 'Joining Date', name: 'from', type: 'date', value: '', disabled: '', required: '' },
        {title: 'Leaving Date', name: 'to', type: 'date', value: '', disabled: '', required: '' },
        {title: 'Designation', name: 'designation', type: 'designation', value: '', disabled: '', required: '' },  
        {title: 'Order', name: 'order', type: 'number', value: '', disabled: '', required: '' }, 
        { title: 'Qualification', name: 'qualification', type: 'multiple', value: [], outputData: getEmployee, outputDropDown: 'name', table: 'Qualification', status: true, disabled: '', required: true , gride: 12 },
        {title: 'Message', name: 'message', type: 'textarea', value: '', disabled: '', required: '' },  
        { title: 'Common Description', name: 'description', type: 'description', value: '', disabled: '', required: true },
        { title: 'Image', name: 'image', type: 'file', value: [],label:'Photo',uploadtype:'teacher',multiple:true, disabled: '', required: true, gride: 12 },
        { title: 'Incumbencies Status', name: 'incumbenciesstatus', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'President', name: 'incumbenciesstatus', value: 'president' }, { title: 'Principal', name: 'incumbenciesstatus', value: 'principal' }] },
        { title: 'Service Status', name: 'teacherstatus', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Continuing', name: 'teacherstatus', value: true }, { title: 'Relieved', name: 'teacherstatus', value: false }] },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
      addButton:true,
    },
    {
      name: 'Company Social Link',
      title: 'Company Social Link',
      active: 0,
      inactive: 0,
      total: 0,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Company Name",
          sort: true,

        },
       
  
      
        {
          dataField: "",
          text: "Socail",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.sociallink.length !== 0 ?  <div className="mb-3">
              {
                prod.sociallink.map((f,i)=>(
                  <Button
                  type="submit"
                  color="success" key={i}
                  onClick={()=>addDescription(prod,f,3)}
                  >
                    {f.name}
                  </Button>
                ))
              }
             

            </div> : <p>OFF</p>,
        },
       
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'company', name: 'company', type: 'hidden', value: 'company', disabled: '' },
        { title: 'sociallink', name: 'sociallink', type: 'hidden', value: 'sociallink', disabled: '',gride: 12 },
        { title: 'Company Name', name: 'name', type: 'text', value: '', disabled: true, required: '', gride: 12 },
        { title: 'Social Link', name: 'social', type: 'text', value: '', disabled: true, required: '', gride: 12 },
        { title: 'URL', name: 'url', type: 'text', value: '', disabled: '', required: '', gride: 12 },
        { title: 'Authentication', name: 'authentication', type: 'text', value: '', disabled: '', required: '', gride: 12 },
     
      ],
      isLoading: true,
      addButton:false,
    },
    {
      name: 'Home Banner',
      title: 'Home Banner',
      active: 0,
      inactive: 0,
      total: 0,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Name",
          sort: true,

        },
      {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 4, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 4, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 4, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
       
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '',  },
        { title: 'school', name: 'uploadtype', type: 'hidden', value: 'uploadtype', disabled: '', },
        { title: 'Name', name: 'name', type: 'text', value: '', disabled: '', required: true },
        { title: 'Banner Description', name: 'description', type: 'description', value: '', disabled: '', required: true },
        { title: 'Image', name: 'image', type: 'file', value: [],label:'Photo',uploadtype:'school',single:true, disabled: '', required: true, gride: 12 },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
      addButton:true,
    },
  ])

  useEffect(async () => {
    const cardElements = [...cardElement]
    for (let i = 0; i < cardElements.length; i++) {
      await getCompany(cardElements[i].name, status).then(async result => {
        for (let j = 0; j < cardElements[i].inputType.length; j++) {

          if (cardElements[i].inputType[j].type === 'multiple' || cardElements[i].inputType[j].type === 'select') {
            await cardElements[i].inputType[j].outputData(cardElements[i].inputType[j].table, cardElements[i].inputType[j].status).then(results => {
              console.log(result);
              for (let k = 0; k < results.data.length; k++) {


                let data = {
                  label: results.data[k][Object.keys(results.data[k]).filter(q => q === cardElements[i].inputType[j].outputDropDown)],
                  value: results.data[k]._id,
                }
                cardElements[i].inputType[j].value.push(data)
              }





            })
          }
        }
        cardElements[i].isLoading = false;
        cardElements[i].total = result.data.length;
        cardElements[i].inactive = result.data.filter(g => g.status == false).length
        cardElements[i].active = result.data.filter(g => g.status == true).length

      })

    }
    console.log(cardElements)
    setCardElement([...cardElements])
    setIsLoading(false)
  }, [])
  const changeTable = async (e, type,index, prod) => {
    if(type == true || type == false){
      setOperationTable('Table')
    }else{
      setOperationTable(type)
    }
   
  
    setIndexValue(index)
    const cardElementList = [...cardElement]

    setTitle(cardElementList[index].name)
    setColumns([...cardElementList[index].HeaderColumns])

    setAddButton(cardElementList[index].addButton)
    setInputType([...cardElementList[index].inputType])
    if (type == true || type == false) {
      setIsLoading(true)
    
   
      await getCompany(cardElementList[index].name, type).then(result => {
        if (result.success) {
          setData(result.data)
          setIsLoading(false)
        } else {
          setData([])
        }

      })
      
    } else if (type == 'Table') {
      setIsLoading(true)
   
  

      await getCompany(cardElementList[index].name, 'All').then(result => {
        if (result.success) {
          setData(result.data)
          setIsLoading(false)
        } else {
          setData([])
        }
      })
   
    } else if (type == 'View' || type == 'Deleted' || type == 'Edit') {
      setIsLoading(true)
      // setOperationTable(type)


      await getCompany(cardElementList[index].name, prod._id).then(result => {
        console.log(result.data)
        if (result.success) {
         

          setSingleItem({ ...result.data })
          setIsLoading(false)
        } else {
          setSingleItem({})
          setIsLoading(false)
        }

      })

      
    }
  }


  const postData = (e, values) => {
    const cardElementList = [...cardElement]
    if (values[Object.keys(values).filter(k => k == 'status')] === 'false') {
      values[Object.keys(values).filter(k => k == 'status')] = false
    }
    if (operationsTable == 'Add') {
      delete values['_id'];
    }
   
    setIsLoading(true)
    postCompany(cardElementList[indexValue].name !=='Home Banner' ?  isEmpty(values) :values, cardElementList[indexValue].name, operationsTable).then(result => {
      if (result.success) {
        setIsLoading(true)
        setOperationTable('Table')
        getCompany(cardElementList[indexValue].name, status).then(result => {
          if (result.success) {
            setColumns([...cardElementList[indexValue].HeaderColumns])
            setData(result.data)
            setIsLoading(false)
          } else {
            setData([])
            setIsLoading(false)
          }

        })
      } else {
        alert('Hello')
      }
    })
  }
  const addDescription=async (prod,pr,index)=>{
    const cardElementList = [...cardElement]
    console.log(prod)
    setIsLoading(true)
    const allInputList = [...cardElementList[index].inputType]
     await getCompany(prod._id,pr._id).then(results=>{
      console.log(results.data)
      if(Object.keys(results.data).length == 0){
       console.log(allInputList)
       setInputType([...allInputList]) 
       setSingleItem({social:pr.name,name:prod.name, company:prod._id,sociallink:pr._id})
       setOperationTable('Add') 
       setIsLoading(false)
      }else{
       
        setInputType([...allInputList]) 
        setSingleItem({social:pr.name,name:prod.name, company:prod._id,sociallink:pr._id,...results.data})
        setOperationTable('Edit') 
        setIsLoading(false)
      }
    })
   }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Company | {titleData.name}</title>
        </MetaTags>
        <Container fluid>
          {
            operationsTable == '' ?
              <Breadcrumbs
                title={"System Admin"}
                breadcrumbItem={"School"}
                bredcumlink={"system-admin"}
              />
              : <Breadcrumbs
                title={'School'}
                breadcrumbItem={title}
                bredcumlink={'company'}
              />
          }

          <Row>
            {

              operationsTable == '' ?
                cardElement.map((d, index) => (
                  <Col sm={4} md={3} key={index}>
                    {
                      isLoading ?

                        <UiLoading />

                        :
                        <GalleryCardElementwithEvent
                          data={d}
                          changeTable={(e, type, title) => changeTable(e, type, index)}
                          addButton={d.addButton}
                        />
                    }

                  </Col>
                ))
                :
                operationsTable == 'Table' ?
                  <Card>
                    {
                      isLoading ?
                        <UiLoading />
                        : <ReactTable
                          title={title}
                          datas={data}
                          changeTable={(e, type, title) => changeTable(e, type, indexValue)}
                          columns={columns}
                          addButton={addButton}
                        />
                    }

                  </Card>
                  :
                  operationsTable == 'Add' || operationsTable == 'Edit' || operationsTable == 'Deleted' || operationsTable == 'View' ?
                    <>
                      {
                        isLoading ?
                          <UiLoading />
                          :
                          <InputFormElement
                            operationsTable={operationsTable}
                            singleItem={singleItem}

                            bredcumlink={'company'}
                            viewIcon={false}
                            filetype='single'

                            title={title}
                            changeTable={(e, type,) => changeTable(e, type,indexValue)}
                            inputType={inputType}
                            postData={postData}
                          />
                      }
                    </>
                    : ''
            }

          </Row>
        </Container>
      </div>
    </React.Fragment >
  )
}



export default Company
