const layoutTypes = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
}

const layoutWidthTypes = {
  FLUID: "fluid",
  BOXED: "boxed",
  SCROLLABLE: "scrollable",
}

const topBarThemeTypes = {
  LIGHT: "light",
  DARK: "dark",
  COLORED: "colored",
}

const leftBarThemeImageTypes = {
  NONE: "none",
  IMG1: "img1",
  IMG2: "img2",
  IMG3: "img3",
  IMG4: "img4",
}

const leftSidebarTypes = {
  DEFAULT: "default",
  COMPACT: "compact",
  ICON: "icon",
}

const leftSideBarThemeTypes = {
  LIGHT: "light",
  COLORED: "colored",
  DARK: "dark",
  WINTER: "winter",
  LADYLIP: "ladylip",
  PLUMPLATE: "plumplate",
  STRONGBLISS: "strongbliss",
  GREATWHALE: "greatwhale",
}
const differentMarketing=[
  {
  label:'Offer',
  value:'Offer'
},
{
  label:'Discount',
  value:'Discount'
},
{
  label:'Coupon',
  value:'Coupon'
},
{
  label:'Deales Of The Day',
  value:'Deales Of The Day'
},




]
const validation = [
  
  {
    label: "String",
    value: "String",
  },
  {
    label: "Number",
    value: "Number",
  },
  {
    label: "String Number",
    value: "String Number",
  },
  {
    label: "String Number Special Character",
    value: "String Number Special Character",
  },
]
const associate = [

  {
    label: "Company",
    value: "Company",
  },
  {
    label: "Employee",
    value: "Employee",
  },
 
]
const policyInformation = [
  {
    label: "B2C",
    value: "B2C",
  },
  {
    label: "B2B",
    value: "B2B",
  },
  {
    label: "ALL",
    value: "ALL",
  }
]
const pageBanner = [
  {
    label: "Category",
    value: "Category",
  },
  {
    label: "Offer",
    value: "offer",
  },
  {
    label: "Discount",
    value: "discount",
  },
  {
    label: "Best Deals Of The Day",
    value: "best-deals-of-the-day",
  },
  {
    label: "Different Type Of Product",
    value: "Different Type Of Product",
  },
  {
    label: "About Us",
    value: "about-us",
  },
  {
    label: "Contact Us",
    value: "contact-us",
  },
  {
    label: "FAQ",
    value: "faq",
  },
  {
    label: "Register",
    value: "register",
  },
  {
    label: "Login",
    value: "login",
  },
  {
    label: "Subcription",
    value:  "subcription"
  }
]

const AllPageNavigation = [
  {
    label: "Home",
    value: "/",
  },
  {
    label: "Company Information",
    value: "company-information",
  },
  {
    label: "Category",
    value: "category",
  },

  {
    label: "Brand",
    value: "brand",
  },
  {
    label: "Offer",
    value: "offer",
  },
  {
    label: "Discount",
    value: "discount",
  },
  {
    label: "Best Deals Of The Day",
    value: "best-deals-of-the-day",
  },
  {
    label: "Different Type Of Product",
    value: "type-of-product",
  },

  {
    label: "About Us",
    value: "about-us",
  },
  {
    label: "Contact Us",
    value: "contact-us",
  },
  {
    label: "FAQ",
    value: "faq",
  },
  {
    label: "Feedback",
    value: "Feedback",
  },
  {
    label: "Social Link",
    value: "social-link",
  }, 
  {
    label: "blank",
    value: "blank",
  }, 
]
const service = [
  {
    label: "Accomodation",
    value: "Accomodation",
    data: [
      {
        label: "Hotel",
        type: "Hotel",
      },
      {
        label: "Villa",
        type: "Villa",
      },
      {
        label: "Appartment",
        type: "Appartment",
      },
      {
        label: "Resort",
        type: "Resort",
      },
    ]
  },
  {
    label: "Transfer",
    value: "Transfer",
    data: [
      {
        label: "Arrival",
        type: "Arrival",
      },
      {
        label: "Departure",
        type: "Departure",
      },
    ]
  },
  {
    label: "Transportation",
    value: "Transportation",
    data: [
      {
        label: "Package",
        type: "Package",
      },
      {
        label: "Per KM",
        type: "Per KM",
      },
    ]
  },
  {
    label: "Packages",
    value: "Packages",
    data: [
      {
        label: "FIT",
        type: "FIT",
      },
      {
        label: "GIT",
        type: "GIT",
      },
    ]
  },
  {
    label: "Resturant",
    value: "Resturant",
    data: [
      {
        label: "Menu",
        type: "Menu",
      },
      {
        label: "Meal",
        type: "Meal",
      },
    ]
  },
  {
    label: "Attraction",
    value: "Attraction",
    data: [
      {
        label: "FIT Ticket",
        type: "FIT Ticket",
      },
      {
        label: "GIT Ticket",
        type: "GIT Ticket",
      },
    ]
  },
]

const categorystatus =[
  {
    label: "Main Category",
    value: "Main Category",
  },
  {
    label: "Sub Category",
    value: "Sub Category",
  },
  {
    label: "Child Category",
    value: "Child Category",
  },
]

const banner = [
  {
    label: "Banner 1",
    value: "Banner 1",
  },
  {
    label: "Banner 2",
    value: "Banner 2",
  },
  {
    label: "Banner 3",
    value: "Banner 3",
  },
  {
    label: "Banner 4",
    value: "Banner 4",
  },
  {
    label: "Home Model",
    value: "Home Model",
  },
]

const amount = [
  {
    label: "%",
    value: "Percent",
  },
  {
    label: "/-",
    value: "Rupees",
  },
]
const pagename = [
  {
    label: "Page 1",
    value: "Page 1",
  },
  {
    label: "Page 2",
    value: "Page 2",
  },
  {
    label: "Page 3",
    value: "Page 3",
  },
]
const promotion = [
  {
    label: "Category",
    value: "Category",
  },
  {
    label: "Brand",
    value: "Brand",
  },
  {
    label: "Feature Product",
    value: "Feature Product",
  },
  {
    label: "Popular Product",
    value: "Popular Product",
  },
  {
    label: "Best Selling Product",
    value: "Best Selling Product",
  },
  {
    label: "Deal Of the Day",
    value: "Deal Of the Day",
  },
  {
    label: "Top Selling Product",
    value: "Top Selling Product",
  },
  {
    label: "Recently Added",
    value: "Recently Added",
  },
  {
    label: "Top Rated Category List",
    value: "Top Rated Category List",
  },
  {
    label: "Top Rated Brand List",
    value: "Top Rated Brand List",
  },
]
export {
  layoutTypes,
  layoutWidthTypes,
  topBarThemeTypes,
  leftBarThemeImageTypes,
  leftSidebarTypes,
  leftSideBarThemeTypes,
  validation,
  associate,
  service,

  banner,
  pagename,
  promotion,
  amount,
  categorystatus,
  pageBanner,
  AllPageNavigation,
  policyInformation,
  differentMarketing
}
