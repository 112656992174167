import {url} from '../../routes'

export const postRegister=async(data)=>{
    return fetch(`${url.authentication}`,{
        method:'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body:JSON.stringify(data)
    })
    .then(res=>res.json())
    .then(result=>{
        return result
    }).catch(err=>{
        return err;
    })
}

export const getCountRegister=async()=>{
    return fetch(`${url.authentication}/count-user`,{
        method:'GET',
        
    })
    .then(res=>res.json())
    .then(result=>{
        return result
    }).catch(err=>{
        return err;
    })
}
export const getUserRegister= async(data,typofLogin)=>{
    return fetch(`${url.authentication}/login?typeoflogin=${typofLogin}`,{
        method:'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body:JSON.stringify(data)
    })
    .then(res=>res.json())
    .then(result=>{
        return result
    }).catch(err=>{
        return err;
    })
}
export const postForgotPasswords= async(data,typofLogin,user)=>{
    console.log('helo')
    return fetch(`${url.authentication}/forgotpassword?typeoflogin=${typofLogin}&&type=${user}`,{
        method:'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body:JSON.stringify(data)
    })
    .then(res=>res.json())
    .then(result=>{
        return result
    }).catch(err=>{
        return err;
    })
}