
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,


} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"


import { url } from "routes"
import { Link } from "react-router-dom"

import GalleryCardElementwithEvent from "UI/GalleryCardElementWithEvent"
import ReactTable from "UI/Table"

import InputFormElement from "UI/InputFormElement"
import { getConstPage,getConstPageStatus,postConstPage} from "data/seo/AllConstPageSeo"
import UiLoading from "UI/loading"
import MultiRowTable from "UI/Multirow/MultiRowTable"
import { isEmpty } from 'data/middlefunctions'
const AllConstPageSeo = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [indexValue, setIndexValue] = useState('')
  const [addButton,setAddButton]=useState(false)
  const [operationsTable, setOperationTable] = useState('Card')
  const [singleItem, setSingleItem] = useState({status:true})
  const [status, setStatus] = useState('All')
  const [title, setTitle] = useState('')
  const [data, setData] = useState([])
  const [inputType, setInputType] = useState([])
  const [columns, setColumns] = useState([])
  const [addMoreTitle, setAddMoreTitle] = useState([])
  const [addMore, setAddMore] = useState([])
  const [addMoreRow, setAddMoreRow] = useState([])
  const [addRowHeader, setAddRowHeader] = useState({})

  const [cardElement, setCardElement] = useState([
    {
      name: 'Costant Page',
      title: 'Costant Page',
      active: 0,
      inactive: 0,
      total: 0,
      getStatusData: getConstPageStatus,
      getData: getConstPage,
      getPostData:postConstPage,
      statusList:'All',
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "typeofpage",
          text: "Dynamic Constant",
          sort: true,

        },
        {
          dataField: "metatitle",
          text: "Meta Title",
          sort: true,

        },
        {
          dataField: "metaslug",
          text: "Meta Slug",
          sort: true,

        },
       
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 0,prod)}
                  />
                </Link>
                
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 0,prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Title', name: 'typeofpage', type: 'text', value: '', disabled: '', required: true, gride: 12 },
        { title: 'Meta Title', name: 'metatitle', type: 'text', value: '', disabled: '', required: '', gride: 12 },
        { title: 'Meta Slug', name: 'metaslug', type: 'text', value: '', disabled: '', required: '', gride: 12 },
        { title: 'Meta Description', name: 'metadescription', type: 'textarea', value: '', disabled: '', required: true, gride: 12 },
        { title: 'Canonical Tag', name: 'canonicaltag', type: 'textarea', value: '', disabled: '', required: true, gride: 12 },
        { title: 'Rebots', name: 'rebots', type: 'textarea', value: '', disabled: '', required: '', gride: 12 },
      
      ],
      isLoading: true,
      addButton: false,
      addMore:false,

    },
    {
      name: 'Og Titile',
      title: 'Og Titile',
      active: 0,
      inactive: 0,
      total: 0,
      getStatusData: getConstPageStatus,
      getData: getConstPage,
      getPostData:postConstPage,
      statusList:'All',
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "typeofpage",
          text: "Dynamic Constant",
          sort: true,

        },
        {
          dataField: "metatitle",
          text: "Meta Title",
          sort: true,

        },
        {
          dataField: "metaslug",
          text: "Meta Slug",
          sort: true,

        },
       
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
              <Button
                      type="submit"
                      color="success"
                      onClick={(e)=>changeTable(e,  "Add More", 1,prod)}
                    >
                      Og Title
                    </Button>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Title', name: 'typeofpage', type: 'text', value: '', disabled: '', required: true, gride: 12 },
      ],
      isLoading: true,
      addButton: false,
      addMoreTitle:'ogtitle',
      addMore:true,
      addMoreRow:[{ title: "Sl. No." }, { title: "title" },{title:'value'},{ title: 'Deleted' }],
      addRowHeader:{ id: Date.now().toString(), title: '',value:''}
    },
    {
      name: 'Og Description',
      title: 'Og Description',
      active: 0,
      inactive: 0,
      total: 0,
      getStatusData: getConstPageStatus,
      getData: getConstPage,
      getPostData:postConstPage,
      statusList:'All',
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "typeofpage",
          text: "Dynamic Constant",
          sort: true,

        },
        {
          dataField: "metatitle",
          text: "Meta Title",
          sort: true,

        },
        {
          dataField: "metaslug",
          text: "Meta Slug",
          sort: true,

        },
       
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                 <div className="d-flex gap-3">
              <Button
                      type="submit"
                      color="success"
                      onClick={(e)=>changeTable(e,  "Add More", 2,prod)}
                    >
                      Og Decription
                    </Button>
              </div>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Title', name: 'typeofpage', type: 'text', value: '', disabled: '', required: true, gride: 12 },
      ],
      isLoading: true,
      addButton: false,
      addMore:true,
      addMoreTitle:'ogdescription',
      addMoreRow:[{ title: "Sl. No." }, { title: "title" },{value:'value'},{ title: 'Deleted' }],
      addRowHeader:{ id: Date.now().toString(), title: '',value:''}
    },
    {
      name: 'Og Image',
      title: 'Og Image',
      active: 0,
      inactive: 0,
      total: 0,
      getStatusData: getConstPageStatus,
      getData: getConstPage,
      getPostData:postConstPage,
      statusList:'All',
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "typeofpage",
          text: "Dynamic Constant",
          sort: true,

        },
        {
          dataField: "metatitle",
          text: "Meta Title",
          sort: true,

        },
        {
          dataField: "metaslug",
          text: "Meta Slug",
          sort: true,

        },
       
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
              <div className="d-flex gap-3">
              <Button
                      type="submit"
                      color="success"
                      onClick={(e)=>changeTable(e, "Add More", 3,prod)}
                    >
                      Og Image
                    </Button>
              </div>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Title', name: 'typeofpage', type: 'text', value: '', disabled: '', required: true, gride: 12 },
      ],
      isLoading: true,
      addButton: false,
      addMore:true,
      addMoreTitle:'ogimage',
      addMoreRow:[{ title: "Sl. No." }, { title: "title" },{title:'value'},{ title: 'Deleted' }],
      addRowHeader:{ id: Date.now().toString(), title: '',value:''}
    },
  ])

  useEffect(async () => {
    const cardElementStatus = [...cardElement]
    for(let i=0;i<cardElementStatus.length;i++){
        cardElementStatus[i].getStatusData(cardElementStatus[i].name,cardElementStatus[i].statusList).then((res) => {
        cardElementStatus[i].active = res.active
        cardElementStatus[i].inactive = res.inactive
        cardElementStatus[i].total = res.total
        cardElementStatus[i].isLoading = false
        setCardElement([...cardElementStatus])
      })
    }
  }, [])
  const changeTable = async (e, type, index,prod) => {
    e.preventDefault()
    setIsLoading(true)
    setIndexValue(index)
    const cardElementList = [...cardElement]
    setAddButton(cardElementList[index].addButton)
    setTitle(cardElementList[index].title)
    
    if(cardElementList[index].addMore){
     setOperationTable('Add More')
     setAddMoreTitle(cardElement[index].addMoreTitle)
     setAddRowHeader({...cardElement[index].addRowHeader})
    // setAddMore([...cardElement[index].addMoreRow])
    setAddMoreRow([...cardElement[index].addMoreRow])
     }
  if (type == 'Table' || type == true || type == false) {
      setColumns(cardElementList[index].HeaderColumns)
      setOperationTable('Table')
    await cardElement[index].getData(cardElementList[index].name,type == 'Table'? "All" :type).then(result => {
        
        if (result.success) {
          setData(result.data)
        } else {
          setData([])
        }
      })
      setIsLoading(false)
    }else if (type == 'Add') {
    for(let i=0;i<cardElementList[index].inputType.length;i++){
      if (cardElementList[index].inputType[i].type === 'multiple' || cardElementList[index].inputType[i].type === 'select') {
        cardElementList[index].inputType[i].value=[]
        await cardElementList[index].inputType[i].outputData(cardElementList[index].inputType[i].table,cardElementList[index].inputType[i].status).then(results => {
            for (let k = 0; k < results.data.length; k++) {
           
                    let data = {
                        label: results.data[k][Object.keys(results.data[k]).filter(q => q === cardElementList[index].inputType[i].outputDropDown)],
                        value: results.data[k]._id,
                    }
                    cardElementList[index].inputType[i].value.push(data)
                  }
            })
          }
      }
   

      setInputType([...cardElementList[index].inputType])
      setOperationTable('Add')

      setIsLoading(false)
    }else if(type === 'Add More'){
    
      await cardElement[index].getData(cardElement[index].name,prod._id).then(results=>{
        setSingleItem(results.data)
        setTitle(prod.typeofpage)
         setIsLoading(false)
      
        
      })
    }else{
    
      setInputType([...cardElement[index].inputType])
      if(type == 'Edit' || type == 'View' || type == 'Deleted'){
        if(cardElement[index].name === 'Different Page Relation'){
          setSortTreeStatus(true)
        }
        await cardElement[index].getData(cardElement[index].name,prod._id).then(async results=>{
          setSingleItem(results.data)
          for(let i=0;i<cardElementList[index].inputType.length;i++){
        
            if (cardElementList[index].inputType[i].type === 'multiple' || cardElementList[index].inputType[i].type === 'select') {
              cardElementList[index].inputType[i].value=[]
              await cardElementList[index].inputType[i].outputData(cardElementList[index].inputType[i].table,cardElementList[index].inputType[i].status).then(results => {
                  for (let k = 0; k < results.data.length; k++) {
                 
                          let data = {
                              label: results.data[k][Object.keys(results.data[k]).filter(q => q === cardElementList[index].inputType[i].outputDropDown)],
                              value: results.data[k]._id,
                          }
                          cardElementList[index].inputType[i].value.push(data)
                        }
                  })
                }
            }
          setIsLoading(false)
          setInputType([...cardElementList[index].inputType])
          setOperationTable(type)
        })
      }

    }
  }


  const postData = (e, values) => {
    const cardElementList = [...cardElement]
    if (values[Object.keys(values).filter(k => k == 'status')] === 'false') {
      values[Object.keys(values).filter(k => k == 'status')] = false
    }
    if (operationsTable == 'Add') {
      delete values['_id'];
    }
    setIsLoading(true)
    cardElementList[indexValue].getPostData(isEmpty(values),cardElementList[indexValue].name, operationsTable).then(result => {
      if (result.success) {
        setColumns([...cardElementList[indexValue].HeaderColumns])
        setOperationTable('Table')
        cardElementList[indexValue].getData(cardElementList[indexValue].name, status).then(result => {
          if (result.success) {
            setData(result.data)
            setIsLoading(false)
          } else {
            setData([])
            setIsLoading(false)
          }

        })
      } else {
        alert('Hello')
      }
    })
  }
  const postAddMoreData = (e, values,type) => {
    console.log(values[addMoreTitle])
    const FilteValue=[]

   values[addMoreTitle].pop()
    console.log( values)
        const cardElementList = [...cardElement]
        singleItem[addMoreTitle]= values[addMoreTitle]
        console.log(values)
        cardElementList[indexValue].getPostData(singleItem, title, 'Edit').then(result => {
          if (result.success) {
            setColumns([...cardElementList[indexValue].HeaderColumns])
            setOperationTable('Table')
            cardElementList[indexValue].getData(cardElementList[indexValue].name, 'All').then(result => {
              if (result.success) {
                setData(result.data)
              } else {
                setData([])
              }
    
            })
          } else {
            alert('Hello')
          }
        })
      }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Page Structure | {url.title}</title>
        </MetaTags>
        <Container fluid>
          {
            operationsTable == 'Card' ?
              <Breadcrumbs
                title={"Page Structure"}
                breadcrumbItem={"Page Structure"}
                bredcumlink={"page-structure"}
              />
              : <Breadcrumbs
                title={'Page Structure'}
                breadcrumbItem={title}
                bredcumlink={'page-structure'}
              />
          }

          <Row>
            {

              operationsTable == 'Card' ?
                cardElement.map((d, index) => (
                  <Col sm={4} md={3} key={index}> 
                
                        <GalleryCardElementwithEvent
                          data={d}
                          changeTable={(e, type) => changeTable(e, type, index)}
                          addButton={d.addButton}
                        />
                  </Col>
                ))
                :
                operationsTable == 'Table' ?
               
                       isLoading ?
                     <UiLoading cardElementOption ={!isLoading ?true :false}/>
                  :
                     <ReactTable
                          title={title}
                          datas={data}
                          changeTable={(e, type) => changeTable(e, type, indexValue)}
                          columns={columns}
                          addButton={addButton}
                          isLoading={isLoading}
                        />
                    

                
                  :       
                  isLoading ?
                  <UiLoading cardElementOption ={!isLoading ?true :false}/>
                :  operationsTable == 'Add More'?
               <Card>
                 <CardBody>
           
                          
                 <MultiRowTable  
               handleValidSubmits={(e,v)=>postAddMoreData(e,v,addMoreTitle)} 
               inputList={addRowHeader} 
               type={addMoreTitle}
                addMoreTable={singleItem[addMoreTitle] !== undefined ? singleItem[addMoreTitle] :[]} 
               multiRowheader={addMoreRow}
        
               saveButton={'Save'}
               />
       
                 </CardBody>
               </Card>
            
               
               :
                            <InputFormElement
                            operationsTable={operationsTable}
                            singleItem={singleItem}
                            bredcumlink={'page-structure'}
                            viewIcon={false}
                            filetype='single'
                            title={title}
                            changeTable={(e, type) => changeTable(e, type, indexValue)}
                            inputType={inputType}
                            postData={postData}
                            isLoading={isLoading}
                          />
                          }
              
          </Row>
        </Container>
      </div>
    </React.Fragment >
  )
}



export default AllConstPageSeo
