import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardBody
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { url } from "routes"
import GalleryCardElementwithEvent from "UI/GalleryCardElementWithEvent"
import ReactTable from "UI/Table"
import InputFormElement from "UI/InputFormElement"
import { getMiscellaneous,getMiscellaneousDropDown } from "data/systemadmin/miscellaneous"
import { isEmpty } from "data/middlefunctions"
import UiLoading from "UI/loading"
import MultiRowTable from "UI/Multirow/MultiRowTable"
import { getCabinate, getCabinateStatus, postCabinate } from "data/school/cabinate"
import { Link } from "react-router-dom"

const Cabinate = () => {
  const [allPageLoading,setAllPageLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [indexValue, setIndexValue] = useState('')
  const [addButton,setAddButton]=useState(false)
  const [operationsTable, setOperationTable] = useState('Card')
  const [singleItem, setSingleItem] = useState({pagedescription:'',status:true})
  const [status, setStatus] = useState('All')
  const [title, setTitle] = useState('')
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const [pageDescription,setPageDescription] = useState([{name:'goapla'}])
  const [inputElemtnType,setInputElemtnType] = useState('')
  const [inputType, setInputType] = useState([
    { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
    { title: 'cabinate', name: 'uploadtype', type: 'hidden', value: '', disabled: '' },
    { title: 'year', name: 'year', type: 'hidden', value: 'year', disabled: '' },
    { title: 'Operational Year', name: 'operationyear', type: 'text', value: 'operationyear', disabled: true},
    { title: 'Member Name', name: 'name', type: 'text', value: '', disabled: '',required:true},
    { title: 'Designation', name: 'designation', type: 'text', value: '', disabled: '',required:false},
    { title: 'Class', name: 'class', type: 'text', value: '', disabled: '',required:false},
    { title: 'Image', name: 'image', type: 'file', value: [],label:'Cabinate Member Image',uploadtype:'cabinate',multiple:true, disabled: '', required: true, gride: 12 },
    { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
  ])
  const [sortTreeStatus,setSortTreeStatus] = useState(false)
  const [cardElement, setCardElement] = useState([])

  useEffect(async () => {
   
    await getMiscellaneous('Academic Year','All').then(async result=>{
     const d=[]
      if(result.success){
        for(let i=0; i < result.data.length;i++){
          await getCabinateStatus(result.data[i]._id,'Category Relation').then(results=>{
            d.push({
              _id: result.data[i]._id,
              name: result.data[i].name,
              title: result.data[i].name,
              total:results.total,
              active:results.active,
              inactive:results.inactive,
              getStatusData: getCabinateStatus,
              getData: getCabinate,
              getPostData:postCabinate,
              statusList:'All',
              HeaderColumns: [
                {
                  dataField: "_id",
                  text: "SL. No",
                  sort: true,
                  // eslint-disable-next-line react/display-name
                  formatter: (cell, prod, index) => index + 1,
                },
                {
                  dataField: "name",
                  text: "Name",
                  sort: true,
                },
                {
                  dataField: "image",
                  text: "Image",
                  sort: true,
                  formatter: (cell, prod, index) =>
                        <img src={`${url.commonUrls}/image/${prod.uploadtype}/${prod.image.length !== 0 ?prod.image[0] :''}`} width='50px' height="50px"/>,
                },
                {
                  dataField: "",
                  text: "Status",
                  sort: true,
                  formatter: (cell, prod, index) =>
                    prod.status == true ? <p>ON</p> : <p>OFF</p>,
                },
                {
                  dataField: "action",
                  isDummyField: true,
                  text: "Action",
                  // eslint-disable-next-line react/display-name
                  formatter: (cell, prod, index) => (
                    <>
                      <div className="d-flex gap-3">
                        <Link to="#" className="text-success">
                          <i
                            className="mdi mdi-pencil font-size-18"
                            id="edittooltip"
                            onClick={e => changeTable(e, "Edit", i, prod,result.data[i].name)}
                          />
                        </Link>
                        <Link to="#" className="text-danger">
                          <i
                            className="mdi mdi-delete font-size-18"
                            id="deletetooltip"
                            onClick={e => changeTable(e, "Deleted",i, prod,result.data[i].name)}
                          />
                        </Link>
                        <Link to="#" className="text-danger">
                          <i
                            className="mdi mdi-eye font-size-18"
                            id="deletetooltip"
                            onClick={e => changeTable(e, "View",i, prod,result.data[i].name)}
                          />
                        </Link>
                      </div>
                    </>
                  ),
                },
               
               
               
              ],
      
              isLoading:false,
              addButton: true,
            })
          })
          
        }
        setCardElement(d)
        setAllPageLoading(false)
      }
    })
    setIsLoading(false)
  
  }, [])
  const changeTable = async (e, type, index,prod,title) => {
    console.log(type)
    e.preventDefault()
    setIndexValue(index)
    const cardElementList = [...cardElement]
    const inputLists =[...inputType]
    setIsLoading(true)
  if (type == 'Table' || type == true || type == false) {
      setAddButton(cardElementList[index].addButton)
    setTitle(cardElementList[index].title)
    setSortTreeStatus(cardElementList[index].title)
    setColumns(cardElementList[index].HeaderColumns)
      setOperationTable('Table')
    await cardElement[index].getData(cardElementList[index]._id,type == 'Table'? "All" :type).then(result => {
        console.log(result.description)
        if (result.success) {
          setPageDescription(result.description)
          setData(result.data)
          setIsLoading(false)
        } else {
          setData([])
          setIsLoading(false)
        }
      })
     
    
    }else if (type == 'Add') {
      
      for(let i=0;i<inputLists.length;i++){
        if (inputLists[i].type === 'multiple' || inputLists[i].type === 'select') {
          inputLists[i].value=[]
          await inputLists[i].outputData(inputLists[i].table,inputLists[i].status).then(results => {
              for (let k = 0; k < results.data.length; k++) {
             
                      let data = {
                          label: results.data[k][Object.keys(results.data[k]).filter(q => q === inputLists[i].outputDropDown)],
                          value: results.data[k]._id,
                      }
                      inputLists[i].value.push(data)
                    }
              })
                singleItem.operationyear=prod.name
              singleItem.year =prod._id
             
              setSingleItem({...singleItem})
              
              setInputType([...inputLists])
              setOperationTable('Add')
      
              setIsLoading(false)
            }else{
              singleItem.operationyear=prod.name
              singleItem.year =prod._id
               console.log(singleItem)
              setSingleItem({...singleItem,})
              
              setInputType([...inputLists])
              setOperationTable('Add')
       
              setIsLoading(false)
            }
        }
        
    }else{
    
      setInputType([...inputLists])
      if(type == 'Edit' || type == 'View' || type == 'Deleted'){
        console.log(prod)
        console.log(index)
        await getCabinate(prod.name,prod._id).then(async result=>{
         
          for(let i=0;i<inputLists.length;i++){
        
            if (inputLists[i].type === 'multiple' || inputLists[i].type === 'select') {
              inputLists[i].value=[]
              await inputLists[i].outputData(inputLists[i].table,inputLists[i].status).then(results => {
                  for (let k = 0; k < results.data.length; k++) {
                 
                          let data = {
                              label: results.data[k][Object.keys(results.data[k]).filter(q => q === inputLists[i].outputDropDown)],
                              value: results.data[k]._id,
                          }
                          inputLists[i].value.push(data)
                        }
                  })
                  setSingleItem({...result.data,operationyear:title})
                  setInputType([...inputLists])
                  setOperationTable(type)
                  setIsLoading(false)
                }else{
                  console.log(title)
                  setSingleItem({...result.data,operationyear:title})
                  setInputType([...inputLists])
                  setOperationTable(type)
                  setIsLoading(false)
                }
            }
        
   
        })
      }

    }
  }
 

  const postData = (e, values) => {
    const cardElementList = [...cardElement]
    if (values[Object.keys(values).filter(k => k == 'status')] === 'false') {
      values[Object.keys(values).filter(k => k == 'status')] = false
    }
    if (operationsTable == 'Add') {
      delete values['_id'];
    }
    setIsLoading(true)
    cardElementList[indexValue].getPostData(isEmpty(values),cardElementList[indexValue].name, operationsTable).then(async result => {
      console.log(cardElementList[indexValue])
      if (result.success) {
        setOperationTable('Table')
        await cardElement[indexValue].getData(cardElementList[indexValue]._id, "All").then(results => {
            console.log(result.description)
            if (results.success) {
             
              setData(results.data)
              setIsLoading(false)
            } else {
              setData([])
              setIsLoading(false)
            }
          })
         
      } else {
        alert('Hello')
      }
    })
  }
  const postAddMoreData = (e, values,type) => {
    console.log(values[type])
    const FilteValue=[]

   values[type].pop()

        const cardElementList = [...cardElement]
        singleItem[type]= values[type]
        console.log(singleItem[type])
        console.log(singleItem[type])
        cardElementList[indexValue].getPostData(singleItem, cardElementList[indexValue].name, operationsTable).then(result => {
          if (result.success) {
            setColumns([...cardElementList[indexValue].HeaderColumns])
            setOperationTable('Table')
            cardElementList[indexValue].getData(cardElementList[indexValue]._id, "All").then(result => {
              if (result.success) {
                setData(result.data)
              } else {
                setData([])
              }
    
            })
          } else {
            alert('Hello')
          }
        })
      }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>All Gallery| {url.title}</title>
        </MetaTags>
        <Container fluid>
          {
            operationsTable == 'Card' ?
            <Breadcrumbs
            title={"School Dashboard"}
            breadcrumbItem={"Gallery"}
            bredcumlink={"school-dashboard"}
          />
              : <Breadcrumbs
                title={'Gallery'}
                breadcrumbItem={title}
                bredcumlink={'all-gallery'}
              />
          }

          <Row>
            {
              allPageLoading?
              <UiLoading cardElementOption={!allPageLoading} />:
              operationsTable == 'Card' ?
                cardElement.map((d, index) => (
                  <Col sm={4} md={3} key={index}> 
                
                        <GalleryCardElementwithEvent
                          data={d}
                          changeTable={(e, type) => changeTable(e, type, index,d)}
                          addButton={d.addButton}
                          isLoading={isLoading}
                        />
                  </Col>
                ))
                :
                operationsTable == 'Table' ?
                 
                     <ReactTable
                          title={title}
                          datas={data}
                          changeTable={(e, type) => changeTable(e, type, indexValue)}
                          columns={columns}
                          addButton={addButton}
                          
                        />
                :
              
               inputElemtnType === 'Add More Text'?
               <Card>
                <CardBody>
                {
          isLoading?
          <UiLoading cardElementOption={isLoading} />
          :
                <MultiRowTable
               handleValidSubmits={(e,v)=>postAddMoreData(e,v,'addmoretext')} 
               inputList={{ id: Date.now().toString(), title: '',value:''}} 
               type={'addmoretext'}
                addMoreTable={singleItem['addmoretext'] !== undefined ? singleItem['addmoretext'] :[]} 
               multiRowheader={[{ title: "Sl. No." }, { title: "title" },{title:'value'},{ title: 'Deleted' }]}
        
               saveButton={operationsTable}
               />
                }
                </CardBody>
               </Card>
                
               :
                          <InputFormElement
                            operationsTable={operationsTable}
                            singleItem={singleItem}

                            bredcumlink={'InformationPage'}
                            viewIcon={false}
                            filetype='single'

                            title={title}
                            changeTable={(e, type) => changeTable(e, type, indexValue)}
                            inputType={inputType}
                            postData={postData}
                            isLoading={isLoading}
                          />
                      
               
            }

          </Row>
        </Container>
      </div>
    </React.Fragment >
  )
}



export default Cabinate
