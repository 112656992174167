import React from 'react'
import { useState } from 'react'
import Select from "react-select"
import PropTypes from 'prop-types'
import './index.css'
import makeAnimated from "react-select/animated"
const animatedComponents = makeAnimated()
export const MultipleDropDown = ({ title, dropDownList, selectDrop, selctdropDownList }) => {
  //   const [selectedMulti3, setselectedMulti3] = useState(null)
  //   function handleMulti3(selectedMulti3) {
  //       setselectedMulti3(selectedMulti3)
  //     }
 console.log(selectDrop)
  return (
    <div className="mb-3 templating-select select2-container" id="demo9">
      <label className="control-label">{title}</label>
      
      <Select
        value={selectDrop !== undefined ?dropDownList.filter(o => selectDrop.some((i) => o.value === i ))  : []}
        isMulti={true}
        onChange={(e) => selctdropDownList(e, selectDrop !== undefined ?dropDownList.filter(o => selectDrop.some((i) => o.value === i ))  : [])}
        options={dropDownList !== undefined ? dropDownList : []}
        classNamePrefix="select2-selection"
        closeMenuOnSelect={false}
        components={animatedComponents}
       
      />
    </div>
  )

}
MultipleDropDown.propTypes = {
  title: PropTypes.string,
  dropDownList: PropTypes.array,
  selectDrop: PropTypes.array,
  selctdropDownList: PropTypes.func
}

export default MultipleDropDown;
