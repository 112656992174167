import { AvField, AvForm } from "availity-reactstrap-validation"
import PropTypes from "prop-types"
import React from "react"
import { Row, Col, CardTitle, Label } from "reactstrap"
import { url } from "routes"

export const ImageList = ({
  title,
  label,
  numberfile,
  uploadFileType,
  uploadImages,
  uploadImage,
  deleteUploadFiles,
  imageaccept,
  imageLoading,
  imageMeassageloading
}) => (
  <Row>
    <CardTitle>{title}</CardTitle>
    <Label>{label}</Label>
    <div style={{textAlign:'center'}}>
      {
        imageLoading?
        <p style={{textAlign:'center',backgroundColor:'green',color:'#FFF',width:'300px',    position: 'relative',left: '431px'}}>{imageMeassageloading}</p>
        :''
      }
    
    </div>
   
    <Col lg={12}>
      <input
        className="form-control"
        type="file"
        onChange={e => uploadImage(e)}
        multiple={numberfile}
        accept={imageaccept}
      />
    </Col>

    { uploadImages!== undefined && uploadImages !== null ? uploadImages.map((d, index) => (
      <Col lg={2} key={index}>
        <img
          className="rounded-circle avatar-xl"
          width="200"
          src={`${url.commonUrls}/image/${uploadFileType}/${d}`}
        />
        <i
          className="mdi mdi-delete font-size-18"
          id="deletetooltip"
          onClick={e => deleteUploadFiles(e, index, d,uploadFileType)}
          style={{ position: "relative", top: "-39px" }}
        />
      </Col>
    ))
  :''
  }
  </Row>
)

ImageList.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  label: PropTypes.string,
  uploadImage: PropTypes.func,
  uploadImages: PropTypes.array,
  urls: PropTypes.string,
  numberfile: PropTypes.bool,
  uploadFileType: PropTypes.string,
  imageaccept: PropTypes.string,
  deleteUploadFiles: PropTypes.func,
  imageLoading:PropTypes.bool,
  imageMeassageloading:PropTypes.string
}

export default ImageList
