
import {url} from 'routes';
export const getInformationSeoStatus=async(table,status)=>{
    return fetch(`${url.informationseo}/status?table=${table}&&status=${status}`,{
        method:'GET'
    })
    .then(res=>res.json())
    .then(result=>{
        return result
    }).catch(err=>{
        return err;
    })
}
export const getInformationSeo=async(table,status)=>{
    return fetch(`${url.informationseo}?table=${table}&&status=${status}`,{
        method:'GET'
    })
    .then(res=>res.json())
    .then(result=>{
        return result
    }).catch(err=>{
        return err;
    })
}

export const postInformationSeo=async(data,table,operation)=>{
    return fetch(`${url.informationseo}?table=${table}&&operation=${operation}`,{
        method:'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body:JSON.stringify(data)
    })
    .then(res=>res.json())
    .then(result=>{
        return result
    }).catch(err=>{
        return err;
    })
}
