
import {url} from 'routes';

export const getFooterMenuManagmentStatus=async(table,status)=>{
    console.log('p')
    return fetch(`${url.footermenumanagment}/status?table=${table}&&status=${status}`,{
        method:'GET'
    })
    .then(res=>res.json())
    .then(result=>{
        return result
    }).catch(err=>{
        return err;
    })
}
export const getFooterMenuManagment=async(table,status)=>{
    console.log('p')
    return fetch(`${url.footermenumanagment}?table=${table}&&status=${status}`,{
        method:'GET'
    })
    .then(res=>res.json())
    .then(result=>{
        return result
    }).catch(err=>{
        return err;
    })
}

export const postFooterMenuManagment=async(data,table,operation)=>{
    return fetch(`${url.footermenumanagment}?table=${table}&&operation=${operation}`,{
        method:'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body:JSON.stringify(data)
    })
    .then(res=>res.json())
    .then(result=>{
        return result
    }).catch(err=>{
        return err;
    })
}
