import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardBody
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

import { authProtectedRoutes, url } from "routes"
import { Link } from "react-router-dom"

import GalleryCardElementwithEvent from "UI/GalleryCardElementWithEvent"
import ReactTable from "UI/Table"
import { getEmployee, postEmployee,getEmployeeStatus } from "data/systemadmin/employee"
import InputFormElement from "UI/InputFormElement"
import UiLoading from "UI/loading"
import { getCompany } from "data/systemadmin/company"
import { isEmpty } from "data/middlefunctions"
import { getMiscellaneous } from "data/systemadmin/miscellaneous"
import { result } from "lodash"

const Employee = () => {
  const [indexValue,setIndexValue] = useState()
  const [addButton,setAddButton] = useState(false)
  const [titleData,setTitleData]= useState(localStorage.getItem('company')!== undefined && localStorage.getItem('company')!== null ?JSON.parse(localStorage.getItem('company')) :{})
  const [isLoading, setIsLoading] = useState(true)
  const [operationsTable, setOperationTable] = useState('')
  const [singleItem, setSingleItem] = useState({status:true,typeofuser:'Super User'})
  const [status, setStatus] = useState('All')
  const [title, setTitle] = useState('')
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const [inputType, setInputType] = useState([])
  const [subModuleId,setSubModuleId] = useState('')
  const [cardElement, setCardElement] = useState([
    {
      name: 'Employee',
      title: 'Employee',
      active: 0,
      inactive: 0,
      total: 0,
      getStatusData: getEmployeeStatus,
      getData: getEmployee,
      getPostData:postEmployee,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Name",
          sort: true,
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 0, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 0, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 0, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Name', name: 'name', type: 'text', value: '', disabled: '', required: true , gride: 4 },
        { title: 'Alias', name: 'alias', type: 'text', value: '', disabled: '', required: true , gride: 4 },
        { title: 'DOJ', name: 'dob', type: 'date', value: '', disabled: '', required: true , gride: 4 },
        { title: 'Employee Level', name: 'label', type: 'select', value: [], outputData: getEmployee, outputDropDown: 'name', table: 'Employee Level', status: true, disabled: '', required: true , gride: 4 },
        { title: 'Employee Roles', name: 'roles', type: 'multiple', value: [], outputData: getEmployee, outputDropDown: 'name', table: 'Roles', status: true, disabled: '', required: true , gride: 4 },
        { title: 'Module Assign', name: 'moduleassign', type: 'multiConsType', value: authProtectedRoutes[0].menu, status: true, disabled: '', required: true, gride: 4 },
        { title: 'Document Type', name: 'documents', type: 'multiple', value: [], outputData: getMiscellaneous, outputDropDown: 'document', table: 'Type Of Document', status: true, disabled: '', required: true , gride: 4 },
        { title: 'Social Link', name: 'sociallink', type: 'multiple', value: [], outputData: getMiscellaneous, outputDropDown: 'social', table: 'Social Link', status: true, disabled: '', required: true, gride: 4 },
        { title: 'Designation Name', name: 'designation', type: 'select', value: [], outputData: getEmployee, outputDropDown: 'name', table: 'Designation', status: true, disabled: '', required: true , gride: 4 },
        { title: 'Qualification', name: 'qualification', type: 'multiple', value: [], outputData: getEmployee, outputDropDown: 'name', table: 'Qualification', status: true, disabled: '', required: true , gride: 4 },
        { title: 'Department Name', name: 'departmemt', type: 'select', value: [], outputData: getEmployee, outputDropDown: 'name', table: 'Department', status: true, disabled: '', required: true , gride: 4 },
        { title: 'Branch Name', name: 'branch', type: 'select', value: [], outputData: getCompany, outputDropDown: 'name', table: 'Branch Setup', status: true, disabled: '', required: true , gride: 4 },
        { title: 'Employee CV Url', name: 'cv', type: 'text', value: '',  disabled: '', required: '', gride: 12, },
        { title: 'Image', name: 'image', type: 'file', value: [], label:'Image', disabled: '', required: true, gride: 12 },
        { title: 'Type Of Employee', name: 'typeofemployee', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Regular', name: 'typeofemployee', value: 'permanent' }, { title: 'Contractual', name: 'typeofemployee', value: 'contractual' }],gride:12 },
        { title: 'Type Of User', name: 'typeofuser', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Super User', name: 'typeofuser', value: 'Super User' }, { title: 'Admin', name: 'typeofuser', value: 'Admin' }],gride:12 },
        { title: 'Employee Status', name: 'employeestatus', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Teaching Staff', name: 'employeestatus', value: 'teachingstaff' }, { title: 'Non Teaching Staff ', name: 'employeestatus', value: 'nonteachingstaff' },{ title: 'Other', name: 'employeestatus', value: 'other' }] },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }],gride:12 }
      ],
      isLoading: true,
      addButton: true,
    },
    {
      name: 'Employee Module Assign',
      title: 'Module Assign',
      active: 0,
      inactive: 0,
      total: 0,
      getStatusData: getEmployeeStatus,
      getData: getEmployee,
      getPostData:postEmployee,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Name",
          sort: true,
        },
        {
          dataField: "",
          text: "Module Assign",
          sort: true,
          formatter: (cell, prod, index) =>
          prod.moduleassign !== undefined && prod.moduleassign !== null && prod.moduleassign.length !== 0 ?  
          <div className="mb-3">
            {
              prod.moduleassign.map((f,i)=>(
                <Button
                type="submit"
                color="success" key={i}
                onClick={()=>addDescription(authProtectedRoutes[0].menu.find((x) => x.value === f).label,authProtectedRoutes[0].menu.find((x) => x.value === f).value,prod._id,1)}
                >
                  {authProtectedRoutes[0].menu.find((x) => x.value === f) !== undefined && authProtectedRoutes[0].menu.find((x) => x.value === f) !==null  ?authProtectedRoutes[0].menu.find((x) => x.value === f).label:''}
                </Button>
              ))
            }
          </div> 
          : 
          <p>OFF</p>,
        },
        {
          dataField: "",
          text: "Card Element Assign",
          sort: true,
          formatter: (cell, prod, index) =>
          prod.moduleList !== undefined  && prod.moduleList.length !== 0 ?  
          <div className="mb-3">
            {
             prod.moduleList.map((f,i)=>(
              f.menu !== undefined && f.menu !== null && f.menu.length !== 0 ? f.menu.map((x,ig) =>   
             
              <Button type="submit" color="success" key={i} onClick={()=>addCardElement(f,x,1)} disabled={authProtectedRoutes.find(d=> d.value === f.value).menu.find(k=> k.value === x.value) !== undefined && authProtectedRoutes.find(d=> d.value === f.value).menu.find(k=> k.value === x.value).cardElement.length ==0 ? true : false}
              >{`${f.label}-${authProtectedRoutes.find(d=> d.value === f.value).menu.find(k=> k.value === x.value).label}`}</Button>
              )
              :'No Card Element Assign'))
            }
          </div> 
          : 
          <p>No Sub Module Assign</p>,
        },

      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: '', name: 'employee', type: 'hidden', value: '', disabled: '' },
        { title: '', name: 'label', type: 'hidden', value:'', disabled: '', },
        { title: '', name: 'value', type: 'hidden', value: '', disabled: '', },
        { title: 'Module Name', name: 'label', type: 'text', value: '', disabled: true, required: true , gride: 12 },
        { title: 'Sub Module List', name: 'submodulelist', type: 'multiConsType', value: [], disabled: '', required: true , gride: 12 },
        { title: 'Card Element', name: 'cardlist', type: 'multiConsType', value: [], disabled: '', required: true , gride: 12 },
        { title: 'Module Operation', name: 'modulepesmission', type: 'multiConsType', value: [{label:'Add',value:'Add'},{label:'Edit',value:'Edit'},{label:'Deleted',value:'Deleted'},{label:'Updated',value:'Updated'}], disabled: '', required: true , gride: 12 },
        ],
      isLoading: true,
      addButton: false,
      
    },
    {
      name: 'Employee Document Upload',
      title: 'Documents',
      active: 0,
      inactive: 0,
      total: 0,
      getStatusData: getEmployeeStatus,
      getData: getEmployee,
      getPostData:postEmployee,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Name",
          sort: true,
        },
        {
          dataField: "email",
          text: "Email",
          sort: true,
        },
        {
          dataField: "mobile",
          text: "Mobile",
          sort: true,
        },
        {
          dataField: "",
          text: "Documents Details",
          sort: true,
          formatter: (cell, prod, index) =>
          prod.documents !== undefined  && prod.documents.length !== 0?
          prod.documents !== undefined  && prod.documents.length !== 0 && prod.documents.map((f,i)=>(
            <div className="mb-3" key={i}>
            
              <Button type="submit" color="success" onClick={()=>uploadDocument(prod,f,2)}>{f.document}</Button>
            
          </div>
          ))  
          :'Document List Not Attached'
         
        }
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'typofdocument', name: 'typofdocument', type: 'hidden', value: 'typofdocument', disabled: '' },
        { title: 'associatetype', name: 'associatetype', type: 'hidden', value: 'associatetype', disabled: '' },
        { title: 'Employee Name', name: 'name', type: 'text', value: '', disabled: 'true',gride: 4 },
        { title: 'Document Name', name: 'document', type: 'text', value: '', disabled: true, required: '' , gride: 4 },
        { title: 'Document Digit', name: 'validationdigit', type: 'text', value: '', disabled: true, required: '' , gride: 4 },
        { title: 'Validation Type', name: 'validationtype', type: 'text', value: '', disabled: true, required: '' , gride: 4 },
        { title: 'Validation Number', name: 'number', type: 'text', value: '', disabled: '', required: true , gride: 4 },
        { title: 'Validation Prove', name: 'file', type: 'file', value:[],uploadtype:'document',label:'Validation Prove', disabled: true, required: '' , gride: 4 },
      ],
      isLoading: true,
      addButton: false,
      
    },
    {
      name: 'Employee Social Link',
      title: 'Employee Social Link',
      active: 0,
      inactive: 0,
      total: 0,
      getStatusData: getEmployeeStatus,
      getData: getEmployee,
      getPostData:postEmployee,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Name",
          sort: true,
        },
        {
          dataField: "",
          text: "Social Link Details",
          sort: true,
          formatter: (cell, prod, index) =>
          prod.sociallink !== undefined  && prod.sociallink.length !== 0?
          prod.sociallink !== undefined  && prod.sociallink.length !== 0 && prod.sociallink.map((f,i)=>(
            <div className="mb-3" key={i}>
            
              <Button type="submit" color="success" onClick={()=>socialLinkDetails(prod,f,3)}>{f.social}</Button>
            
          </div>
          ))  
          :'No Social Link Attached'
         
        }
      
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'employee', name: 'employee', type: 'hidden', value: 'employee', disabled: '' },
        { title: 'sociallink', name: 'sociallink', type: 'hidden', value: 'sociallink', disabled: '',gride: 12 },
        { title: 'Employee Name', name: 'name', type: 'text', value: '', disabled: true, required: '', gride: 12 },
        { title: 'Social Link', name: 'social', type: 'text', value: '', disabled: true, required: '', gride: 12 },
        { title: 'URL', name: 'url', type: 'text', value: '', disabled: '', required: '', gride: 12 },
        { title: 'Authentication', name: 'authentication', type: 'text', value: '', disabled: '', required: '', gride: 12 },
     
      ],
      isLoading: true,
      addButton: false,
      
    },
    {
      name: 'Employee',
      title: 'Employee Profile Details',
      active: 0,
      inactive: 0,
      total: 0,
      getStatusData: getEmployeeStatus,
      getData: getEmployee,
      getPostData:postEmployee,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Name",
          sort: true,
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 4, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 4, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 4, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Name', name: 'name', type: 'text', value: '', disabled: true, required: true , gride: 4 },
        { title: 'Email', name: 'email', type: 'text', value: '', disabled: '', required: true , gride: 4 },
        { title: 'Mobile', name: 'mobile', type: 'text', value: '', disabled: '', required: true , gride: 4 },
        { title: 'User Id', name: 'userid', type: 'text', value: '', disabled: '', required: true , gride: 4 },
        { title: 'Password', name: 'password', type: 'password', value: '', disabled: '', required: true , gride: 4 },
        { title: 'Conf Password', name: 'cpassword', type: 'password', value: '', disabled: '', required: true , gride: 4 },
      ],
      isLoading: true,
      addButton: false,
      
    },
    {
      name: 'Designation',
      title: 'Designation',
      active: 0,
      inactive: 0,
      total: 0,
      getStatusData: getEmployeeStatus,
      getData: getEmployee,
      getPostData:postEmployee,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Designation",
          sort: true,
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 5, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 5, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 5, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Name', name: 'name', type: 'text', value: '', disabled: '', required: true },
        { title: 'Description', name: 'description', type: 'description', value: '', disabled: '', required: false },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
      addButton: true,
    },
    {
      name: 'Department',
      title: 'Department',
      active: 0,
      inactive: 0,
      total: 0,
      getStatusData: getEmployeeStatus,
      getData: getEmployee,
      getPostData:postEmployee,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Department Name",
          sort: true,
        },
        {
          dataField: "studentnumber",
          text: "Nuber Of Student",
          sort: true,
        },
        {
          dataField: "numberoflectrue",
          text: "Nuber Of Lecture",
          sort: true,
        },
        
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 6, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 6, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 6, prod)}
                  />
                </Link>
              </div>
            </>

          ),
        },
      ],
      active: 0,
      inactive: 0,
      total: 0,
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'department', name: 'uploadtype', type: 'hidden', value: 'uploadtype', disabled: '' },
        { title: 'Stream', name: 'stream', type: 'select', value: [], outputData: getMiscellaneous, outputDropDown: 'name', table: 'Class', status: true, disabled: '', required: true , gride: 4 },
        { title: 'Name', name: 'name', type: 'text', value: '', disabled: '', required: true,gride:4 },
        { title: 'Number Of Lecture', name: 'numberoflectrue', type: 'number', value: '', disabled: '', required: true,gride:4 },
        { title: 'Number Of Student', name: 'studentnumber', type: 'number', value: '', disabled: '', required: true,gride:4 },
        { title: 'Student Strength', name: 'studentstrength', type: 'number', value: '', disabled: '', required: true,gride:4 },
        { title: 'Course Fee', name: 'coursefees', type: 'text', value: '', disabled: '', required: true,gride:4 },
        { title: 'Study Tour', name: 'studytour', type: 'multiple', value: [], outputData: getMiscellaneous, outputDropDown: 'name', table: 'Academic Year', status: true, disabled: '', required: true , gride: 4 },
        { title: 'Seminar Year', name: 'seminar', type: 'multiple', value: [], outputData: getMiscellaneous, outputDropDown: 'name', table: 'Academic Year', status: true, disabled: '', required: true , gride: 4 },
        { title: 'Syllabus Url', name: 'syllabus', type: 'text', value: '', disabled: '', required: false,gride:4 },
        { title: 'Course Outcome URL', name: 'customeoutcome', type: 'text', value: '', disabled: '', required: false,gride:4 },
        { title: 'Image', name: 'image', type: 'file', value: [], label:'Image', uploadtype:'department', disabled: '', required: true, gride: 12 },
        { title: 'Banner Image', name: 'bannerimage', type: 'file', value: [], label:'Banner Image', uploadtype:'department', disabled: '', required: true, gride: 12 },
        { title: 'Overview', name: 'description', type: 'description', value: '', disabled: '', required: false,gride:12 },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
      addButton: true,
    },
    {
      name: 'Employee Level',
      title: 'Employee Level',
      active: 0,
      inactive: 0,
      total: 0,
      getStatusData: getEmployeeStatus,
      getData: getEmployee,
      getPostData:postEmployee,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Employee Lavel",
          sort: true,
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 7, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted",7, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 7, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Name', name: 'name', type: 'text', value: '', disabled: '', required: true },
        { title: 'Description', name: 'description', type: 'description', value: '', disabled: '', required: false },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
      addButton: true,
    },
    {
      name: 'Roles',
      title: 'Roles',
      active: 0,
      inactive: 0,
      total: 0,
      getStatusData: getEmployeeStatus,
      getData: getEmployee,
      getPostData:postEmployee,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Role Name",
          sort: true,
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 8, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 8, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 8, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Name', name: 'name', type: 'text', value: '', disabled: '', required: true },
        { title: 'Description', name: 'description', type: 'description', value: '', disabled: '', required: false },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
      addButton: true,
    },
    {
      name: 'Qualification',
      title: 'Qualification',
      active: 0,
      inactive: 0,
      total: 0,
      getStatusData: getEmployeeStatus,
      getData: getEmployee,
      getPostData:postEmployee,
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Role Name",
          sort: true,
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 9, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 9, prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 9, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Name', name: 'name', type: 'text', value: '', disabled: '', required: true },
        { title: 'Description', name: 'description', type: 'description', value: '', disabled: '', required: false },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
      addButton: true,
    },
  ])

  useEffect(async () => {
    const cardElementStatus = [...cardElement]
    for(let i=0;i<cardElementStatus.length;i++){
        cardElementStatus[i].getStatusData(cardElementStatus[i].name,cardElementStatus[i].statusList).then((res) => {
        cardElementStatus[i].active = res.active
        cardElementStatus[i].inactive = res.inactive
        cardElementStatus[i].total = res.total
        cardElementStatus[i].isLoading = false
        setCardElement([...cardElementStatus])
      })
    
    }
    setIsLoading(false)
  }, [])
  const changeTable = async (e, type, index, prod) => {
    setIndexValue(index)
    const cardElementList = [...cardElement]
    setTitle(cardElementList[index].title)
    setAddButton(cardElementList[index].title)
    if (type == 'Table' || type == true || type == false) {
      setIsLoading(true)
      await cardElement[index].getData(cardElementList[index].name, type == 'Table'?"All":status).then(result => {
        if (result.success) {
          setData(result.data)
          setIsLoading(false)
          setColumns(cardElementList[index].HeaderColumns)
          setOperationTable('Table')      
        } else {
          setData([])
          setOperationTable('Table')
          setColumns(cardElementList[index].HeaderColumns)
          setIsLoading(false)
        }
      })
    } else if (type == 'View' || type == 'Deleted' || type == 'Edit') {
      setIsLoading(true)
      await cardElement[index].getData(cardElement[index].name, prod._id).then(async result => {
        console.log(result.data)
        if (result.success) {
      
          for(let i=0;i<cardElementList[index].inputType.length;i++){
            if (cardElementList[index].inputType[i].type === 'multiple' || cardElementList[index].inputType[i].type === 'select') {
              cardElementList[index].inputType[i].value=[]
              await cardElementList[index].inputType[i].outputData(cardElementList[index].inputType[i].table,cardElementList[index].inputType[i].status).then(results => {
                  for (let k = 0; k < results.data.length; k++) {
                 
                          let data = {
                              label: results.data[k][Object.keys(results.data[k]).filter(q => q === cardElementList[index].inputType[i].outputDropDown)],
                              value: results.data[k]._id,
                          }
                          cardElementList[index].inputType[i].value.push(data)
                        }
                  })
                  setInputType([...cardElementList[index].inputType])
                  setOperationTable(type)
                  setSingleItem({ ...result.data })
                  setIsLoading(false)
                }else{
                  setInputType([...cardElementList[index].inputType])
                  setOperationTable(type)
                  setSingleItem({ ...result.data })
                  setIsLoading(false)
                }
              
            }
         
      
          //   setInputType([...cardElementList[index].inputType])
          //   setOperationTable(type)
      
            
          // setSingleItem({ ...result.data })
          // setIsLoading(false)
          // setInputType([...cardElementList[index].inputType])

          
        } else {
          setSingleItem({})
          setOperationTable(type)
          setIsLoading(false)
        }

      })
     
    }else if (type == 'Add') {
      setIsLoading(true)
      setOperationTable('Add')
      for(let i=0;i<cardElementList[index].inputType.length;i++){
        if (cardElementList[index].inputType[i].type === 'multiple' || cardElementList[index].inputType[i].type === 'select') {
          cardElementList[index].inputType[i].value=[]
          await cardElementList[index].inputType[i].outputData(cardElementList[index].inputType[i].table,cardElementList[index].inputType[i].status).then(results => {
              for (let k = 0; k < results.data.length; k++) {
             
                      let data = {
                          label: results.data[k][Object.keys(results.data[k]).filter(q => q === cardElementList[index].inputType[i].outputDropDown)],
                          value: results.data[k]._id,
                      }
                      cardElementList[index].inputType[i].value.push(data)
                    }
              })
            }
        }
     
  
        setInputType([...cardElementList[index].inputType])
        setOperationTable('Add')
  
        setIsLoading(false)
    }
  }


  const postData = (e, values) => {
    console.log(values)
    const cardElementList = [...cardElement]
    if (values[Object.keys(values).filter(k => k == 'status')] === 'false') {
      values[Object.keys(values).filter(k => k == 'status')] = false
    }
    if (operationsTable == 'Add') {
      delete values['_id'];
    }
    if(values.name !== undefined ){
      values['slug'] =values.name.replace(/[^\w\s]/gi, '').split(' ').join('-').toLowerCase()
      values['metatitle'] = values.name;
    }
    if( values.title !== undefined){
      values['slug'] =values.title.replace(/[^\w\s]/gi, '').split(' ').join('-').toLowerCase()
      values['metatitle'] = values.title;
    
    }

    if(title === 'Employee Module Assign'){
      delete values['modulepesmission'];
      values.submodule= subModuleId;
      cardElementList.find(d => d.name === 'Employee Module Assign').getPostData(isEmpty(values), 'Employee Sub Module Assign', 'Edit').then(result => {
        if (result.success) {
          setIsLoading(true)
          setColumns([...cardElementList.find(d => d.name === 'Employee Module Assign').HeaderColumns])
          setOperationTable('Table')
          getEmployee(title, status).then(result => {
            if (result.success) {
              setData(result.data)
              setIsLoading(false)
            } else {
              setData([])
              setIsLoading(false)
            }
  
          })
        } else {
          alert('Hello')
        }
      })
     }else if(title === 'Employee Document Upload'){
      cardElementList.find(d => d.name === 'Employee Document Upload').getPostData(isEmpty(values), 'Employee Document Upload', operationsTable).then(result => {
        if (result.success) {
          setIsLoading(true)
          setColumns([...cardElementList.find(d => d.name === 'Employee Document Upload').HeaderColumns])
          setOperationTable('Table')
          getEmployee(title, status).then(result => {
            if (result.success) {
              setData(result.data)
              setIsLoading(false)
            } else {
              setData([])
              setIsLoading(false)
            }
  
          })
        } else {
          alert('Hello')
        }
      })
     }else{
      cardElementList.find(d => d.title === title).getPostData(isEmpty(values), cardElementList.find(d => d.title === title).name, operationsTable).then(result => {
        if (result.success) {
          setColumns([...cardElementList.find(d => d.title === title).HeaderColumns])
          setOperationTable('Table')
          getEmployee(title, status).then(result => {
            if (result.success) {
              setData(result.data)
            } else {
              setData([])
            }
  
          })
        } else {
          alert('Hello')
        }
      })
     }
  }
  const addDescription=async (prod,pr,id,index)=>{
    const cardElementList = [...cardElement]
    setIsLoading(true)
    const allInputList = [...cardElementList[index].inputType]
     await cardElementList[index].getData('Employee Module Assign',`${prod}-${id}`).then(results=>{
      console.log(Object.keys(results.data))
      console.log(pr)
      if(Object.keys(results.data).length == 0){
        console.log(cardElementList[index].inputType[4].value=authProtectedRoutes.find(d=> d.value == pr))
        cardElementList[index].inputType[1].title=id;
        cardElementList[index].inputType[2].title=prod;
        cardElementList[index].inputType[3].title=pr;
        cardElementList[index].inputType[5].type="multiConsType"
        cardElementList[index].inputType[5].value=authProtectedRoutes.find(d=> d.value == pr).menu
        cardElementList[index].inputType[6].type='hidden';
        setInputType([...allInputList]) 
       setSingleItem({label:prod,value:pr,})
       setOperationTable('Add') 
       setIsLoading(false)
      }else{
        console.log(cardElementList[index].inputType[4].value=authProtectedRoutes.find(d=> d.value == pr))
        cardElementList[index].inputType[1].title=id;
        cardElementList[index].inputType[2].title=prod;
        cardElementList[index].inputType[3].title=pr;
        cardElementList[index].inputType[5].type="multiConsType"
        cardElementList[index].inputType[5].value=authProtectedRoutes.find(d=> d.value == pr).menu
        cardElementList[index].inputType[6].type='hidden';
        setInputType([...allInputList]) 
        setSingleItem({label:prod,value:pr,employee:id,...results.data,})
        setOperationTable('Edit') 
        setIsLoading(false)
      }
    })
   }
  const  addCardElement=async (pr,sub,index)=>{
    console.log(sub)
    const cardElementList = [...cardElement]
    setIsLoading(true)  
    const allInputList = [...cardElementList[index].inputType]
    setTitle('Employee Sub Module Assign')
    setSubModuleId(sub._id)
    await cardElementList[index].getData('Employee Sub Module Assign',`${pr._id}`).then(results=>{
      allInputList[0].title=pr._id;
      allInputList[1].title=sub.employee;
      allInputList[2].title=authProtectedRoutes.find(d=> d.value === pr.value).menu.find(k=> k.value === sub.value).label;
    //   allInputList[4].type='text';
      allInputList[5].type='text';
      allInputList[5].value='';
      allInputList[5].disabled=true;
      allInputList[6].value=authProtectedRoutes.find(d=> d.value === pr.value).menu.find(k=> k.value === sub.value).cardElement
      allInputList[7].type='hidden';
    setInputType([...allInputList]) 
    setSingleItem({...results.data,cardlist:sub.cardlist,submodulelist:authProtectedRoutes.find(d=> d.value === pr.value).menu.find(k=> k.value === sub.value).label,})
    setOperationTable('Edit') 
       setIsLoading(false)
     
   })
  }
  // Document Upload
  const uploadDocument = async (main,sub,index) => {
    setIndexValue(index)
    setIsLoading(true)
    const cardElementList = [...cardElement]
    setTitle(cardElementList[index].name)
    setAddButton(cardElementList[index].name)
    await cardElementList[index].getData(cardElementList[index].name,`${main._id}-${sub._id}`).then(results=>{
        if(results.success){
          if(Object.keys(results.data).length == 0){
            setInputType([...cardElementList[index].inputType])
            setSingleItem({...results.data,typofdocument:sub._id,associatetype:main._id,name: main.name,validationdigit:sub.validationdigit,validationtype:sub.validationtype,document:sub.document,})
            setOperationTable('Add')
            setIsLoading(false)
          }else{
            setInputType([...cardElementList[index].inputType])
            setSingleItem({...results.data,typofdocument:sub._id,associatetype:main._id,name: main.name,validationdigit:sub.validationdigit,validationtype:sub.validationtype,document:sub.document,})
           
            setOperationTable('Edit')
            setIsLoading(false)
          }
            
        }else{

        }
    })
  }
  const socialLinkDetails =async (main,sub,index)=>{
    const cardElementList = [...cardElement]
    
    setIsLoading(true)
    const allInputList = [...cardElementList[index].inputType]
     await cardElementList[index].getData(cardElementList[index].name,`${main._id}-${sub._id}`).then(results=>{
      console.log(results.data)
      if(Object.keys(results.data).length == 0){
       console.log(allInputList)
       setInputType([...allInputList]) 
       setSingleItem({...results.data,social:sub.social,name:main.name, employee:main._id,sociallink:sub._id})
       setOperationTable('Add') 
       setIsLoading(false)
      }else{
       
        setInputType([...allInputList]) 
        setSingleItem({...results.data,social:sub.social,name:main.name, employee:main._id,sociallink:sub._id})
        setOperationTable('Edit') 
        setIsLoading(false)
      }
    })
   }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Employee | {titleData.name}</title>
        </MetaTags>
        <Container fluid>
          {
            operationsTable == '' ?
              <Breadcrumbs
                title={"System Admin"}
                breadcrumbItem={"Employee"}
                bredcumlink={"system-admin"}
              />
              : <Breadcrumbs
                title={'Employee'}
                breadcrumbItem={title}
                bredcumlink={'employee'}
              />
          }

          <Row>
            {

              operationsTable == '' ?
                cardElement.map((d, index) => (
                  <Col sm={4} md={3} key={index}>
                    {
                      isLoading ?

                        <UiLoading />

                        :
                        <GalleryCardElementwithEvent
                          data={d}
                          changeTable={(e, type, title) => changeTable(e, type, index)}
                          addButton={d.addButton}
                        />
                    }

                  </Col>
                ))
                :
                operationsTable == 'Table' ?
                  <Card>
                    {
                      isLoading ?
                        <UiLoading />
                        : <ReactTable
                          title={title}
                          datas={data}
                          changeTable={(e, type, title) => changeTable(e, type, indexValue)}
                          columns={columns}
                          addButton={addButton}
                        />
                    }

                  </Card>
                  :
                  operationsTable == 'Add' || operationsTable == 'Edit' || operationsTable == 'Deleted' || operationsTable == 'View' ?
                    <>
                      {
                        isLoading ?
                          <UiLoading />
                          :
                          <InputFormElement
                            operationsTable={operationsTable}
                            singleItem={singleItem}

                            bredcumlink={'employee'}
                            viewIcon={false}
                            filetype='single'

                            title={title}
                            changeTable={(e, type) => changeTable(e, type,indexValue)}
                            inputType={inputType}
                            postData={postData}
                          />
                      }
                    </>
                    : ''
            }

          </Row>
        </Container>
      </div>
    </React.Fragment >
  )
}



export default withTranslation()(Employee)
