import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import HorizontalLayout from "../components/HorizontalLayout"
const Authmiddleware = ({
  component: Component,
  layout: Layout,
  menu,
  isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      // if (isAuthProtected && !localStorage.getItem("authUser")) {
      //   return (
      //     <Redirect
      //       to={{ pathname: "/", state: { from: props.location } }}
      //     />
      //   )
      // }

      return (
        <HorizontalLayout menu={menu}>
          <Component {...props}  />
        </HorizontalLayout>
      )
    }}
  />
)

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
  menu:PropTypes.any,
}

export default Authmiddleware;
