import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardBody
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

import { url } from "routes"
import { Link } from "react-router-dom"

import GalleryCardElementwithEvent from "UI/GalleryCardElementWithEvent"
import ReactTable from "UI/Table"

import { getpageStructurDescription,getpageStructureDescriptionStatus,postPageStructureDescription} from "data/pageDescription"
import { getpageStructureLinkStatus,getpageStructurLink,postPageStructureLink} from "data/pageStructureLink"
import InputFormElement from "UI/InputFormElement"
import { getpageStructure } from "data/pageStructure"
import { getMiscellaneous } from "data/systemadmin/miscellaneous"
import { getEmployee } from "data/systemadmin/employee"
import { isEmpty } from "data/middlefunctions"
import UiLoading from "UI/loading"
import MultiRowTable from "UI/Multirow/MultiRowTable"

const AllStudent = () => {
  const [allPageLoading,setAllPageLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [indexValue, setIndexValue] = useState('')
  const [addButton,setAddButton]=useState(false)
  const [operationsTable, setOperationTable] = useState('Card')
  const [singleItem, setSingleItem] = useState({pagedescription:'',status:true})
  const [status, setStatus] = useState('All')
  const [title, setTitle] = useState('')
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const [pageDescription,setPageDescription] = useState([{name:'goapla'}])
  const [inputElemtnType,setInputElemtnType] = useState('')
  const [inputType, setInputType] = useState([
    { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
    { title: 'uploadImages', name: 'uploadImages', type: 'hidden', value: 'dynamicpage', disabled: '' },
    { title: 'pagelink', name: 'pagelink', type: 'hidden', value: 'pagelink', disabled: '' },
    { title: 'productdescription', name: 'productdescription', type: 'hidden', value: 'productdescription', disabled: '' },
      {title:`Name`,name:'name',type:'text',value:'',disabled:true},    
    { title: 'Description', name: 'description', type: 'hidden', value: '',uploadImages:'dynamicpage',multiple:true, disabled: '', required: true, gride: 12 },
   ])
  const [sortTreeStatus,setSortTreeStatus] = useState(false)
  const [cardElement, setCardElement] = useState([])

  useEffect(async () => {
    const cardElementStatus = [...cardElement]
    await getpageStructure('Different Page Design','All').then(async result=>{
      const d=[]
      if(result.success){
        for(let i=0; i < result.data.length;i++){
          await getpageStructureDescriptionStatus(result.data[i]._id,'All').then(results=>{
            d.push({
              _id: result.data[i]._id,
              name: result.data[i].name,
              title: result.data[i].name,
              total:results.total,
              active:results.active,
              inactive:results.inactive,
              getStatusData: getpageStructurDescription,
              getData: getpageStructurDescription,
              getPostData:postPageStructureDescription,
              statusList:'All',
              HeaderColumns: [
                {
                  dataField: "_id",
                  text: "SL. No",
                  sort: true,
                  // eslint-disable-next-line react/display-name
                  formatter: (cell, prod, index) => index + 1,
                },
                {
                  dataField: "name",
                  text: "Content Title",
                  sort: true,
        
                },
                {
                  dataField: "",
                  text: "Content Details",
                  sort: true,
                  formatter: (cell, prod, index) =>
                  
                  prod.description !== undefined && prod.description !== null ? 
                  <div className="d-flex gap-3">
                    {
                       prod.description.map((d,index)=>
                       <Button
                       type="submit"
                       color="success"
                       key={index}
                       onClick={()=>addDescription(prod._id,d._id,prod,d)}
                       disabled={d.descriptiontype == 'Blank'? true :false}
                       >
                   {d.name}
                       </Button>
                       )}
                 

                    </div>
                   :'No Description'
                },
                {
                  dataField: "",
                  text: "Status",
                  sort: true,
                  formatter: (cell, prod, index) =>
                    prod.status == true ? <p>ON</p> : <p>OFF</p>,
                },
               
               
               
              ],
      
              isLoading:false,
              addButton: false,
            })
          })
          
        }
        setCardElement(d)
        setAllPageLoading(false)
      }
    })
    console.log(cardElementStatus)
    
    setIsLoading(false)
  
  }, [])
  const changeTable = async (e, type, index,prod) => {
    console.log('h')
    e.preventDefault()
   
    console.log(index)
    setIndexValue(index)
    const cardElementList = [...cardElement]
    const inputLists =[...inputType]
    setAddButton(cardElementList[index].addButton)
    setTitle(cardElementList[index].title)
    setSortTreeStatus(cardElementList[index].title)
    setIsLoading(true)
  if (type == 'Table' || type == true || type == false) {
    setColumns(cardElementList[index].HeaderColumns)
      setOperationTable('Table')
    await cardElement[index].getData(cardElementList[index]._id,type == 'Table'? "All" :type).then(result => {
        console.log(result.description)
        if (result.success) {
          setPageDescription(result.description)
          setData(result.data)
          setIsLoading(false)
        } else {
          setData([])
          setIsLoading(false)
        }
      })
     
    
    }else if (type == 'Add') {
      
      for(let i=0;i<inputLists.length;i++){
        if (inputLists[i].type === 'multiple' || inputLists[i].type === 'select') {
          inputLists[i].value=[]
          await inputLists[i].outputData(inputLists[i].table,inputLists[i].status).then(results => {
              for (let k = 0; k < results.data.length; k++) {
             
                      let data = {
                          label: results.data[k][Object.keys(results.data[k]).filter(q => q === inputLists[i].outputDropDown)],
                          value: results.data[k]._id,
                      }
                      inputLists[i].value.push(data)
                    }
              })
                singleItem.pagedescription=prod.name
              singleItem.pagedescriptionid =prod._id
              console.log(singleItem)
              setSingleItem({...singleItem})
              
              setInputType([...inputLists])
              setOperationTable('Add')
      
              setIsLoading(false)
            }else{
              singleItem.pagedescription=prod.name
              singleItem.pagedescriptionid =prod._id
               console.log(singleItem)
              setSingleItem({...singleItem})
              
              setInputType([...inputLists])
              setOperationTable('Add')
       
              setIsLoading(false)
            }
        }
        
    }else{
    
      setInputType([...inputLists])
      if(type == 'Edit' || type == 'View' || type == 'Deleted'){
        if(cardElement[index].name === 'Different Page Relation'){
          setSortTreeStatus(true)
        }
        await cardElement[index].getData(cardElement[index].name,prod._id).then(async result=>{
         
          for(let i=0;i<inputLists.length;i++){
        
            if (inputLists[i].type === 'multiple' || inputLists[i].type === 'select') {
              inputLists[i].value=[]
              await inputLists[i].outputData(inputLists[i].table,inputLists[i].status).then(results => {
                  for (let k = 0; k < results.data.length; k++) {
                 
                          let data = {
                              label: results.data[k][Object.keys(results.data[k]).filter(q => q === inputLists[i].outputDropDown)],
                              value: results.data[k]._id,
                          }
                          inputLists[i].value.push(data)
                        }
                  })
                  setSingleItem(result.data)
                  setInputType([...inputLists])
                  setOperationTable(type)
                  setIsLoading(false)
                }else{
                  setSingleItem(results.data)
                  setInputType([...inputLists])
                  setOperationTable(type)
                  setIsLoading(false)
                }
            }
        
   
        })
      }

    }
  }
  const addDescription=async (prodid,id,pro,prod)=>{
    setIsLoading(true)
    console.log(prod.descriptiontype === "Add More One Text Box and One Text Area")
    const allInputList = [...inputType]
     await getpageStructurDescription(prodid,id).then(results=>{
      if(Object.keys(results.data).length == 0){
        if(prod.descriptiontype === 'file'){
          allInputList[5].label='Upload Image'
          allInputList[5].name='image'
          allInputList[5].type=prod.descriptiontype;
          allInputList[5].value=[]
        }else if(prod.descriptiontype === 'Add More'){
          allInputList[5].name=prod.descriptiontype
          allInputList[5].type=prod.descriptiontype;
          
        }else if(prod.descriptiontype === 'videourl'){
          allInputList[5].name='video'
          allInputList[5].type='text';
          
        }else if(prod.descriptiontype === 'refurl'){
          allInputList[5].name='refurl'
          allInputList[5].type='text';
          
        }else if(prod.descriptiontype === 'quotation'){
          allInputList[5].name='quotation'
          allInputList[5].type='textarea';
          
        }else if(prod.descriptiontype ==='Add More One Text Box and One Text Area'){
         console.log(prod.descriptiontype)
          setInputElemtnType('Add More Text')
          }else{
                    allInputList[5].name=prod.descriptiontype
                    allInputList[5].type=prod.descriptiontype;
          
                    
                  }
       setInputType([...allInputList]) 
       setSingleItem({name:pro.name,productdescription:id,pagelink:prodid})
       setOperationTable('Add') 
       setIsLoading(false)
      }else{
        if(prod.descriptiontype === 'file'){
          allInputList[5].label='Upload Image'
          allInputList[5].name='image'
          allInputList[5].type=prod.descriptiontype;
          allInputList[5].value=[]
        }else if(prod.descriptiontype === 'Add More'){
          allInputList[5].name=prod.descriptiontype
          allInputList[5].type=prod.descriptiontype;
          
        }else if(prod.descriptiontype === 'videourl'){
          allInputList[5].name='video'
          allInputList[5].type='text';
          
        }else if(prod.descriptiontype === 'refurl'){
          allInputList[5].name='refurl'
          allInputList[5].type='text';
          
        }else if(prod.descriptiontype === 'quotation'){
          allInputList[5].name='quotation'
          allInputList[5].type='textarea';
          
        }else if(prod.descriptiontype ==='Add More One Text Box and One Text Area'){
         
          setInputElemtnType('Add More Text')
          }else{
                    allInputList[5].name=prod.descriptiontype
                    allInputList[5].type=prod.descriptiontype;
          
                    
                  }
        setInputType([...allInputList]) 
        setSingleItem({name:pro.name,...results.data})
        setOperationTable('Edit') 
        setIsLoading(false)
      }
     console.log(results)
    })
   }

  const postData = (e, values) => {
    const cardElementList = [...cardElement]
    if (values[Object.keys(values).filter(k => k == 'status')] === 'false') {
      values[Object.keys(values).filter(k => k == 'status')] = false
    }
    if (operationsTable == 'Add') {
      delete values['_id'];
    }
    setIsLoading(true)
    cardElementList[indexValue].getPostData(isEmpty(values),cardElementList[indexValue].name, operationsTable).then(async result => {
      console.log(cardElementList[indexValue])
      if (result.success) {
        setOperationTable('Table')
        await cardElement[indexValue].getData(cardElementList[indexValue]._id, "All").then(results => {
            console.log(result.description)
            if (results.success) {
             
              setData(results.data)
              setIsLoading(false)
            } else {
              setData([])
              setIsLoading(false)
            }
          })
         
      } else {
        alert('Hello')
      }
    })
  }
  const postAddMoreData = (e, values,type) => {
    console.log(values[type])
    const FilteValue=[]

   values[type].pop()

        const cardElementList = [...cardElement]
        singleItem[type]= values[type]
        console.log(singleItem[type])
        console.log(singleItem[type])
        cardElementList[indexValue].getPostData(singleItem, cardElementList[indexValue].name, operationsTable).then(result => {
          if (result.success) {
            setColumns([...cardElementList[indexValue].HeaderColumns])
            setOperationTable('Table')
            cardElementList[indexValue].getData(cardElementList[indexValue]._id, "All").then(result => {
              if (result.success) {
                setData(result.data)
              } else {
                setData([])
              }
    
            })
          } else {
            alert('Hello')
          }
        })
      }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Content Manager | {url.title}</title>
        </MetaTags>
        <Container fluid>
          {
            operationsTable == 'Card' ?
              <Breadcrumbs
                title={"Dynamic Page Dashboard"}
                breadcrumbItem={"Content Manager"}
                bredcumlink={"dynamic-page-structure"}
              />
              : <Breadcrumbs
                title={'Content Manager'}
                breadcrumbItem={title}
                bredcumlink={'page-description'}
              />
          }

          <Row>
            {
              allPageLoading?
              <UiLoading cardElementOption={!allPageLoading} />:
              operationsTable == 'Card' ?
                cardElement.map((d, index) => (
                  <Col sm={4} md={3} key={index}> 
                
                        <GalleryCardElementwithEvent
                          data={d}
                          changeTable={(e, type) => changeTable(e, type, index,d)}
                          addButton={d.addButton}
                          isLoading={isLoading}
                        />
                  </Col>
                ))
                :
                operationsTable == 'Table' ?
                 
                     <ReactTable
                          title={title}
                          datas={data}
                          changeTable={(e, type) => changeTable(e, type, indexValue)}
                          columns={columns}
                          addButton={addButton}
                          
                        />
                :
              
               inputElemtnType === 'Add More Text'?
               <Card>
                <CardBody>
                {
          isLoading?
          <UiLoading cardElementOption={isLoading} />
          :
                <MultiRowTable
               handleValidSubmits={(e,v)=>postAddMoreData(e,v,'addmoretext')} 
               inputList={{ id: Date.now().toString(), title: '',value:''}} 
               type={'addmoretext'}
                addMoreTable={singleItem['addmoretext'] !== undefined ? singleItem['addmoretext'] :[]} 
               multiRowheader={[{ title: "Sl. No." }, { title: "title" },{title:'value'},{ title: 'Deleted' }]}
        
               saveButton={operationsTable}
               />
                }
                </CardBody>
               </Card>
                
               :
                          <InputFormElement
                            operationsTable={operationsTable}
                            singleItem={singleItem}

                            bredcumlink={'InformationPage'}
                            viewIcon={false}
                            filetype='single'

                            title={title}
                            changeTable={(e, type) => changeTable(e, type, indexValue)}
                            inputType={inputType}
                            postData={postData}
                            isLoading={isLoading}
                          />
                      
               
            }

          </Row>
        </Container>
      </div>
    </React.Fragment >
  )
}



export default AllStudent
