import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  Button,

} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { removeNodeAtPath } from 'react-sortable-tree';

import { url } from "routes"
import { Link } from "react-router-dom"

import GalleryCardElementwithEvent from "UI/GalleryCardElementWithEvent"
import ReactTable from "UI/Table"


import { getpageStructureStatus,getpageStructure,postPageStructure} from "data/pageStructure"
import InputFormElement from "UI/InputFormElement"

import SortTreeRelation from "UI/sortTreeRelation"
import UiLoading from "UI/loading"
import { getMiscellaneous } from "data/miscellaneous"
const pageStructure = () => {
  const [allPageLoading,setAllPageLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [indexValue, setIndexValue] = useState('')
  const [addButton,setAddButton]=useState(false)
  const [operationsTable, setOperationTable] = useState('Card')
  const [singleItem, setSingleItem] = useState({status:true})
  const [status, setStatus] = useState('All')
  const [title, setTitle] = useState('')
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const [inputType, setInputType] = useState([])
  const [treeData,setTreeData] = useState([])
  const [cardElement, setCardElement] = useState([
    {
      name: 'Different Description Type',
      title: 'Description Types',
      active: 0,
      inactive: 0,
      total: 0,
      getStatusData: getpageStructureStatus,
      getData: getpageStructure,
      getPostData:postPageStructure,
      statusList:'All',
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Description Title",
          sort: true,

        },
        {
          dataField: "descriptiontype",
          text: "File Type",
          sort: true,

        },
        {
          dataField: "mobilegride",
          text: "Mobile Gride",
          sort: true,

        },
        {
          dataField: "midiumgride",
          text: "Middle Gride",
          sort: true,

        },
        {
          dataField: "largegride",
          text: "Large Gride",
          sort: true,

        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 0,prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 0,prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 0,prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Title', name: 'name', type: 'text', value: '', disabled: '', required: true, gride: 12 },
        { title: 'Parameters', name: 'descriptiontype', type: 'consType', value: [{label:'Blank',value:'Blank'},{label:'Image',value:'file'},{label:'Add More',value:'Add More'},{label:'Add More One Text Box and One Text Area',value:'Add More One Text Box and One Text Area'},{label:'Video Url',value:'videourl'},{label:'Ref Url',value:'refurl'},{label:'Description',value:'description'},{label:'Text Area',value:'textarea'}], disabled: '', required: true, gride: 12 },
        { title: 'Mobile Device Gride', name: 'mobilegride', type: 'consType', value:[{label:'1',value:'1'},{label:'2',value:'2'},{label:'3',value:'3'},{label:'4',value:'4'},{label:'5',value:'5'},{label:'6',value:'6'},{label:'7',value:'7'},{label:'8',value:'8'},{label:'9',value:'9'},{label:'10',value:'10'},{label:'11',value:'11'},{label:'12',value:'12'}], disabled: '', required: '', gride: 12 },
        { title: 'Medium Device Gride', name: 'midiumgride', type: 'consType', value:[{label:'1',value:'1'},{label:'2',value:'2'},{label:'3',value:'3'},{label:'4',value:'4'},{label:'5',value:'5'},{label:'6',value:'6'},{label:'7',value:'7'},{label:'8',value:'8'},{label:'9',value:'9'},{label:'10',value:'10'},{label:'11',value:'11'},{label:'12',value:'12'}], disabled: '', required: '', gride: 12 },
        { title: 'Large Device Gride', name: 'largegride', type: 'consType', value:[{label:'1',value:'1'},{label:'2',value:'2'},{label:'3',value:'3'},{label:'4',value:'4'},{label:'5',value:'5'},{label:'6',value:'6'},{label:'7',value:'7'},{label:'8',value:'8'},{label:'9',value:'9'},{label:'10',value:'10'},{label:'11',value:'11'},{label:'12',value:'12'}], disabled: '', required: true, gride: 12 },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading: true,
      addButton: true,
     
    },
    {
      name: 'Different Page Design',
      title: 'Page Name',
      active: 0,
      inactive: 0,
      total: 0,
      getStatusData: getpageStructureStatus,
      getData: getpageStructure,
      getPostData:postPageStructure,
      statusList:'All',

      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true, 
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Page Name",
          sort: true,

        },
        {
          dataField: "pagedescriptionlink",
          text: "Main Description Details",
          sort: true,
          formatter: (cell, prod, index) =>
            // prod.mainpagedescriptionlink.length !== 0  ?  prod.mainpagedescriptionlink.map(d=> `${d.name},`) : <p>OFF</p>,
            prod.mainpagedescriptionlink.length !== 0  ?  prod.mainpagedescriptionlink.length : <p>OFF</p>,
          
        },
        {
          dataField: "pagedescriptionlink",
          text: "Description Details",
          sort: true,
          formatter: (cell, prod, index) =>
            // prod.pagedescriptionlink.length !== 0  ?  prod.pagedescriptionlink.map(d=> `${d.name},`) : <p>OFF</p>,
            prod.pagedescriptionlink.length !== 0  ?  prod.pagedescriptionlink.length: <p>OFF</p>,
          
        },
 
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 1,prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 1,prod)}
                  />  
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 1,prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Page Name', name: 'name', type: 'text', value: '', disabled: '', required: true, gride: 12 },
        { title: 'Page Description', name: 'mainpagedescriptionlink', type: 'multiple', value: [], select: {},outputDropDown:'name', outputData:getpageStructure,table:'Different Description Type',status:true, required: true, gride: 12 },
        { title: 'Sub Page Description', name: 'pagedescriptionlink', type: 'multiple', value: [], select: {},outputDropDown:'name', outputData:getpageStructure,table:'Different Description Type',status:true, required: true, gride: 12 },
        { title: 'Gallery Year', name: 'galleryyear', type: 'multiple', value: [], outputDropDown:'name', outputData:getMiscellaneous,table:'Academic Year',status:true, required: true, gride: 12 },
        { title: 'Report Year', name: 'reportyear', type: 'multiple', value: [], outputDropDown:'name', outputData:getMiscellaneous,table:'Academic Year',status:true, required: true, gride: 12 },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading:true,
      addButton: true,
      
    },
    {
      name: 'Main Page Structure',
      title: 'Main Page Structure',
      active: 0,
      inactive: 0,
      total: 0,
      getStatusData: getpageStructureStatus,
      getData: getpageStructure,
      getPostData:postPageStructure,
      statusList:'All',
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Page Name",
          sort: true,

        },
        {
          dataField: "pagedescriptionlink",
          text: "Description Details",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.mainpagedescriptionlink.length !== 0  ?  prod.mainpagedescriptionlink.map(d=> `${d.name},`) : '',
          
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "pagedescriptionlink",
          text: "Page Structure",
          sort: true,
          formatter: (cell, prod, index) =>
          <Button
          
          color="success"
          onClick={e => descriptionRelationShip(e,prod,2,'Main Page Structure')}
        >
        Page Structure
        </Button>
          
        },
       
       
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Title', name: 'name', type: 'text', value: '', disabled: '', required: true, gride: 12 },
        { title: 'Type', name: 'type', type: 'consType', value: [{label:'Image',value:'image'}],  required: true, gride: 12 },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading:true,
      addButton: false,
      
    },
    {
      name: 'Different Page Relation',
      title: 'Content Structure',
      active: 0,
      inactive: 0,
      total: 0,
      getStatusData: getpageStructureStatus,
      getData: getpageStructure,
      getPostData:postPageStructure,
      statusList:'All',
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Page Name",
          sort: true,

        },
        {
          dataField: "pagedescriptionlink",
          text: "Description Details",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.pagedescriptionlink.length !== 0  ?  prod.pagedescriptionlink.map(d=> `${d.name},`) : '',
          
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "pagedescriptionlink",
          text: "Page Structure",
          sort: true,
          formatter: (cell, prod, index) =>
          <Button
          
          color="success"
          onClick={e => descriptionRelationShip(e,prod,3,'Different Page Relation')}
        >
        Page Structure
        </Button>
          
        },
       
       
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Title', name: 'name', type: 'text', value: '', disabled: '', required: true, gride: 12 },
        { title: 'Type', name: 'type', type: 'consType', value: [{label:'Image',value:'image'}],  required: true, gride: 12 },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading:true,
      addButton: false,
      
    },
    {
      name: 'Standard Page Design',
      title: 'Fix Page',
      active: 0,
      inactive: 0,
      total: 0,
      getStatusData: getpageStructureStatus,
      getData: getpageStructure,
      getPostData:postPageStructure,
      statusList:'All',

      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true, 
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "typeofpage",
          text: "Page Name",
          sort: true,

        },
        {
          dataField: "pagedescriptionlink",
          text: "Content Details",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.pagedescriptionlink.length !== 0  ?  prod.pagedescriptionlink.map(d=> `${d.name},`) : <p>OFF</p>,
          
        },
 
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 4,prod)}
                  />
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 4,prod)}
                  />  
                </Link>
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 4,prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Page Title', name: 'name', type: 'text',value:'',disabled:'',required:true },
        { title: 'Page Name', name: 'typeofpage', type: 'consType', value: [{label:'Contact Us',value:'Contact Us'},{label:'Blog',value:'Blog'},{label:'Poll',value:'Poll'},{label:'Career',value:'Career'},{label:'Information',value:'Information'},{label:'Online Schedule',value:'Online Schedule'},], disabled: '', required: true, gride: 12 },
        { title: 'Content Details', name: 'pagedescriptionlink', type: 'multiple', value: [], select: {},outputDropDown:'name', outputData:getpageStructure,table:'Different Description Type',status:true, required: true, gride: 12 },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading:true,
      addButton: true,
      
    },
    {
      name: 'Standard Page Design Relation',
      title: 'Fix Page Content',
      active: 0,
      inactive: 0,
      total: 0,
      getStatusData: getpageStructureStatus,
      getData: getpageStructure,
      getPostData:postPageStructure,
      statusList:'All',
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "typeofpage",
          text: "Page Name",
          sort: true,

        },
        {
          dataField: "pagedescriptionlink",
          text: "Content Details",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.pagedescriptionlink.length !== 0  ?  prod.pagedescriptionlink.map(d=> `${d.name},`) : '',
          
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "pagedescriptionlink",
          text: "Page Structure",
          sort: true,
          formatter: (cell, prod, index) =>
          <Button
          
          color="success"
          onClick={e => descriptionRelationShip(e,prod,5,'Different Page Relation')}
        >
       Page Structure
        </Button>
          
        },
       
       
      ],
      inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Type Of Page', name: 'typeofpage', type: 'consType', value: [{label:'Blog',value:'Blog'},{label:'Poll',value:'Poll'},{label:'Information',value:'Information'},{label:'About Us',value:'About Us'},{label:'Contact Us',value:'Contact Us'},{label:'Register',value:'Register'},{label:'Login',value:'Login'},{label:'Forgot Password',value:'Forgot Password'}], disabled: '', required: true, gride: 12 },
        { title: 'Page Description Type', name: 'pagedescriptionlink', type: 'multiple', value: [], select: {},outputDropDown:'name', outputData:getpageStructure,table:'Different Description Type',status:true, required: true, gride: 12 },
        { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
      ],
      isLoading:true,
      addButton: false,
      
    },
  ])

  useEffect(async () => {
    const cardElementStatus = [...cardElement]
    for(let i=0;i<cardElementStatus.length;i++){
        cardElementStatus[i].getStatusData(cardElementStatus[i].name,cardElementStatus[i].statusList).then((res) => {
        cardElementStatus[i].active = res.active
        cardElementStatus[i].inactive = res.inactive
        cardElementStatus[i].total = res.total
        cardElementStatus[i].isLoading = false
        setCardElement([...cardElementStatus])
      })
    }
    setAllPageLoading(false)
  }, [])
  const changeTable = async (e, type, index,prod) => {
    e.preventDefault()
    setIsLoading(true)
    setIndexValue(index)
    const cardElementList = [...cardElement]
    setAddButton(cardElementList[index].addButton)
    setTitle(cardElementList[index].title)

  if (type == 'Table' || type == true || type == false) {
      setColumns(cardElementList[index].HeaderColumns)
      setOperationTable('Table')
    await cardElement[index].getData(cardElementList[index].name,type == 'Table'? "All" :type).then(result => {
        
        if (result.success) {
          setData(result.data)
        } else {
          setData([])
        }
      })
      setIsLoading(false)
    }else if (type == 'Add') {
    for(let i=0;i<cardElementList[index].inputType.length;i++){
      if (cardElementList[index].inputType[i].type === 'multiple' || cardElementList[index].inputType[i].type === 'select') {
        cardElementList[index].inputType[i].value=[]
        await cardElementList[index].inputType[i].outputData(cardElementList[index].inputType[i].table,cardElementList[index].inputType[i].status).then(results => {
            for (let k = 0; k < results.data.length; k++) {
           
                    let data = {
                        label: results.data[k][Object.keys(results.data[k]).filter(q => q === cardElementList[index].inputType[i].outputDropDown)],
                        value: results.data[k]._id,
                    }
                    cardElementList[index].inputType[i].value.push(data)
                  }
            })
          }
      }
   

      setInputType([...cardElementList[index].inputType])
      setOperationTable('Add')

      setIsLoading(false)
    }else{
    
      setInputType([...cardElement[index].inputType])
      if(type == 'Edit' || type == 'View' || type == 'Deleted'){
        if(cardElement[index].name === 'Different Page Relation'){
          setSortTreeStatus(true)
        }
        await cardElement[index].getData(cardElement[index].name,prod._id).then(async results=>{
          setSingleItem(results.data)
          for(let i=0;i<cardElementList[index].inputType.length;i++){
        
            if (cardElementList[index].inputType[i].type === 'multiple' || cardElementList[index].inputType[i].type === 'select') {
              cardElementList[index].inputType[i].value=[]
              await cardElementList[index].inputType[i].outputData(cardElementList[index].inputType[i].table,cardElementList[index].inputType[i].status).then(results => {
                  for (let k = 0; k < results.data.length; k++) {
                 
                          let data = {
                              label: results.data[k][Object.keys(results.data[k]).filter(q => q === cardElementList[index].inputType[i].outputDropDown)],
                              value: results.data[k]._id,
                          }
                          cardElementList[index].inputType[i].value.push(data)
                        }
                  })
                }
            }
          setIsLoading(false)
          setInputType([...cardElementList[index].inputType])
          setOperationTable(type)
        })
      }

    }
  }


  const postData = (e, values) => {
    const cardElementList = [...cardElement]
    if (values[Object.keys(values).filter(k => k == 'status')] === 'false') {
      values[Object.keys(values).filter(k => k == 'status')] = false
    }
    if (operationsTable == 'Add') {
      delete values['_id'];
    }
    if(values.name !== undefined ){
      values['slug'] =values.name.replace(/[^\w\s]/gi, '').split(' ').join('-').toLowerCase()
      values['metatitle'] = values.name;
    }
    if( values.title !== undefined){
      values['slug'] =values.title.replace(/[^\w\s]/gi, '').split(' ').join('-').toLowerCase()
      values['metatitle'] = values.title;
    }
    
    if( values.typeofpage !== undefined){
      values['slug'] =values.typeofpage.replace(/[^\w\s]/gi, '').split(' ').join('-').toLowerCase()
      values['metatitle'] = values.typeofpage;
    }
    setIsLoading(true)
    cardElementList[indexValue].getPostData(values,cardElementList[indexValue].name, operationsTable).then(result => {
      if (result.success) {
        setColumns([...cardElementList[indexValue].HeaderColumns])
        setOperationTable('Table')
        cardElementList[indexValue].getData(cardElementList[indexValue].name, status).then(result => {
          if (result.success) {
            setData(result.data)
            setIsLoading(false)
          } else {
            setData([])
            setIsLoading(false)
          }

        })
      } else {
        alert('Hello')
      }
    })
  }
  const descriptionRelationShip=async (e,prod,index,title)=>{
    console.log('ljaldf')
    setOperationTable('Tree')
    const cardElementStatus = [...cardElement]
    setIndexValue(index)
    setIsLoading(true)
    await cardElementStatus[index].getData(cardElement[index].name,prod._id).then((result)=>{
      if (result.success) {
       
        setSingleItem(result.data)
        if(title === 'Different Page Relation'){

        
        if(result.data.descriptionrelation.length != 0){
          const treeDatas =[]
          for(let i=0;i<result.data.descriptionrelation.length;i++){
              const d={
                title:result.data.descriptionrelation[i].titles.name,
                titles:result.data.descriptionrelation[i].titles._id,
                children:[],
                expanded: true,
              }
              if(result.data.descriptionrelation[i].children !== 0){
                for(let j=0;j<result.data.descriptionrelation[i].children.length;j++){
                  d.children.push({
                    title:result.data.descriptionrelation[i].children[j].titles.name,
                    titles:result.data.descriptionrelation[i].children[j].titles._id,
                    children:[],
                    expanded: true,
                  })
                  if(result.data.descriptionrelation[i].children[j].children !== 0){
                    for(let k=0;k<result.data.descriptionrelation[i].children[j].children.length;k++){
                      d.children[j].children.push({
                        title:result.data.descriptionrelation[i].children[j].children[k].titles.name,
                        titles:result.data.descriptionrelation[i].children[j].children[k].titles._id,
                        children:[],
                        expanded: true,
                      })
                      if(result.data.descriptionrelation[i].children[j].children[k].children !== 0){
                        for(let l=0;l<result.data.descriptionrelation[i].children[j].children[k].children.length;l++){
                          d.children[j].children[k].children.push({
                            title:result.data.descriptionrelation[i].children[j].children[k].children[l].titles.name,
                            titles:result.data.descriptionrelation[i].children[j].children[k].children[l].titles._id,
                            children:[],
                            expanded: true,
                          })
                          if(result.data.descriptionrelation[i].children[j].children[k].children[l].children !== 0){
                            for(let m=0;m<result.data.descriptionrelation[i].children[j].children[k].children[l].children.length;m++){
                              d.children[j].children[k].children[l].children.push({
                                title:result.data.descriptionrelation[i].children[j].children[k].children[l].children[m].titles.name,
                                titles:result.data.descriptionrelation[i].children[j].children[k].children[l].children[m].titles._id,
                                children:[],
                                expanded: true,
                              })
                            }
                            
                          }
                        }
                       
                      }
                    }
                  
                  }
                
                }
            
                  
              }
             
              treeDatas.push(d)
          }
          setTreeData(treeDatas)
        
        }
      }else{
        if(result.data.maindescriptionrelation.length != 0){
          const treeDatas =[]
          for(let i=0;i<result.data.maindescriptionrelation.length;i++){
              const d={
                title:result.data.maindescriptionrelation[i].titles.name,
                titles:result.data.maindescriptionrelation[i].titles._id,
                children:[],
                expanded: true,
              }
              if(result.data.maindescriptionrelation[i].children !== 0){
                for(let j=0;j<result.data.maindescriptionrelation[i].children.length;j++){
                  d.children.push({
                    title:result.data.maindescriptionrelation[i].children[j].titles.name,
                    titles:result.data.maindescriptionrelation[i].children[j].titles._id,
                    children:[],
                    expanded: true,
                  })
                  if(result.data.maindescriptionrelation[i].children[j].children !== 0){
                    for(let k=0;k<result.data.maindescriptionrelation[i].children[j].children.length;k++){
                      d.children[j].children.push({
                        title:result.data.maindescriptionrelation[i].children[j].children[k].titles.name,
                        titles:result.data.maindescriptionrelation[i].children[j].children[k].titles._id,
                        children:[],
                        expanded: true,
                      })
                      if(result.data.maindescriptionrelation[i].children[j].children[k].children !== 0){
                        for(let l=0;l<result.data.maindescriptionrelation[i].children[j].children[k].children.length;l++){
                          d.children[j].children[k].children.push({
                            title:result.data.maindescriptionrelation[i].children[j].children[k].children[l].titles.name,
                            titles:result.data.maindescriptionrelation[i].children[j].children[k].children[l].titles._id,
                            children:[],
                            expanded: true,
                          })
                          if(result.data.maindescriptionrelation[i].children[j].children[k].children[l].children !== 0){
                            for(let m=0;m<result.data.maindescriptionrelation[i].children[j].children[k].children[l].children.length;m++){
                              d.children[j].children[k].children[l].children.push({
                                title:result.data.maindescriptionrelation[i].children[j].children[k].children[l].children[m].titles.name,
                                titles:result.data.maindescriptionrelation[i].children[j].children[k].children[l].children[m].titles._id,
                                children:[],
                                expanded: true,
                              })
                            }
                            
                          }
                        }
                       
                      }
                    }
                  
                  }
                
                }
            
                  
              }
             
              treeDatas.push(d)
          }
          setTreeData(treeDatas)
        
        }
      }
        setIsLoading(false)
      } else {
        setData({})
        setIsLoading(false)
      }
    })
  }
  const saveTreeData=()=>{
    const cardElementList = [...cardElement]
  
    setIsLoading(true)
    cardElementList[indexValue].getPostData( {_id:singleItem._id,treeData:treeData},cardElementList[indexValue].name, 'Edit').then(result => {
      if (result.success) {
        setColumns([...cardElementList[indexValue].HeaderColumns])
        setOperationTable('Table')
        cardElementList[indexValue].getData(cardElementList[indexValue].name, status).then(result => {
          if (result.success) {
            setData(result.data)
            setIsLoading(false)
          } else {
            setData([])
            setIsLoading(false)
          }

        })
      } else {
        alert('Hello')
      }
    })
  }
  const treeDataValue=(tree)=>{
   
    setTreeData([...tree])
  }
  const removeNode =(rowInfo,indexValue)=>{
    const cardElements = [...cardElement]
    let {node, treeIndex, path} = rowInfo;
 
 
    
  const remove_data = removeNodeAtPath({
      treeData: treeData,
      path: path,   // You can use path from here
      getNodeKey: ({node: TreeNode, treeIndex: number}) => {
          // console.log(number);
          return number;
      },
      ignoreCollapsed: false,
   })
   setTreeData(remove_data)

   postPageStructure({
    _id:singleItem._id,
    remove_data:remove_data,
    rowid:rowInfo.node.titles
  },cardElements[indexValue].name ,'Deleted').then(result=>{
    console.log(result)
  })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Page Maker | {url.title}</title>
        </MetaTags>
        <Container fluid>
          {
            operationsTable == 'Card' ?
              <Breadcrumbs
                title={"Dynamic Pages Dashboard"}
                breadcrumbItem={"Page Maker"}
                bredcumlink={"page-structure"}
              />
              : <Breadcrumbs
                title={'Page Maker'}
                breadcrumbItem={title}
                bredcumlink={'page-structure'}
              />
          }

          <Row>
            {
    
      allPageLoading?
      <UiLoading cardElementOption={!allPageLoading} />
      :
              operationsTable == 'Card' ?
                cardElement.map((d, index) => (
                  <Col sm={4} md={3} key={index}> 
                
                        <GalleryCardElementwithEvent
                          data={d}
                          changeTable={(e, type) => changeTable(e, type, index)}
                          addButton={d.addButton}
                        />
                  </Col>
                ))
                :
                operationsTable == 'Table' ?
               
                       isLoading ?
                     <UiLoading cardElementOption ={!isLoading ?true :false}/>
                  :
                     <ReactTable
                          title={title}
                          datas={data}
                          changeTable={(e, type) => changeTable(e, type, indexValue)}
                          columns={columns}
                          addButton={addButton}
                          isLoading={isLoading}
                        />
                    

                
                  :  operationsTable == 'Tree' ?
                  
                    isLoading ?
                     <UiLoading cardElementOption ={!isLoading ?true :false}/>
                  :
                  <SortTreeRelation   isLoading={isLoading} treeData={treeData} saveTreeData={()=>saveTreeData()} treeDataValue={(tree)=>treeDataValue(tree)} removeNode={(rowInfo)=>removeNode(rowInfo,indexValue)}/>
                  
                  :      
                  isLoading ?
                  <UiLoading cardElementOption ={!isLoading ?true :false}/>
               : 
                            <InputFormElement
                            operationsTable={operationsTable}
                            singleItem={singleItem}
                            bredcumlink={'page-structure'}
                            viewIcon={false}
                            filetype='single'
                            title={title}
                            changeTable={(e, type) => changeTable(e, type, indexValue)}
                            inputType={inputType}
                            postData={postData}
                            isLoading={isLoading}
                          />
                          }

          </Row>
        </Container>
      </div>
    </React.Fragment >
  )
}



export default pageStructure
