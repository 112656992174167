import React from 'react';
import {Card, CardBody } from 'reactstrap';
import PropTypes from 'prop-types';
export const UiLoading= ({cardElementOption})=>{
    return(
      <Card>
        <CardBody>
          {
            cardElementOption?
               <button
        type="button"
        className="btn btn-dark "
        style={{ background: '#FFFFFF',border: 'none'}}
      >
        <span style={{color:'black'}}>
        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
        Loading...
        </span>
      </button> 
            
            : <div id="preloader">
            <div id="status">
              <div className="spinner-chase">
                <div className="chase-dot" />
                <div className="chase-dot" />
                <div className="chase-dot" />
                <div className="chase-dot" />
                <div className="chase-dot" />
                <div className="chase-dot" />
              </div>
            </div>
          </div>
          }
       
    
        </CardBody>
      </Card>
       
    )
}
UiLoading.propTypes = {
  cardElementOption: PropTypes.bool,
};
export default UiLoading;