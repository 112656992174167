
import React, { useState } from 'react';
import SortableTree from 'react-sortable-tree';
import { removeNodeAtPath } from 'react-sortable-tree';
import MetaTags from "react-meta-tags"
import {
    Container,
    Row,
    Col,
    Card,
    Button,
    CardBody,
    Breadcrumb
  } from "reactstrap"
import 'react-sortable-tree/style.css'; // This only needs to be imported once in your app
import { url } from 'routes';
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { useEffect } from 'react';
import { getMenuTree, postMenuTree } from 'data/menumangment/menutree';
import UiLoading from 'UI/loading';
export  const  HeaderTree =()=> {
  const [isLoading,setIsLoading]=useState(true)
  const [tree,setTreeData] = useState( [])
  useEffect(() => {
    getMenuTree().then(result=>{
    
      if(result.success){
        const treeDatas =[]
        for(let i=0;i<result.data.length;i++){
            const d={
              title:result.data[i].titles.title,
              titles:result.data[i].titles._id,
              children:[],
              expanded: true,
            }
            if(result.data[i].children !== 0){
              for(let j=0;j<result.data[i].children.length;j++){
                d.children.push({
                  title:result.data[i].children[j].titles.title,
                  titles:result.data[i].children[j].titles._id,
                  children:[],
                  expanded: true,
                })
                if(result.data[i].children[j].children !== 0){
                  for(let k=0;k<result.data[i].children[j].children.length;k++){
                    d.children[j].children.push({
                      title:result.data[i].children[j].children[k].titles.title,
                      titles:result.data[i].children[j].children[k].titles._id,
                      children:[],
                      expanded: true,
                    })
                    if(result.data[i].children[j].children[k].children !== 0){
                      for(let l=0;l<result.data[i].children[j].children[k].children.length;l++){
                        d.children[j].children[k].children.push({
                          title:result.data[i].children[j].children[k].children[l].titles.title,
                          titles:result.data[i].children[j].children[k].children[l].titles._id,
                          children:[],
                          expanded: true,
                        })
                        if(result.data[i].children[j].children[k].children[l].children !== 0){
                          for(let m=0;m<result.data[i].children[j].children[k].children[l].children.length;m++){
                            d.children[j].children[k].children[l].children.push({
                              title:result.data[i].children[j].children[k].children[l].children[m].titles.title,
                              titles:result.data[i].children[j].children[k].children[l].children[m].titles._id,
                              children:[],
                              expanded: true,
                            })
                          }
                          
                        }
                      }
                     
                    }
                  }
                
                }
              
              }
          
                
            }
           
            treeDatas.push(d)
        }
        setTreeData(treeDatas)
        setIsLoading(false)
      }
     
    })
  },[])
const treeDataValue=(tree)=>{
  setTreeData(tree)
 
}
const saveTreeData=()=>{
  postMenuTree(tree).then(result=>{
    window.location.reload()
  })
}
const removeNode=(rowInfo)=> {
        
  let {node, treeIndex, path} = rowInfo;
 
 
    
  const remove_data = removeNodeAtPath({
      treeData: tree,
      path: path,   // You can use path from here
      getNodeKey: ({node: TreeNode, treeIndex: number}) => {
          // console.log(number);
          return number;
      },
      ignoreCollapsed: false,
   })
   setTreeData(remove_data)
   console.log(remove_data)
   postMenuTree({
    remove_data:remove_data,
    rowid:rowInfo.node.titles
  },'HeaderMenuTree' ,'Delete').then(result=>{
    console.log(result)
  })
  }
    return (
        <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Header Navigation | {url.title}</title>
          </MetaTags>
          <Container fluid>
          <Breadcrumbs
            title={"Menu Management"}
            breadcrumbItem={"Header Navigation"}
            bredcumlink={"menu-management"}
          />
      
        <Card>
          <CardBody>
            {
              isLoading ?
             <UiLoading />
               : 
               tree.length !== 0 ?
               <div style={{ height:parseInt(tree.length) *400 }}>
                <Row>
                  <Col md={10}></Col>
                  <Col md={2}><div className="mb-3">
                    <Button
                      type="submit"
                      color="success"
                     onClick={()=>saveTreeData()}
                    >
                     Save
                    </Button>
                    </div>
                    </Col>
                </Row>
                <SortableTree
              treeData={tree}
              onChange={treeData => treeDataValue(treeData)}
              isVirtualized={false}
              generateNodeProps={(rowInfo ,index) => ({
                buttons: [
                  <i
                  key={index}
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                  style={{ cursor: "pointer" ,color: 'red'}}
                  onClick={(event) => removeNode(rowInfo)}
              />
                          
                        
                          
                       ],
                    })}
            />
            </div>
            :''
            }
        
          </CardBody>
        </Card>
       
     
      </Container>
      </div>
      </React.Fragment>
    );
  
}

export default HeaderTree;