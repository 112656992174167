import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardBody
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { url } from "routes"
import GalleryCardElementwithEvent from "UI/GalleryCardElementWithEvent"
import ReactTable from "UI/Table"
import { getPollParticipate,getPollParticipateStatus,postPollParticipate} from "data/pollparticipate"
import InputFormElement from "UI/InputFormElement"
import { getMiscellaneous } from "data/systemadmin/miscellaneous"
import { isEmpty } from "data/middlefunctions"
import UiLoading from "UI/loading"

const PollParticipate = () => {
  const [allPageLoading,setAllPageLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [indexValue, setIndexValue] = useState('')
  const [addButton,setAddButton]=useState(false)
  const [operationsTable, setOperationTable] = useState('Card')
  const [singleItem, setSingleItem] = useState({pagedescription:'',status:true})
  const [status, setStatus] = useState('All')
  const [title, setTitle] = useState('')
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const [pageDescription,setPageDescription] = useState([{name:'goapla'}])
  const [inputType, setInputType] = useState([
    { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
    { title: 'uploadImages', name: 'uploadImages', type: 'hidden', value: 'dynamicpage', disabled: '' },
    { title: 'pagelink', name: 'pagelink', type: 'hidden', value: 'pagelink', disabled: '' },
    { title: 'productdescription', name: 'productdescription', type: 'hidden', value: 'productdescription', disabled: '' },
      {title:`Name`,name:'name',type:'text',value:'',disabled:true},    
    { title: 'Description', name: 'description', type: 'hidden', value: '',uploadImages:'dynamicpage',multiple:true, disabled: '', required: true, gride: 12 },
   ])
  const [sortTreeStatus,setSortTreeStatus] = useState(false)
  const [cardElement, setCardElement] = useState([])

  useEffect(async () => {
    const cardElementStatus = [...cardElement]
    await getMiscellaneous('Poll Category','All').then(async result=>{
      const d=[]
      if(result.success){
        for(let i=0; i < result.data.length;i++){
          await getPollParticipateStatus(result.data[i]._id,'All').then(results=>{
            d.push({
              _id: result.data[i]._id,
              name: result.data[i].name,
              title: result.data[i].name,
              total:results.total,
              active:results.active,
              inactive:results.inactive,
              getStatusData: getPollParticipateStatus,
              getData: getPollParticipate,
              getPostData:postPollParticipate,
              statusList:'All',
              HeaderColumns: [
                {
                  dataField: "_id",
                  text: "SL. No",
                  sort: true,
                  // eslint-disable-next-line react/display-name
                  formatter: (cell, prod, index) => index + 1,
                },
                {
                  dataField: "name",
                  text: "Poll Name",
                  sort: true,
        
                },
                {
                  dataField: "name",
                  text: "Option Count",
                  sort: true,
                },
                {
                  dataField: "name",
                  text: "Total Participant",
                  sort: true,
                },
                {
                  dataField: "name",
                  text: "Vote Count",
                  sort: true,
                },
                
               
               
               
               
              ],
      
              isLoading:false,
              addButton: false,
            })
          })
          
        }
        setCardElement(d)
        setAllPageLoading(false)
      }
    })
    console.log(cardElementStatus)
    
    setIsLoading(false)
  
  }, [])
  const changeTable = async (e, type, index,prod) => {
    console.log('h')
    e.preventDefault()
   
    console.log(index)
    setIndexValue(index)
    const cardElementList = [...cardElement]
    const inputLists =[...inputType]
    setAddButton(cardElementList[index].addButton)
    setTitle(cardElementList[index].title)
    setSortTreeStatus(cardElementList[index].title)
    setIsLoading(true)
  if (type == 'Table' || type == true || type == false) {
    setColumns(cardElementList[index].HeaderColumns)
      setOperationTable('Table')
    await cardElement[index].getData(cardElementList[index]._id,type == 'Table'? "All" :type).then(result => {
        console.log(result.description)
        if (result.success) {
          setPageDescription(result.description)
          setData(result.data)
          setIsLoading(false)
        } else {
          setData([])
          setIsLoading(false)
        }
      })
     
    
    }else if (type == 'Add') {
      
      for(let i=0;i<inputLists.length;i++){
        if (inputLists[i].type === 'multiple' || inputLists[i].type === 'select') {
          inputLists[i].value=[]
          await inputLists[i].outputData(inputLists[i].table,inputLists[i].status).then(results => {
              for (let k = 0; k < results.data.length; k++) {
             
                      let data = {
                          label: results.data[k][Object.keys(results.data[k]).filter(q => q === inputLists[i].outputDropDown)],
                          value: results.data[k]._id,
                      }
                      inputLists[i].value.push(data)
                    }
              })
                singleItem.pagedescription=prod.name
              singleItem.pagedescriptionid =prod._id
              console.log(singleItem)
              setSingleItem({...singleItem})
              
              setInputType([...inputLists])
              setOperationTable('Add')
      
              setIsLoading(false)
            }else{
              singleItem.pagedescription=prod.name
              singleItem.pagedescriptionid =prod._id
               console.log(singleItem)
              setSingleItem({...singleItem})
              
              setInputType([...inputLists])
              setOperationTable('Add')
       
              setIsLoading(false)
            }
        }
        
    }else{
    
      setInputType([...inputLists])
      if(type == 'Edit' || type == 'View' || type == 'Deleted'){
        if(cardElement[index].name === 'Different Page Relation'){
          setSortTreeStatus(true)
        }
        await cardElement[index].getData(cardElement[index].name,prod._id).then(async result=>{
         
          for(let i=0;i<inputLists.length;i++){
        
            if (inputLists[i].type === 'multiple' || inputLists[i].type === 'select') {
              inputLists[i].value=[]
              await inputLists[i].outputData(inputLists[i].table,inputLists[i].status).then(results => {
                  for (let k = 0; k < results.data.length; k++) {
                 
                          let data = {
                              label: results.data[k][Object.keys(results.data[k]).filter(q => q === inputLists[i].outputDropDown)],
                              value: results.data[k]._id,
                          }
                          inputLists[i].value.push(data)
                        }
                  })
                  setSingleItem(result.data)
                  setInputType([...inputLists])
                  setOperationTable(type)
                  setIsLoading(false)
                }else{
                  setSingleItem(results.data)
                  setInputType([...inputLists])
                  setOperationTable(type)
                  setIsLoading(false)
                }
            }
        
   
        })
      }

    }
  }
  const addDescription=async (prodid,id,pro,prod)=>{
    console.log(prod)
    const allInputList = [...inputType]
     await getpageStructurDescription(prodid,id).then(results=>{
      if(Object.keys(results.data).length == 0){
        if(prod.descriptiontype === 'file'){
          allInputList[5].label='Upload Image'
          allInputList[5].name='image'
          allInputList[5].type=prod.descriptiontype;
          allInputList[5].value=[]
        }else if(prod.descriptiontype === 'Add More'){
          allInputList[5].name=prod.descriptiontype
          allInputList[5].type=prod.descriptiontype;
          
        }else if(prod.descriptiontype === 'videourl'){
          allInputList[5].name='video'
          allInputList[5].type='text';
          
        }else if(prod.descriptiontype === 'refurl'){
          allInputList[5].name='refurl'
          allInputList[5].type='text';
          
        }else if(prod.descriptiontype === 'quotation'){
          allInputList[5].name='quotation'
          allInputList[5].type='textarea';
          
        }else{
          allInputList[5].name=prod.descriptiontype
          allInputList[5].type=prod.descriptiontype;
        }
       setInputType([...allInputList]) 
       setSingleItem({name:pro.name,productdescription:id,pagelink:prodid})
       setOperationTable('Add') 
      }else{
        if(prod.descriptiontype === 'file'){
          allInputList[5].label='Upload Image'
          allInputList[5].name='image'
          allInputList[5].type=prod.descriptiontype;
          allInputList[5].value=[]
        }else if(prod.descriptiontype === 'Add More'){
          allInputList[5].name=prod.descriptiontype
          allInputList[5].type=prod.descriptiontype;
          
        }else if(prod.descriptiontype === 'videourl'){
          allInputList[5].name='video'
          allInputList[5].type='text';
          
        }else if(prod.descriptiontype === 'refurl'){
          allInputList[5].name='refurl'
          allInputList[5].type='text';
          
        }else if(prod.descriptiontype === 'quotation'){
          allInputList[5].name='quotation'
          allInputList[5].type='textarea';
          
        }else{
          allInputList[5].name=prod.descriptiontype
          allInputList[5].type=prod.descriptiontype;
        }
        setInputType([...allInputList]) 
        setSingleItem({name:pro.name,...results.data})
        setOperationTable('Edit') 
      }
     console.log(results)
    })
   }

  const postData = (e, values) => {
    const cardElementList = [...cardElement]
    if (values[Object.keys(values).filter(k => k == 'status')] === 'false') {
      values[Object.keys(values).filter(k => k == 'status')] = false
    }
    if (operationsTable == 'Add') {
      delete values['_id'];
    }
    setIsLoading(true)
    cardElementList[indexValue].getPostData(isEmpty(values),cardElementList[indexValue].name, operationsTable).then(async result => {
      console.log(cardElementList[indexValue])
      if (result.success) {
        setOperationTable('Table')
        await cardElement[index].getData(cardElementList[index]._id, "All").then(results => {
            console.log(result.description)
            if (results.success) {
             
              setData(results.data)
              setIsLoading(false)
            } else {
              setData([])
              setIsLoading(false)
            }
          })
         
      } else {
        alert('Hello')
      }
    })
  }
console.log(pageDescription)
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Poll Participate | {url.title}</title>
        </MetaTags>
        <Container fluid>
          {
            operationsTable == 'Card' ?
              <Breadcrumbs
                title={"Citizen Dashboard"}
                breadcrumbItem={"Poll Participate"}
                bredcumlink={"customer-dashboard"}
              />
              : <Breadcrumbs
                title={'Poll Participate'}
                breadcrumbItem={title}
                bredcumlink={'poll-participate'}
              />
          }

          <Row>
            {
              allPageLoading?
              <UiLoading cardElementOption={!allPageLoading} />:
              operationsTable == 'Card' ?
                cardElement.map((d, index) => (
                  <Col sm={4} md={3} key={index}> 
                
                        <GalleryCardElementwithEvent
                          data={d}
                          changeTable={(e, type) => changeTable(e, type, index,d)}
                          addButton={d.addButton}
                          isLoading={isLoading}
                        />
                  </Col>
                ))
                :
                operationsTable == 'Table' ?
                 
                     <ReactTable
                          title={title}
                          datas={data}
                          changeTable={(e, type) => changeTable(e, type, indexValue)}
                          columns={columns}
                          addButton={addButton}
                          
                        />
                    

                  
                  :
              
                         
                          <InputFormElement
                            operationsTable={operationsTable}
                            singleItem={singleItem}

                            bredcumlink={'InformationPage'}
                            viewIcon={false}
                            filetype='single'

                            title={title}
                            changeTable={(e, type) => changeTable(e, type, indexValue)}
                            inputType={inputType}
                            postData={postData}
                            isLoading={isLoading}
                          />
                      
               
            }

          </Row>
        </Container>
      </div>
    </React.Fragment >
  )
}



export default PollParticipate
