import React from 'react';
import Dashboards from 'UI/Dashboards';

const Dashboard = props => {
  return(
    <Dashboards/>
  )
}

// Dashboard.propTypes = {
//   t: PropTypes.any,
//   chartsData: PropTypes.any,
//   onGetChartsData: PropTypes.func,
// }

export default Dashboard;
