import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardBody
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

import { url } from "routes"
import { Link } from "react-router-dom"

import GalleryCardElementwithEvent from "UI/GalleryCardElementWithEvent"
import ReactTable from "UI/Table"


import {getBannerStatus, getBanner, postBanner } from "data/cms/banner"
import InputFormElement from "UI/InputFormElement"

import UiLoading from "UI/loading"

const Banner = () => {
  const [indexValue, setIndexValue] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [operationsTable, setOperationTable] = useState('')
  const [singleItem, setSingleItem] = useState({ status: true })
  const [status, setStatus] = useState('All')
  const [title, setTitle] = useState('')
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const [inputType, setInputType] = useState([])
  const [cardElement, setCardElement] = useState([
    {
      name: 'All Page List',
      title: 'Dynamic Pages',
       total:0,
      active:0,
      inactive:0,
      statusList:'All',
      getStatusData: getBannerStatus,
      getData: getBanner,
      getPostData:postBanner,
    
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Page Name",
          sort: true,

        },
        
        
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 0,prod)}
                  />
                </Link>
               
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 0,prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
       
       
      ],
      inputType: [
          { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
          { title: 'uploadtype', name: 'uploadtype', type: 'hidden', value: 'allpage', disabled: '' },
          { title: 'Page Name', name: 'name', type: 'text', value: '',disabled:true },
          { title: 'Banner Image', name: 'bannerimage', type: 'file', value: [],label:'Banner Image',uploadtype:'allpage', disabled: '', required: true, },
         
      ],
      isLoading: true,
      addButton:false,
  },
    {
      name: 'All Dynamic Page',
      title: `Dynamic Sub Page's`,
       total:0,
      active:0,
      inactive:0,
      statusList:'All',
      getStatusData: getBannerStatus,
      getData: getBanner,
      getPostData:postBanner,
    
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "name",
          text: "Page Name",
          sort: true,

        },
        
        
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 1,prod)}
                  />
                </Link>
              
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 1,prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
       
       
      ],
      inputType: [
          { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
          { title: 'uploadtype', name: 'uploadtype', type: 'hidden', value: 'alldynamicpage', disabled: '' },
          { title: 'Page Name', name: 'name', type: 'text', value: '', disabled:true },
          { title: 'Banner Image', name: 'bannerimage', type: 'file', value: [],label:'Banner Image',uploadtype:'alldynamicpage', disabled: '', required: true, },
      ],
      isLoading: true,
      addButton:false,
  },
  {
    name: 'Blog',
    title: 'Blogs',
     total:0,
    active:0,
    inactive:0,
    statusList:'All',
    getStatusData: getBannerStatus,
    getData: getBanner,
    getPostData:postBanner,
  
    HeaderColumns: [
      {
        dataField: "_id",
        text: "SL. No",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cell, prod, index) => index + 1,
      },
      {
        dataField: "name",
        text: "Blog Name",
        sort: true,

      },
      
      
      {
        dataField: "",
        text: "Status",
        sort: true,
        formatter: (cell, prod, index) =>
          prod.status == true ? <p>ON</p> : <p>OFF</p>,
      },
      {
        dataField: "action",
        isDummyField: true,
        text: "Action",
        // eslint-disable-next-line react/display-name
        formatter: (cell, prod, index) => (
          <>
            <div className="d-flex gap-3">
              <Link to="#" className="text-success">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={e => changeTable(e, "Edit", 2,prod)}
                />
              </Link>
     
              <Link to="#" className="text-danger">
                <i
                  className="mdi mdi-eye font-size-18"
                  id="deletetooltip"
                  onClick={e => changeTable(e, "View", 2,prod)}
                />
              </Link>
            </div>
          </>
        ),
      },
     
     
    ],
    inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'uploadtype', name: 'uploadtype', type: 'hidden', value: 'blog', disabled: '' },
        { title: 'Blog Name', name: 'name', type: 'text', value: '',disabled:true },
        { title: 'Banner Image', name: 'bannerimage', type: 'file', value: [],label:'Banner Image',uploadtype:'blog', disabled: '', required: true, },
    ],
    isLoading: true,
    addButton:false,
},
{
  name: 'Poll',
  title: 'Poll',
   total:0,
  active:0,
  inactive:0,
  statusList:'All',
  getStatusData: getBannerStatus,
  getData: getBanner,
  getPostData:postBanner,
  HeaderColumns: [
    {
      dataField: "_id",
      text: "SL. No",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell, prod, index) => index + 1,
    },
    {
      dataField: "name",
      text: "Page Name",
      sort: true,

    },
    
    
    {
      dataField: "",
      text: "Status",
      sort: true,
      formatter: (cell, prod, index) =>
        prod.status == true ? <p>ON</p> : <p>OFF</p>,
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cell, prod, index) => (
        <>
          <div className="d-flex gap-3">
            <Link to="#" className="text-success">
              <i
                className="mdi mdi-pencil font-size-18"
                id="edittooltip"
                onClick={e => changeTable(e, "Edit", 3,prod)}
              />
            </Link>
           
            <Link to="#" className="text-danger">
              <i
                className="mdi mdi-eye font-size-18"
                id="deletetooltip"
                onClick={e => changeTable(e, "View", 3,prod)}
              />
            </Link>
          </div>
        </>
      ),
    },
   
   
  ],
  inputType: [
      { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
      { title: 'uploadtype', name: 'uploadtype', type: 'hidden', value: 'poll', disabled: '' },
      { title: 'Poll Name', name: 'name', type: 'text', value: '', disabled:true },
      { title: 'Banner Image', name: 'bannerimage', type: 'file', value: [],label:'Banner Image',uploadtype:'poll', disabled: '', required: true, },
  ],
  isLoading: true,
  addButton:false,
}, 
{
name: 'Carreer',
title: 'Job',
 total:0,
active:0,
inactive:0,
statusList:'All',
getStatusData: getBannerStatus,
getData: getBanner,
getPostData:postBanner,
HeaderColumns: [
  {
    dataField: "_id",
    text: "SL. No",
    sort: true,
    // eslint-disable-next-line react/display-name
    formatter: (cell, prod, index) => index + 1,
  },
  {
    dataField: "name",
    text: "Job Name",
    sort: true,

  },
  
  
  {
    dataField: "",
    text: "Status",
    sort: true,
    formatter: (cell, prod, index) =>
      prod.status == true ? <p>ON</p> : <p>OFF</p>,
  },
  {
    dataField: "action",
    isDummyField: true,
    text: "Action",
    // eslint-disable-next-line react/display-name
    formatter: (cell, prod, index) => (
      <>
        <div className="d-flex gap-3">
          <Link to="#" className="text-success">
            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
              onClick={e => changeTable(e, "Edit", 4,prod)}
            />
          </Link>
         
          <Link to="#" className="text-danger">
            <i
              className="mdi mdi-eye font-size-18"
              id="deletetooltip"
              onClick={e => changeTable(e, "View", 4,prod)}
            />
          </Link>
        </div>
      </>
    ),
  },
 
 
],
inputType: [
    { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
    { title: 'uploadtype', name: 'uploadtype', type: 'hidden', value: 'career', disabled: '' },
    { title: 'Job Name', name: 'name', type: 'text', value: '', disabled: '',disabled:true },
    { title: 'Banner Image', name: 'bannerimage', type: 'file', value: [],label:'Banner Image',uploadtype:'career', disabled: '', required: true, },
],
isLoading: true,
addButton:false,
},
{
  name: 'Information Page',
  title: 'Information Page',
  total:0,
  active:0,
  inactive:0,
  statusList:'All',
  getStatusData: getBannerStatus,
  getData: getBanner,
  getPostData:postBanner,

  HeaderColumns: [
    {
      dataField: "_id",
      text: "SL. No",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cell, prod, index) => index + 1,
    },
    {
      dataField: "name",
      text: "Page Name",
      sort: true,

    },
    
    
    {
      dataField: "",
      text: "Status",
      sort: true,
      formatter: (cell, prod, index) =>
        prod.status == true ? <p>ON</p> : <p>OFF</p>,
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cell, prod, index) => (
        <>
          <div className="d-flex gap-3">
            <Link to="#" className="text-success">
              <i
                className="mdi mdi-pencil font-size-18"
                id="edittooltip"
                onClick={e => changeTable(e, "Edit", 5,prod)}
              />
            </Link>
           
            <Link to="#" className="text-danger">
              <i
                className="mdi mdi-eye font-size-18"
                id="deletetooltip"
                onClick={e => changeTable(e, "View", 5,prod)}
              />
            </Link>
          </div>
        </>
      ),
    },
   
   
  ],
  inputType: [
      { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
      { title: 'uploadtype', name: 'uploadtype', type: 'hidden', value: 'information', disabled: '' },
      { title: 'Page Name', name: 'name', type: 'text', value: '', disabled: '',disabled:true },
      { title: 'Banner Image', name: 'bannerimage', type: 'file', value: [],label:'Banner Image',uploadtype:'information', disabled: '', required: true, },
  ],
  isLoading: true,
  addButton:false,
},  
{
name: 'Constant Page',
title: 'Fix Pages',
 total:0,
active:0,
inactive:0,
statusList:'All',
getStatusData: getBannerStatus,
getData: getBanner,
getPostData:postBanner,

HeaderColumns: [
  {
    dataField: "_id",
    text: "SL. No",
    sort: true,
    // eslint-disable-next-line react/display-name
    formatter: (cell, prod, index) => index + 1,
  },
  {
    dataField: "typeofpage",
    text: "Page Name",
    sort: true,

  },
  
  
  {
    dataField: "",
    text: "Status",
    sort: true,
    formatter: (cell, prod, index) =>
      prod.status == true ? <p>ON</p> : <p>OFF</p>,
  },
  {
    dataField: "action",
    isDummyField: true,
    text: "Action",
    // eslint-disable-next-line react/display-name
    formatter: (cell, prod, index) => (
      <>
        <div className="d-flex gap-3">
          <Link to="#" className="text-success">
            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
              onClick={e => changeTable(e, "Edit", 6,prod)}
            />
          </Link>
        
          <Link to="#" className="text-danger">
            <i
              className="mdi mdi-eye font-size-18"
              id="deletetooltip"
              onClick={e => changeTable(e, "View", 6,prod)}
            />
          </Link>
        </div>
      </>
    ),
  },
 
 
],
inputType: [
    { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
    { title: 'uploadtype', name: 'uploadtype', type: 'hidden', value: 'career', disabled: '' },
    { title: 'Page Name', name: 'typeofpage', type: 'text', value: '', disabled: '',disabled:true },
    { title: 'Banner Image', name: 'bannerimage', type: 'file', value: [],label:'Banner Image',uploadtype:'career', disabled: '', required: true, },
],
isLoading: true,
addButton:false
},
  ])

  useEffect(async () => {
    const cardElementStatus = [...cardElement]
    for(let i=0;i<cardElementStatus.length;i++){
        cardElementStatus[i].getStatusData(cardElementStatus[i].name,cardElementStatus[i].statusList).then((res) => {
        cardElementStatus[i].active = res.active
        cardElementStatus[i].inactive = res.inactive
        cardElementStatus[i].total = res.total
        cardElementStatus[i].isLoading = false
        setCardElement([...cardElementStatus])
      })
    }
  setIsLoading(false)
  }, [])
  const changeTable = async (e, type, index, prod) => {
    const cardElementList = [...cardElement]
    if (type == true || type == false) {
      setSingleItem({ status: true })
      setOperationTable('Table')
    } else {
      setOperationTable(type)
    }
    setIndexValue(index)

    setTitle(cardElementList[index].title)
    setColumns([...cardElementList[index].HeaderColumns])
    setInputType([...cardElementList[index].inputType])
    if (type == true || type == false) {
      setIsLoading(true)

      await getBanner(cardElementList[index].name, type).then(result => {
        if (result.success) {
          setData(result.data)
          setIsLoading(false)
        } else {
          setData([])
          setIsLoading(false)
        }

      })

    } else if (type == 'Table') {
      setIsLoading(true)
      await getBanner(cardElementList[index].name, "All").then(result => {

        if (result.success) {
          setData(result.data)
          setIsLoading(false)
        } else {
          setData([])
          setIsLoading(false)
        }
      })

    } else if (type == 'View' || type == 'Deleted' || type == 'Edit') {
      setIsLoading(true)

      setOperationTable(type)


      await getBanner(cardElement[index].name, prod._id).then(result => {

        if (result.success) {
          setSingleItem({ ...result.data })
          setIsLoading(false)
        } else {
          setSingleItem({})
          setIsLoading(false)
        }

      })


    }
  }


  const postData = (e, values) => {

    const cardElementList = [...cardElement]
    console.log(cardElementList[indexValue].name)
    if (values[Object.keys(values).filter(k => k == 'status')] === 'false') {
      values[Object.keys(values).filter(k => k == 'status')] = false
    }
    if (operationsTable == 'Add') {
      delete values['_id'];
    }
    console.log(values)
    postBanner(values, cardElementList[indexValue].name, operationsTable).then(result => {
      if (result.success) {
        setIsLoading(true)
        setColumns([...cardElementList[indexValue].HeaderColumns])
        setOperationTable('Table')
        getBanner(cardElementList[indexValue].name, status).then(result => {
          if (result.success) {
            setData(result.data)
            setIsLoading(false)
          } else {
            setData([])
            setIsLoading(false)
          }

        })
      } else {
        alert('Hello')
      }
    })
  }
  console.log(cardElement)
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Banner | {url.title}</title>
        </MetaTags>
        <Container fluid>
          {
            operationsTable == '' ?
              <Breadcrumbs
                title={"CMS"}
                breadcrumbItem={"Banner"}
                bredcumlink={"cms"}
              />
              : <Breadcrumbs
                title={'Banner'}
                breadcrumbItem={title}
                bredcumlink={'banner'}
              />
          }

          <Row>
            {

              operationsTable == '' ?
                cardElement.map((d, index) => (
                  <Col sm={4} md={3} key={index}>
                    {
                      isLoading ?

                        <UiLoading />

                        :
                        <GalleryCardElementwithEvent
                          data={d}
                          changeTable={(e, type) => changeTable(e, type, index)}
                          addButton={d.addButton}
                        />
                    }

                  </Col>
                ))
                :
                operationsTable == 'Table' ?
                  <Card>
                    {
                      isLoading ?
                        <UiLoading />
                        : <ReactTable
                          title={title}
                          datas={data}
                          changeTable={(e, type) => changeTable(e, type, indexValue)}
                          columns={columns}
                          addButton={false}
                        />
                    }

                  </Card>
                  :
                  operationsTable == 'Add' || operationsTable == 'Edit' || operationsTable == 'Deleted' || operationsTable == 'View' ?
                    <>
                      {
                        isLoading ?
                          <UiLoading />
                          :
                          <InputFormElement
                            operationsTable={operationsTable}
                            singleItem={singleItem}

                            bredcumlink={'banner'}
                            viewIcon={false}
                            filetype='single'
                            addButton={false}
                            title={title}
                            changeTable={(e, type,) => changeTable(e, type, indexValue)}
                            inputType={inputType}
                            postData={postData}
                          />
                      }
                    </>
                    : ''
            }

          </Row>
        </Container>
      </div>
    </React.Fragment >
  )
}



export default withTranslation()(Banner)
