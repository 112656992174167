import React from 'react';
import Select from "react-select"
import PropTypes from 'prop-types'
export const SingleDropDown=({title,dropDownList,selectDrop,selctdropDownList,disabled})=>{
  console.log(selectDrop)
    return(
        <div className="mb-3 templating-select select2-container">
        <label className="control-label">{title}</label>
        {
        disabled?
        selectDrop !== undefined && selectDrop !== null? 
        <div className="form-group"><input type='text'name={`${title}`} disabled={true} value={dropDownList.find(o => o.value === selectDrop).label} className="form-control is-untouched is-pristine av-invalid form-control"/></div>
        :''
        :
        <Select
        value={selectDrop !== undefined ?dropDownList.find(o => o.value === selectDrop)  : {}}
        onChange={(e) => selctdropDownList(e)}
        options={dropDownList !== undefined ? [{value:'',label:'Default'}, ...dropDownList]:[]}
        classNamePrefix="select2-selection"
      />
        }
       
      </div>
    )
}
SingleDropDown.propTypes = {
    title:PropTypes.string,
    dropDownList:PropTypes.array,
    selectDrop:PropTypes.array,
    selctdropDownList:PropTypes.func,
    disabled:PropTypes.bool
  }


export default SingleDropDown;