import React from "react";
import PropTypes from 'prop-types';

import BootstrapTable from "react-bootstrap-table-next"
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,

} from "reactstrap"
import UiLoading from "UI/loading";
const ReactTable = ({isLoading, title, datas, columns, changeTable, addButton }) => {
  return (
    <Card>
    <CardBody>
    
        <React.Fragment>
        <Row className="mb-2">
          <Col sm="4">

          </Col>
          <Col sm="8">
            <div className="text-sm-end">
              {
                addButton !== undefined ?
                  addButton ?
                    <Button
                      type="button"
                      color="success"
                      className="btn-rounded  mb-2 me-2"
                      onClick={(e)=>changeTable(e,'Add',title)}
                    >
                      <i className="mdi mdi-plus me-1" />
                      {`Add ${title}`}
                    </Button>
                    : ''

                  : <Button
                    type="button"
                    color="success"
                    className="btn-rounded  mb-2 me-2"
                    onClick={(e)=>changeTable(e,'Add',title)}
                  >
                    <i className="mdi mdi-plus me-1" />
                    {`Add ${title}`}
                  </Button>
              }



            </div>
          </Col>
        </Row>
        <Row>
          <Col xl="12">
            <div className="table-responsive">
              <BootstrapTable
                keyField="id"
                responsive
                bordered={true}
                striped={false}

                data={datas}
                columns={columns}
                classes={
                  "table align-middle table-nowrap table-check"
                }
                headerWrapperClasses={"table-light"}

              />
            </div>

          </Col>
        </Row>

      </React.Fragment>
          



    </CardBody>
    </Card>
  )
}
ReactTable.propTypes = {
  isLoading: PropTypes.bool,
  datas: PropTypes.array,
  columns: PropTypes.array,
  tablelink: PropTypes.string,
  title: PropTypes.string,
  changeTable: PropTypes.func,
  addButton: PropTypes.bool
};
export default ReactTable