import React from 'react'
import PropTypes from 'prop-types'
import { Table, Col, Row, Button, CardTitle } from 'reactstrap'
import { AvForm, AvInput } from 'availity-reactstrap-validation'
import SingleDropDown from 'UI/SingleDropDown'
import { service } from 'constants/layout'

export const MultiRow = ({ headerTitle,type, tableTitle, rowTitle, addDataTable, addRow, deletedDataTable, changeTableRow, opration,handleValidSubmit,country,citys }) => {
 console.log(addDataTable)
  return (
    <>
      {/* <CardTitle className="mb-4">
        {tableTitle}
      </CardTitle> */}
     
      <div >
        <AvForm onValidSubmit={(e,v)=>handleValidSubmit(e,v)}>
          <AvInput
            name={type}
            type='hidden'
            value={addDataTable}
          />
          <Table className="table table-bordered mb-0">
            <thead>
              <tr>
                {
                  headerTitle.map((d, i) => (
                    <th key={i}>{d.title}</th>
                  ))
                }
              </tr>
            </thead>
            <tbody>
              {
                addDataTable.map((d, i) => (
                  <tr key={i}>
                    <td>
                      {i + 1}
                    </td>
                    
                      {
                        Object.keys(d).map((o,k)=>(
                          o !== '_id' && o !== 'id' && o !== 'deleted' && o !== 'servicetype' && o !== 'country' && o !== 'city' ?
                          <td key={k}>
                              {
                                o == 'file'?
                                 d[o] !== ''?
                                  <img />
                                 :<input
                                 name={`${o}`}
                                className="form-control"
                                type='file'
                               id="example-date-input"
                                value={d[o]}
                               onChange={(e) => changeTableRow(e, i)}
                        />

                                : <input
                                name={`${o}`}
                               className="form-control"
                               type= {`${o == 'file' ? 'file' : o== 'fromdate' || o == 'todate' ? 'date' : "text"}`}
                              id="example-date-input"
                               value={d[o]}
                              onChange={(e) => changeTableRow(e, i)}
                       />
                              }
                               
                        </td>
                        :o === 'servicetype'?
                          <td>
                          <SingleDropDown 
                          selectDrop={d[o]}
                          dropDownList={service}
                          selctdropDownList={(e)=>changeTableRow(e,i,'servicetype')}
                          />
                          </td>
                        :o === 'country'?
                          <td>
                          <SingleDropDown 
                          selectDrop={d[o]}
                          dropDownList={country}
                          selctdropDownList={(e)=>changeTableRow(e,i,'country')}
                          />
                          </td>
                        :o === 'city'?
                        <td>
                        <SingleDropDown 
                        selectDrop={d[o]}
                        dropDownList={citys}
                        selctdropDownList={(e)=>changeTableRow(e,i,'city')}
                        />
                        </td>
                      :''
                        
                        ))
                      }
                 <td>
                        <i className="mdi mdi-delete font-size-18" id="deletetooltip" onClick={(e) => deletedDataTable(e, i)} />
                      </td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
          <Row>
            <Col lg={2}></Col>
            <Col lg={10}>
              <Row>
                <Col lg={11}></Col>
                <Col lg={1}>
                  <div className="mb-3">
                    <Button type="submit" color="success">
                      {opration}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </AvForm>
      </div>
    </>
  )
}
MultiRow.propTypes = {
  headerTitle: PropTypes.array,
  tableTitle: PropTypes.string,
  rowTitle: PropTypes.string,
  addRow: PropTypes.func,
  addDataTable: PropTypes.array,
  deletedDataTable: PropTypes.func,
  changeTableRow: PropTypes.func,
  handleValidSubmit: PropTypes.func,
  opration: PropTypes.string,
  type:PropTypes.string,
  country:PropTypes.array,
  citys:PropTypes.array,
}

export default MultiRow