import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

import { url } from "routes"

const PromoDashBoard = props => {
  const homebanner = ['Banner 1', 'Banner 2', 'Banner 3', 'Banner 4', 'Pop Up Banner']
  const pagebanner = ['Category Page', 'Brand Page', 'Product Types', 'Information Page', 'Different Page']
  const information = ['All', 'B2B', 'B2C']
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Adv Dashboard | {url.title}</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title="Dashboard"
            breadcrumbItem="Adv Dashboard"
            bredcumlink="/dashboard"
          />
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Adv Dashboard</CardTitle>
              <Row>
                {homebanner.map((val, ind) =>
                  <Col lg={4} key={ind}>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">{val}</CardTitle>
                        <Row>
                          <Col lg={6}>
                            <Card color="success">
                              <CardBody className="text-center">
                                <h1 className="text-white">6</h1>
                                <h6 className="text-white">Active</h6>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col lg={6}>
                            <Card color="danger">
                              <CardBody className="text-center">
                                <h1 className="text-white">0</h1>
                                <h6 className="text-white">In-Active</h6>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                )}
              </Row>
            </CardBody>
          </Card>
          <Row>
            {pagebanner.map((val, ind) =>
              <Col lg={4} key={ind}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">{val}</CardTitle>
                    <Row>
                      <Col lg={6}>
                        <Card color="success">
                          <CardBody className="text-center">
                            <h1 className="text-white">6</h1>
                            <h6 className="text-white">Active</h6>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg={6}>
                        <Card color="danger">
                          <CardBody className="text-center">
                            <h1 className="text-white">0</h1>
                            <h6 className="text-white">In-Active</h6>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
          <Card>
            <CardBody>
              <CardTitle className="mb-4">Information Page</CardTitle>
              <Row>
                {information.map((val, ind) =>
                  <Col lg={4} key={ind}>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">{val}</CardTitle>
                        <Row>
                          <Col lg={6}>
                            <Card color="success">
                              <CardBody className="text-center">
                                <h1 className="text-white">6</h1>
                                <h6 className="text-white">Active</h6>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col lg={6}>
                            <Card color="danger">
                              <CardBody className="text-center">
                                <h1 className="text-white">0</h1>
                                <h6 className="text-white">In-Active</h6>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                )}
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}



export default PromoDashBoard
