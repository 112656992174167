import React from 'react';
import PropTypes from 'prop-types';
import { CKEditor } from 'ckeditor4-react'
import {


    Col,


} from "reactstrap"
const TextEditor = ({ title, description,  sm,onEditorChange }) => {
  
    return (
        <>

            <label htmlFor="taxtype-Input" className="col-sm-2 col-form-label">
                {title}
            </label>

            <Col sm={`${sm !== undefined ? sm : 12}`}>
                <CKEditor
                    id="formmessage"
                    className="form-control"
                    rows="3"
                    initData={description}
                    // name={`${name}`}
                    // data={'ram'}
                    // value={'ramo'}
                    onChange={(e)=>onEditorChange(e)}
                    config={{
                        extraPlugins: "justify,font,colorbutton",
                        toolbarGroups: [
                            { name: "document", groups: ["mode", "document", "doctools"] },
                            { name: "clipboard", groups: ["clipboard", "undo"] },
                            { name: "editing", groups: ["find", "selection", "spellchecker"] },
                            { name: "forms" },
                            "/",
                            { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
                            {
                                name: "paragraph",
                                groups: ["list", "indent", "blocks", "align", "bidi"] // 'align' -> 'justify' plugin
                            },
                            { name: "links" },
                            { name: "insert" },
                            "/",
                            { name: "styles" }, // 'font and fontsize' -> 'font' plugin
                            { name: "colors" }, // 'colors' -> 'colorbutton' plugin
                            { name: "tools" },
                            { name: "others" },
                            { name: "about" }
                        ]
                    }}
                />
            </Col>
        </>
    )
}

TextEditor.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    sm: PropTypes.number,
    onEditorChange:PropTypes.func
}

export default TextEditor;