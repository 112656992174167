import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
import { generate_random_number, generate_random_string } from "data/middlefunctions"


import { Link } from "react-router-dom"

// import images
import profileImg from "../../assets/images/profile-img.png"
import logoImg from "../../assets/images/images.png"
import { url } from "routes"
import { getCountRegister,postRegister } from "data/authentication"
import UiLoading from "UI/loading"

const Register = props => {
  const [error,setError] = useState('')
  const [titleData,setTitleData]= useState(localStorage.getItem('company')!== undefined && localStorage.getItem('company')!== null ?JSON.parse(localStorage.getItem('company')) :{})
  const [userData,setUserData]= useState(localStorage.getItem('user')!== undefined && localStorage.getItem('user')!== null ?JSON.parse(localStorage.getItem('user')) :{})
  const [isLoading,setIsLoading] = useState(true)
  const [randomnumber,setRandomNmber] = useState(generate_random_number(6) )
  const [message,setMessage] = useState('')
  const [userid,setUserId] = useState('')
 useEffect(()=>{
  getCountRegister().then(result=>{
    console.log(result)
    if(result.success){
      if(result.count === 0){
        setIsLoading(false)
      }else{
        window.location.href=`${process.env.PUBLIC_URL}/`
      }
    }else{
      window.location.href=`${process.env.PUBLIC_URL}/`
    }
  })
 },[])
 const handleValidSubmit=(e,v)=>{
  console.log(v)
  if(`${randomnumber}` == `${v.capthca}`){
  if(v.password === v.confpassword){
    var regix = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
   if(regix.test(v.confpassword)){
    postRegister(v).then(result=>{
      if(result.success){
        window.location.href =`${process.env.PUBLIC_URL}/`
      }else{

      }
    })
   }else{
    setError('Please type at least 1 Alphabet, 1 Number and 1 Special Character : The minimum character count should be 8')
   }
   
  }else{
    setError('Password Not Matching')
  }
}else{
  setError('Wrong Captcha')
}
 }
 const resetInput=()=>{
  setMessage('')
 }
 const userInput=(e)=>{
  const {name,value} = e.target;
  console.log('h')
  if(name === 'email'){
    if(value.split('@').length == 2){
      setUserId(value.split('@')[0])
    }else{
      setUserId(value)
    }
   
  }else{
    setUserId(value)
  }
 }
console.log(userData)
  return (
    <React.Fragment>
      <MetaTags>
        <title>Register | {titleData.name}</title>
        <link rel="manifest" href={`${titleData.favicon !== undefined && titleData.favicon !== null ?  Object.keys(titleData).length !== 0? `${url.commonUrls}/image/${titleData.imagetype}/${titleData.favicon}`:`${process.env.PUBLIC_URL}/favicon.ico`:`${process.env.PUBLIC_URL}/favicon.ico`}`}/>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back</h5>
                        <p> Register  {titleData.name}.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                             src={`${titleData.log !== undefined && titleData.log !== null ?`${url.commonUrls}/image/${userData.logo}/${titleData.logo}`:`${process.env.PUBLIC_URL}/favicon.png`}`}
                            alt=""
                            className="rounded-circle"
                            //height="34"
                            style={{height:'100%',width:'100%'}}
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  {
                    isLoading ? <UiLoading />
                    : <div className="p-2">
                       {
                        error!= ''?
                        <Alert color="danger" style={{ marginTop: "13px" }}>
                        {error}
                      </Alert>
                        
                        :''
                       }
                     
                   
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={handleValidSubmit}
                    >
                         <div className="mb-3">
                        <AvField
                          name="name"
                          label="Company Name"
                          type="text"
                          required
                         
                        />
                      </div>
                     

                      <div className="mb-3">
                        <AvField
                          id="email"
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Email"
                          type="email"
                          required
                         
                          onChange={userInput}
                        />
                      </div>


                      <div className="mb-3">
                        <AvField
                          name="mobile"
                          label="Mobile No."
                          type="text"
                          required
                          placeholder="Mobile No"
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="userid"
                          label="UserId"
                          type="text"
                          placeholder="User Id"
                          value={userid}
                          onChange={userInput}
                        />
                      </div>
                 
                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          type="password"
                          required
                          placeholder="Password"
                          onChange={resetInput}
                        />
                      </div>
                      <p style={{color:'red',fontSize:'12px'}}>*<span style={{color:'green'}}>Password Length 8</span>  : <b>(Alpha-Numeric And Special Character)</b> </p>
                      <div className="mb-3">
                        <AvField
                          name="confpassword"
                          label="Conform Password"
                          type="password"
                          required
                          placeholder="Conform Password"
                          onChange={resetInput}
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="capthcacode"
                          label="Capthca Code"
                          className="form-control"
                          placeholder="Enter Email Id / User Id"
                          type="text"
                          value={randomnumber}
                          disabled={true}
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                            name="capthca"
                            label="Type Capthca"
                            className="form-control"
                            placeholder="Type Capthca"
                            type="text"
                            value=''
                           
                        />
                      </div>
                      <div className="mt-4">
                        <button
                          className="btn btn-primary btn-block "
                          type="submit"
                        >
                          Register
                        </button>
                      </div>

                      {/* <div className="mt-4 text-center">
                        <p className="mb-0">
                          By registering you agree to the Skote{" "}
                          <Link to="#" className="text-primary">
                            Terms of Use
                          </Link>
                        </p>
                      </div> */}
                    </AvForm>
                  </div>
                  }
                 
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/" className="font-weight-medium text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by The BigChimp Company
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Register
