import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Container, Row, Col, Card, Button, CardBody } from "reactstrap"
import "react-sortable-tree/style.css"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { url } from "routes"
import { Link } from "react-router-dom"
import GalleryCardElementwithEvent from "UI/GalleryCardElementWithEvent"
import ReactTable from "UI/Table"
import InputFormElement from "UI/InputFormElement"
import UiLoading from "UI/loading"
// import { getCatalogList } from "data/catalog/cataloglist"
import { getFooterMenuManagment, getFooterMenuManagmentStatus,postFooterMenuManagment } from "data/menumangment/footermenu"
import { isEmpty } from "data/middlefunctions"
import { getpageStructure } from "data/pageStructure"
import { getpageStructurLink } from "data/pageStructureLink"
import { getMiscellaneous } from "data/systemadmin/miscellaneous"
import { getBlog } from "data/cms/blog"
const FooterNavigation = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [indexValue, setIndexValue] = useState(0)
  const [addButton, setAddButton] = useState(false)
  const [operationsTable, setOperationTable] = useState("")
  const [singleItem, setSingleItem] = useState({ status: true })
  const [status, setStatus] = useState("All")
  const [title, setTitle] = useState("Footer Navigation Menu")
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const [inputType, setInputType] = useState([])
  const [cardElement, setCardElement] = useState([
    {
      name: "Page",
      title: "Category Page",
      active: 0,
      inactive: 0,
      total: 0,
      getStatusData: getFooterMenuManagmentStatus,
      getData: getFooterMenuManagment,
      getPostData:postFooterMenuManagment,
      statusList:'All',
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "title",
          text: "Display Name",
          sort: true,
        },

        {
          dataField: "link",
          text: "Page Link",
          sort: true,
        },
        {
          dataField: "title",
          text: "Page Name",
          sort: true,
          formatter: (cell, prod, index) =>
          prod.dynamic !== undefined && prod.dynamic !== null ? prod.dynamic.name : <p>No Page Link</p>,
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 0, prod)}
                  />
                </Link>
           
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 0, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        {title: "_id",name: "_id",type: "hidden",value: "_id",disabled: "",},
        { title: "dynamic", name: 'link', type: 'hidden', value: "", disabled: '' },
        {title: "Display Name",name: "title", type: "text",value: "",disabled: "",required: "",gride: 6,},
        {title: "Dynamic Page",name: "dynamic",type: "select",value: [],outputDropDown: "name", outputData:getpageStructure,table: "Different Page Design",status: true,disabled: "",required: true,gride: 6,},
        {title: "Status",name: "status",type: "radio",value: "",disabled: "",required: "",statustype: [{ title: "Active", name: "status", value: true },{ title: "In Active", name: "status", value: false },],
        },
      ],
      isLoading: true,
      addButton: true,
    },
    {
      name: "Dynamic Page",
      title: "Sub Category Page",
      active: 0,
      inactive: 0,
      total: 0,
      getStatusData: getFooterMenuManagmentStatus,
      getData: getFooterMenuManagment,
      getPostData:postFooterMenuManagment,
      statusList:'All',
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "title",
          text: "Display Name",
          sort: true,
        },

        {
          dataField: "link",
          text: "Page Link",
          sort: true,
        },
        {
          dataField: "title",
          text: "Page Name",
          sort: true,
          formatter: (cell, prod, index) =>
          prod.dynamicpage !== undefined && prod.dynamicpage !== null ? prod.dynamicpage.name : <p>No Page Link</p>,
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 1, prod)}
                  />
                </Link>
           
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 1, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        {title: "_id",name: "_id",type: "hidden",value: "_id",disabled: "",},
        { title: "dynamic page", name: 'link', type: 'hidden', value: "", disabled: '' },
         {title: "Display Name",name: "title", type: "text",value: "",disabled: "",required: "",gride: 6,},
        {title: "All Dynamic Page",name: "dynamicpage",type: "select",value: [],outputDropDown: "name", outputData:getpageStructurLink,table: "Different Page Design",status: 'Drop Down',disabled: "",required: true,gride: 6,},
        {title: "Status",name: "status",type: "radio",value: "",disabled: "",required: "",statustype: [{ title: "Active", name: "status", value: true },{ title: "In Active", name: "status", value: false },],
        },
      ],
      isLoading: true,
      addButton: true,
    },
    {
      name: "Blog",
      title: "Blog List",
      active: 0,
      inactive: 0,
      total: 0,
      getStatusData: getFooterMenuManagmentStatus,
      getData: getFooterMenuManagment,
      getPostData:postFooterMenuManagment,
      statusList:'All',
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "title",
          text: "Display Name",
          sort: true,
        },

        {
          dataField: "link",
          text: "Page Link",
          sort: true,
        },
        {
          dataField: "title",
          text: "Page Name",
          sort: true,
          formatter: (cell, prod, index) =>
          prod.blogtype !== undefined && prod.blogtype !== null ? prod.blogtype.name : <p>No Page Link</p>,
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 2, prod)}
                  />
                </Link>
            
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 2, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        {title: "_id",name: "_id",type: "hidden",value: "_id",disabled: "",},
        { title: "blog", name: 'link', type: 'hidden', value: "", disabled: '' },
        {title: "Display Name",name: "title", type: "text",value: "",disabled: "",required: "",gride: 6,},
        {title: "Blog Type",name: "blogtype",type: "select",value: [],outputDropDown: "name", outputData:getBlog,table: "Blog",status: true,disabled: "",required: true,gride: 6,},
        {title: "Status",name: "status",type: "radio",value: "",disabled: "",required: "",statustype: [{ title: "Active", name: "status", value: true },{ title: "In Active", name: "status", value: false },],
        },
      ],
      isLoading: true,
      addButton: true,
    },
    {
      name: "Information",
      title: "Information",
      active: 0,
      inactive: 0,
      total: 0,
      getStatusData: getFooterMenuManagmentStatus,
      getData: getFooterMenuManagment,
      getPostData:postFooterMenuManagment,
      statusList:'All',
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "title",
          text: "Display Name",
          sort: true,
        },

        {
          dataField: "link",
          text: "Page Link",
          sort: true,
        },
        {
          dataField: "title",
          text: "Page Name",
          sort: true,
          formatter: (cell, prod, index) =>
          prod.informationtype !== undefined && prod.informationtype !== null ? prod.informationtype.name : <p>No Page Link</p>,
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 3, prod)}
                  />
                </Link>
                {/* <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "Deleted", 1, prod)}
                  />
                </Link> */}
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 3, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        {title: "_id",name: "_id",type: "hidden",value: "_id",disabled: "",},
        { title: "information", name: 'link', type: 'hidden', value: "", disabled: '' },
        {title: "Display Name",name: "title", type: "text",value: "",disabled: "",required: "",gride: 6,},
        {title: "Information Page",name: "informationtype",type: "select",value: [],outputDropDown: "name", outputData:getBlog,table: "Information",status: true,disabled: "",required: true,gride: 6,},
        {title: "Status",name: "status",type: "radio",value: "",disabled: "",required: "",statustype: [{ title: "Active", name: "status", value: true },{ title: "In Active", name: "status", value: false },],
        },
      ],
      isLoading: true,
      addButton: true,
    },
    {
      name: "Constant Page",
      title: "Fix Page",
      active: 0,
      inactive: 0,
      total: 0,
      getStatusData: getFooterMenuManagmentStatus,
      getData: getFooterMenuManagment,
      getPostData:postFooterMenuManagment,
      statusList:'All',
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "title",
          text: "Display Name",
          sort: true,
        },

        {
          dataField: "link",
          text: "Page Link",
          sort: true,
        },
        {
          dataField: "page",
          text: "Page Name",
          sort: true,
            formatter: (cell, prod, index) =>
            prod.constantpage != undefined &&  prod.constantpage != null ? prod.constantpage.typeofpage : <p>No Page Link</p>,
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 4, prod)}
                  />
                </Link>
             
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 4, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        {title: "_id",name: "_id",type: "hidden",value: "_id",disabled: "",},
        { title: "constant", name: 'link', type: 'hidden', value: "", disabled: '' },
        {title: "Display Name",name: "title", type: "text",value: "",disabled: "",required: "",gride: 6,},
        {title: "Costant Page",name: "constantpage",type: "select",value: [],outputDropDown: "typeofpage", outputData:getBlog,table: "Constant Page",status: true,disabled: "",required: true,gride: 6,},
        {title: "Status",name: "status",type: "radio",value: "",disabled: "",required: "",statustype: [{ title: "Active", name: "status", value: true },{ title: "In Active", name: "status", value: false },],
        },
      ],
      isLoading: true,
      addButton: true,
    },
    {
      name: "Poll",
      title: "Poll List",
      active: 0,
      inactive: 0,
      total: 0,
      getStatusData: getFooterMenuManagmentStatus,
      getData: getFooterMenuManagment,
      getPostData:postFooterMenuManagment,
      statusList:'All',
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "title",
          text: "Display Name",
          sort: true,
        },

        {
          dataField: "link",
          text: "Page Link",
          sort: true,
        },
        {
          dataField: "title",
          text: "Page Name",
          sort: true,
          formatter: (cell, prod, index) =>
          prod.polltype !== undefined && prod.polltype !== null ? prod.polltype.name : <p>No Page Link</p>,
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 5, prod)}
                  />
                </Link>
                
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 5, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        {title: "_id",name: "_id",type: "hidden",value: "_id",disabled: "",},
        { title: "main", name: 'link', type: 'hidden', value: "", disabled: '' },
        {title: "Display Name",name: "title", type: "text",value: "",disabled: "",required: "",gride: 6,},
        {title: "Category",name: "polltype",type: "select",value: [],outputDropDown: "name", outputData:getBlog,table: "Poll",status: true,disabled: "",required: true,gride: 6,},
        {title: "Status",name: "status",type: "radio",value: "",disabled: "",required: "",statustype: [{ title: "Active", name: "status", value: true },{ title: "In Active", name: "status", value: false },],
        },
      ],
      isLoading: true,
      addButton: true,
    },
    {
      name: "Career",
      title: "Career List",
      active: 0,
      inactive: 0,
      total: 0,
      getStatusData: getFooterMenuManagmentStatus,
      getData: getFooterMenuManagment,
      getPostData:postFooterMenuManagment,
      statusList:'All',
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "title",
          text: "Display Name",
          sort: true,
        },

        {
          dataField: "title",
          text: "Page Name",
          sort: true,
          formatter: (cell, prod, index) =>
          prod.careertype !== undefined && prod.careertype !== null ? prod.careertype.name : <p>No Page Link</p>,
        },
    
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 6, prod)}
                  />
                </Link>
            
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 6, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        {title: "_id",name: "_id",type: "hidden",value: "_id",disabled: "",},
        { title: "career", name: 'link', type: 'hidden', value: "", disabled: '' },
        {title: "Display Name",name: "title", type: "text",value: "",disabled: "",required: "",gride: 6,},
        {title: "Category",name: "careertype",type: "select",value: [],outputDropDown: "name", outputData:getBlog,table: "Career",status: true,disabled: "",required: true,gride: 6,},
        {title: "Status",name: "status",type: "radio",value: "",disabled: "",required: "",statustype: [{ title: "Active", name: "status", value: true },{ title: "In Active", name: "status", value: false },],
        },
      ],
      isLoading: true,
      addButton: true,
    },
    {
      name: "Main Page",
      title: "Main Page",
      active: 0,
      inactive: 0,
      total: 0,
      getStatusData: getFooterMenuManagmentStatus,
      getData: getFooterMenuManagment,
      getPostData:postFooterMenuManagment,
      statusList:'All',
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "title",
          text: "Display Name",
          sort: true,
        },

        {
          dataField: "link",
          text: "Page Link",
          sort: true,
        },
        {
          dataField: "page",
          text: "Page Name",
          sort: true,
         
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 7, prod)}
                  />
                </Link>
            
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 7, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        {title: "_id",name: "_id",type: "hidden",value: "_id",disabled: "",},
        { title: "mainpage", name: 'link', type: 'hidden', value: "", disabled: '' },
        {title: "Display Name",name: "title", type: "text",value: "",disabled: "",required: "",gride: 6,},
        {title: "Page Name",name: "page",type: "consType",value: [{label:'Home',value:'/'},{label:'About Us',value:'/about-us'},{label:'Contact Us',value:'/contact-us'},{label:'Blank',value:'/blank'}],outputDropDown: "name", outputData:getBlog,table: "Career",status: true,disabled: "",required: true,gride: 6,},
        {title: "Status",name: "status",type: "radio",value: "",disabled: "",required: "",statustype: [{ title: "Active", name: "status", value: true },{ title: "In Active", name: "status", value: false },],
        },
      ],
      isLoading: true,
      addButton: true,
    },
    {
      name: "Event Category",
      title: "Event Category",
      active: 0,
      inactive: 0,
      total: 0,
      getStatusData: getFooterMenuManagmentStatus,
      getData: getFooterMenuManagment,
      getPostData:postFooterMenuManagment,
      statusList:'All',
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "title",
          text: "Display Name",
          sort: true,
        },

        {
          dataField: "link",
          text: "Page Link",
          sort: true,
        },
        {
          dataField: "page",
          text: "Page Name",
          sort: true,
         
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 8, prod)}
                  />
                </Link>
            
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 8, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        {title: "_id",name: "_id",type: "hidden",value: "_id",disabled: "",},
        { title: "eventcategory", name: 'link', type: 'hidden', value: "", disabled: '' },
        {title: "Display Name",name: "title", type: "text",value: "",disabled: "",required: "",gride: 6,},
        {title: "Category",name: "eventcategory",type: "select",value: [],outputDropDown: "name", outputData:getMiscellaneous,table: "Category",status: true,disabled: "",required: true,gride: 6,},
        {title: "Status",name: "status",type: "radio",value: "",disabled: "",required: "",statustype: [{ title: "Active", name: "status", value: true },{ title: "In Active", name: "status", value: false },],
        },
      ],
      isLoading: true,
      addButton: true,
    },
    {
      name: "Event Category Relation",
      title: "Event Category Relation",
      active: 0,
      inactive: 0,
      total: 0,
      getStatusData: getFooterMenuManagmentStatus,
      getData: getFooterMenuManagment,
      getPostData:postFooterMenuManagment,
      statusList:'All',
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "title",
          text: "Display Name",
          sort: true,
        },

        {
          dataField: "link",
          text: "Page Link",
          sort: true,
        },
        {
          dataField: "page",
          text: "Page Name",
          sort: true,
         
        },
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 8, prod)}
                  />
                </Link>
            
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 8, prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
      ],
      inputType: [
        {title: "_id",name: "_id",type: "hidden",value: "_id",disabled: "",},
        { title: "eventcategoryrelation", name: 'link', type: 'hidden', value: "", disabled: '' },
        {title: "Display Name",name: "title", type: "text",value: "",disabled: "",required: "",gride: 6,},
        {title: "Category",name: "eventcategoryrelation",type: "select",value: [],outputDropDown: "name", outputData:getMiscellaneous,table: "Category Relation",status: true,disabled: "",required: true,gride: 6,},
        {title: "Status",name: "status",type: "radio",value: "",disabled: "",required: "",statustype: [{ title: "Active", name: "status", value: true },{ title: "In Active", name: "status", value: false },],
        },
      ],
      isLoading: true,
      addButton: true,
    },
   
  ])

  useEffect(async () => {
    const cardElementStatus = [...cardElement]
    for(let i=0;i<cardElementStatus.length;i++){
        cardElementStatus[i].getStatusData(cardElementStatus[i].name,cardElementStatus[i].statusList).then((res) => {
        cardElementStatus[i].active = res.active
        cardElementStatus[i].inactive = res.inactive
        cardElementStatus[i].total = res.total
        cardElementStatus[i].isLoading = false
        setCardElement([...cardElementStatus])
      })
    }
  setIsLoading(false)
  }, [])
  const changeTable = async (e, type, index,prod) => {
    e.preventDefault()
    setIsLoading(true)
    setIndexValue(index)
    const cardElementList = [...cardElement]
    setAddButton(cardElementList[index].addButton)
    setTitle(cardElementList[index].title)

  if (type == 'Table' || type == true || type == false) {
      setColumns(cardElementList[index].HeaderColumns)
      setOperationTable('Table')
    await cardElement[index].getData(cardElementList[index].name,type == 'Table'? "All" :type).then(result => {
        
        if (result.success) {
          setData(result.data)
        } else {
          setData([])
        }
      })
      setIsLoading(false)
    }else if (type == 'Add') {
    for(let i=0;i<cardElementList[index].inputType.length;i++){
      if (cardElementList[index].inputType[i].type === 'multiple' || cardElementList[index].inputType[i].type === 'select') {
        cardElementList[index].inputType[i].value=[]
        await cardElementList[index].inputType[i].outputData(cardElementList[index].inputType[i].table,cardElementList[index].inputType[i].status).then(results => {
            for (let k = 0; k < results.data.length; k++) {
           
                    let data = {
                        label: results.data[k][Object.keys(results.data[k]).filter(q => q === cardElementList[index].inputType[i].outputDropDown)],
                        value: results.data[k]._id,
                    }
                    cardElementList[index].inputType[i].value.push(data)
                  }
            })
          }
      }
   

      setInputType([...cardElementList[index].inputType])
      setOperationTable('Add')

      setIsLoading(false)
    }else{
    
      setInputType([...cardElement[index].inputType])
      if(type == 'Edit' || type == 'View' || type == 'Deleted'){
        if(cardElement[index].name === 'Different Page Relation'){
          setSortTreeStatus(true)
        }
        await cardElement[index].getData(cardElement[index].name,prod._id).then(async results=>{
          setSingleItem(results.data)
          for(let i=0;i<cardElementList[index].inputType.length;i++){
        
            if (cardElementList[index].inputType[i].type === 'multiple' || cardElementList[index].inputType[i].type === 'select') {
              cardElementList[index].inputType[i].value=[]
              await cardElementList[index].inputType[i].outputData(cardElementList[index].inputType[i].table,cardElementList[index].inputType[i].status).then(results => {
                  for (let k = 0; k < results.data.length; k++) {
                 
                          let data = {
                              label: results.data[k][Object.keys(results.data[k]).filter(q => q === cardElementList[index].inputType[i].outputDropDown)],
                              value: results.data[k]._id,
                          }
                          cardElementList[index].inputType[i].value.push(data)
                        }
                  })
                }
            }
          setIsLoading(false)
          setInputType([...cardElementList[index].inputType])
          setOperationTable(type)
        })
      }

    }
  }


  const postData = (e, values) => {
    const cardElementList = [...cardElement]
    if (values[Object.keys(values).filter(k => k == 'status')] === 'false') {
      values[Object.keys(values).filter(k => k == 'status')] = false
    }
    if (operationsTable == 'Add') {
      delete values['_id'];
    }
    setIsLoading(true)
    cardElementList[indexValue].getPostData(values,cardElementList[indexValue].name, operationsTable).then(result => {
      if (result.success) {
        setColumns([...cardElementList[indexValue].HeaderColumns])
        setOperationTable('Table')
        cardElementList[indexValue].getData(cardElementList[indexValue].name, status).then(result => {
          if (result.success) {
            setData(result.data)
            setIsLoading(false)
          } else {
            setData([])
            setIsLoading(false)
          }

        })
      } else {
        alert('Hello')
      }
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            {title} | {url.title}
          </title>
        </MetaTags>
        <Container fluid>
          {operationsTable == "" ? (
            <Breadcrumbs
              title={"Menu Management Dashboard"}
              breadcrumbItem={"Footer Navigation"}
              bredcumlink={"menu-management"}
            />
          ) : (
            <Breadcrumbs
              title={"Footer Navigation Menu"}
              breadcrumbItem={title}
              bredcumlink={"footer-navigation"}
            />
          )}

          <Row>
            {operationsTable == "" ? (
              cardElement.map((d, index) => (
                <Col sm={4} md={3} key={index}>
                  {isLoading ? (
                    <UiLoading />
                  ) : (
                    <GalleryCardElementwithEvent
                      data={d}
                      changeTable={(e, type) => changeTable(e, type, index)}
                      addButton={d.addButton}
                    />
                  )}
                </Col>
              ))
            ) : operationsTable == "Table" ? (
              <Card>
                {isLoading ? (
                  <UiLoading />
                ) : (
                  <ReactTable
                    title={title}
                    datas={data}
                    changeTable={(e, type) => changeTable(e, type, indexValue)}
                    columns={columns}
                    addButton={addButton}
                  />
                )}
              </Card>
            ) : operationsTable == "Add" ||
              operationsTable == "Edit" ||
              operationsTable == "Deleted" ||
              operationsTable == "View" ? (
              <>
                {isLoading ? (
                  <UiLoading />
                ) : (
                  <InputFormElement
                    operationsTable={operationsTable}
                    singleItem={singleItem}
                    bredcumlink={"banner"}
                    viewIcon={false}
                    filetype="single"
                    title={title}
                    changeTable={(e, type, title) =>
                      changeTable(e, type, indexValue)
                    }
                    inputType={inputType}
                    postData={postData}
                  />
                )}
              </>
            ) : (
              ""
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FooterNavigation
