import PropTypes from "prop-types"
import { useState } from "react"
import MetaTags from "react-meta-tags"
import React from "react"
import { Row, Col, Alert, Card, CardBody, Container } from "reactstrap"



import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"



// import images
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/images.png"
import { url } from "routes"
import { generate_random_number, generate_random_string } from "data/middlefunctions"
import { postForgotPasswords } from "data/authentication"

const ForgetPasswordPage = props => {
  const [message,setMessage] = useState('')
  const [error,setError] = useState('')
  const [randomnumber,setRandomNmber] = useState(generate_random_number(6) )
  const [titleData,setTitleData]= useState(localStorage.getItem('company')!== undefined && localStorage.getItem('company')!== null ?JSON.parse(localStorage.getItem('company')) :{})
  const [userData,setUserData]= useState(localStorage.getItem('user')!== undefined && localStorage.getItem('user')!== null ?JSON.parse(localStorage.getItem('user')) :{})
  const [typofLogin,setTypeofLogin] = useState('super')
const handleValidSubmit=(e,v)=>{
  if(`${randomnumber}` == `${v.capthca}`){
    if(v.password == v.confpassword){
      var regix = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
     
      if(regix.test(v.password)){
        if(v.userid.split('@').length == 2){
          postForgotPasswords(v,typofLogin,'email').then(result=>{
            console.log(result)
            if(result.success){
              window.location.href =`${process.env.PUBLIC_URL}/`
            }else{
              setError(res.data.message)
            }
          })
        }else{
          postForgotPasswords(v,typofLogin,'user').then(result=>{
            console.log(result)
            if(result.success){
              window.location.href =`${process.env.PUBLIC_URL}/`
            }else{
              setError(res.data.message)
            }
          })
        }
       
      }else{
        setError('Please type at least 1 Alphabet, 1 Number and 1 Special Character : The minimum character count should be 8')
      }
    }else{
      setError('Wrong Password')
    }
  }else{
    setError('Wrong Captcha')
  }
}
const changeAdmin=(e)=>{
  const {name,value} = e.target;
  setTypeofLogin(value)
}

  return (
    <React.Fragment>
      <MetaTags>
        <title>
          Forget Password | {titleData.name}
          <link rel="manifest" href={`${Object.keys(titleData).length == 0?`${url.commonUrls}/image/${titleData.imagetype}/${titleData.favicon}`:`${process.env.PUBLIC_URL}/favicon.ico`}`}/>
        </title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Forgot Password {titleData.name}.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={`${Object.keys(titleData).length == 0?`${url.commonUrls}/image/${titleData.imagetype}/${titleData.logo}`:`${process.env.PUBLIC_URL}/favicon.png`}`}
                            alt=""
                            className="rounded-circle"
                            //height="34"
                            style={{height:'100%',width:'100%',}}
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {error !='' ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {error}
                      </Alert>
                    ) : ''}
                    {message != '' ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {message}
                      </Alert>
                    ) : ''}
  <Row>
                    
                    <Col sm={2}></Col>       

           <Col sm={4}>  <input
                              className="form-check-input"
                              type="radio"
                              name='typeoflogin'
                              value='super'
                              checked={typofLogin === 'super' ? true :false}
                              onClick={changeAdmin}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                            <b>Super Admin</b> 
                            </label></Col>           
                    
           <Col sm={4}>  <input
                              className="form-check-input"
                              type="radio"
                              name='typeoflogin'
                              value='employee'
                              checked={typofLogin === 'employee' ? true :false}
                              onClick={changeAdmin}
                            
                            />
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              <b>Employee</b> 
                            </label></Col> 
    
                           
                     
                      </Row>
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                    >
                      <div className="mb-3">
                        <AvField
                          name="userid"
                          label="Email Id / User Id"
                          className="form-control"
                          placeholder="Enter Email Id / User Id"
                          type="text"
                          value=''
                          required
                        />
                      </div>
                      <div className="mb-3">
               
                        <AvField
                          name="password"
                          label="Password"
                          type="password"
                          required
                        />
                      </div>
                  <p style={{color:'red',fontSize:'12px'}}>*<span style={{color:'green'}}>Password Length 8</span>  : <b>(Alpha-Numeric And Special Character)</b> </p>
                      <div className="mb-3">
                        <AvField
                         
                          name="confpassword"
                          label="Conform Password"
                          type="password"
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="capthcacode"
                          label="Capthca Code"
                          className="form-control"
                          placeholder="Enter Email Id / User Id"
                          type="text"
                          value={randomnumber}
                          disabled={true}
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                            name="capthca"
                            label="Type Capthca"
                            className="form-control"
                            placeholder="Type Capthca"
                            type="text"
                            value=''
                           
                        />
                      </div>
                   
                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            className="btn btn-primary w-md "
                            type="submit"
                          >
                            Reset
                          </button>
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="/" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by The BigChimp Company
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
}

export default withRouter(ForgetPasswordPage)
