import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
    Container,
    Row,
    Col,
    Card,
    Button,
    CardBody
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

import { url } from "routes"
import { Link } from "react-router-dom"

import ReactTable from "UI/Table"

import InputFormElement from "UI/InputFormElement"

import UiLoading from "UI/loading"

import { getSalesPromo, getSalesPromoStatus,postSalesPromo } from "data/sales"
import { generate_random_number, generate_random_string, isEmpty } from "data/middlefunctions"
import GalleryCardElementwithEvent from "UI/GalleryCardElementWithEvent"

const SalesPromoList = () => {
    const [indexValue,setIndexValue] = useState()
    const [addButton,setAddButton] = useState(true)
    const [isLoading, setIsLoading] = useState(true)
    const [operationsTable, setOperationTable] = useState('')
    const [singleItem, setSingleItem] = useState({status:true,sku:generate_random_number() + generate_random_string(3),moq:1})
    const [status, setStatus] = useState('All')
    const [title, setTitle] = useState('')
    const [data, setData] = useState([])
    const [columns, setColumns] = useState([])
    const [inputType, setInputType] = useState([])
    const [cardElement, setCardElement] = useState([
      {
        name: 'Advertisements Master',
        title: 'Advertisements Master',
        total:0,
        active:0,
        inactive:0,
        statusList:'All',
        getStatusData: getSalesPromoStatus,
        getData: getSalesPromo,
        getPostData:postSalesPromo,
      
        HeaderColumns: [
          {
            dataField: "_id",
            text: "SL. No",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cell, prod, index) => index + 1,
          },
          {
            dataField: "name",
            text: "Name",
            sort: true,
  
          },
          {
            dataField: "businessname",
            text: "Business Name",
            sort: true,
          },
          {
            dataField: "brand",
            text: "Brand",
            sort: true,
          },
          {
            dataField: "website",
            text: "Web site",
            sort: true,
          },
          {
            dataField: "startdate",
            text: "Start Date",
            sort: true,
          }, 
          {
            dataField: "enddate",
            text: "End Date",
            sort: true,
          },
          {
            dataField: "totalcost",
            text: "Price",
            sort: true,
          },
          
          {
            dataField: "",
            text: "Status",
            sort: true,
            formatter: (cell, prod, index) =>
              prod.status == true ? <p>ON</p> : <p>OFF</p>,
          },
          {
            dataField: "action",
            isDummyField: true,
            text: "Action",
            // eslint-disable-next-line react/display-name
            formatter: (cell, prod, index) => (
              <>
                <div className="d-flex gap-3">
                  <Link to="#" className="text-success">
                    <i
                      className="mdi mdi-pencil font-size-18"
                      id="edittooltip"
                      onClick={e => changeTable(e, "Edit", 0,prod)}
                    />
                  </Link>
                  <Link to="#" className="text-danger">
                    <i
                      className="mdi mdi-delete font-size-18"
                      id="deletetooltip"
                      onClick={e => changeTable(e, "Deleted", 0,prod)}
                    />  
                  </Link>
                  <Link to="#" className="text-danger">
                    <i
                      className="mdi mdi-eye font-size-18"
                      id="deletetooltip"
                      onClick={e => changeTable(e, "View", 0,prod)}
                    />
                  </Link>
                </div>
              </>
            ),
          },
         
         
        ],
        inputType: [
            { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
            { title: 'uploadType', name: 'uploadType', type: 'hidden', value: 'sales', disabled: '' },
            { title: 'Name', name: 'name', type: 'text', value: '', disabled: '', required: true,gride:4 },
            { title: 'Buisness Name', name: 'businessname', type: 'text', value: '', disabled: '', required: true,gride:4 },
            { title: 'Brand', name: 'brand', type: 'text', value: '', disabled: '', required: true,gride:4 },
            { title: 'Website Url', name: 'website', type: 'text', value: '', disabled: '', required: true,gride:4 },
            { title: 'Booking Start Date', name: 'startdate', type: 'datetime-local', value: '', disabled: '', required: true,gride:4 },
            { title: 'Booking End Date', name: 'enddate', type: 'datetime-local', value: '', disabled: '', required: true,gride:4 },
            { title: 'Booking Amount', name: 'totalcost', type: 'text', value: '', disabled: '', required: true,gride:4 },
            { title: 'Booking Add Banner', name: 'image', type: 'file', value: [],label:'Booking AdV Image',uploadType:'sales', disabled: '', required: true,gride:4 },
            { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }],gride:4 }
        ],
        isLoading: true,
        addButton:true,
    },
      {
        name: 'Dynamic Pages',
        title: 'Dynamic Pages',
        total:0,
        active:0,
        inactive:0,
        statusList:'All',
        getStatusData: getSalesPromoStatus,
        getData: getSalesPromo,
        getPostData:postSalesPromo,
      
        HeaderColumns: [
          {
            dataField: "_id",
            text: "SL. No",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cell, prod, index) => index + 1,
          },
          {
            dataField: "name",
            text: "Page Name",
            sort: true,
  
          },
          
          
          {
            dataField: "",
            text: "Status",
            sort: true,
            formatter: (cell, prod, index) =>
              prod.status == true ? <p>ON</p> : <p>OFF</p>,
          },
          {
            dataField: "action",
            isDummyField: true,
            text: "Action",
            // eslint-disable-next-line react/display-name
            formatter: (cell, prod, index) => (
              <>
                <div className="d-flex gap-3">
                  <Link to="#" className="text-success">
                    <i
                      className="mdi mdi-pencil font-size-18"
                      id="edittooltip"
                      onClick={e => changeTable(e, "Edit", 1,prod)}
                    />
                  </Link>
                 
                  <Link to="#" className="text-danger">
                    <i
                      className="mdi mdi-eye font-size-18"
                      id="deletetooltip"
                      onClick={e => changeTable(e, "View", 1,prod)}
                    />
                  </Link>
                </div>
              </>
            ),
          },
         
         
        ],
        inputType: [
            { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
            { title: 'Header Advertisements', name: 'headeradv',type: 'select', value: [], outputDropDown:'name', outputData:getSalesPromo,table:'Advertisements Master',status:true, required: true, gride: 4 },
            { title: 'Left Side Advertisements', name: 'leftadv',type: 'select', value: [], outputDropDown:'name', outputData:getSalesPromo,table:'Advertisements Master',status:true, required: true, gride: 4 },
            { title: 'Footer Advertisements', name: 'footeradv',type: 'select', value: [], outputDropDown:'name', outputData:getSalesPromo,table:'Advertisements Master',status:true, required: true, gride: 4 },
          
        ],
        isLoading: true,
        addButton:false,
    },

    {
      name: 'Main Pages',
      title: 'Main Pages',
       total:0,
      active:0,
      inactive:0,
      statusList:'All',
      getStatusData: getSalesPromoStatus,
      getData: getSalesPromo,
      getPostData:postSalesPromo,
    
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "typeofpage",
          text: "Page Name",
          sort: true,
    
        },
        
        
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 2,prod)}
                  />
                </Link>
              
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 2,prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
       
       
      ],
      inputType: [
          { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
          { title: 'Header Advertisements', name: 'headeradv',type: 'select', value: [], outputDropDown:'name', outputData:getSalesPromo,table:'Advertisements Master',status:true, required: true, gride: 4 },
          { title: 'Left Side Advertisements', name: 'leftadv',type: 'select', value: [], outputDropDown:'name', outputData:getSalesPromo,table:'Advertisements Master',status:true, required: true, gride: 4 },
          { title: 'Footer Advertisements', name: 'footeradv',type: 'select', value: [], outputDropDown:'name', outputData:getSalesPromo,table:'Advertisements Master',status:true, required: true, gride: 4 },
      ],
      isLoading: true,
      addButton:false
    },
      {
        name: 'Blog',
        title: 'Blogs',
         total:0,
        active:0,
        inactive:0,
        statusList:'All',
        getStatusData: getSalesPromoStatus,
        getData: getSalesPromo,
        getPostData:postSalesPromo,
      
        HeaderColumns: [
          {
            dataField: "_id",
            text: "SL. No",
            sort: true,
            // eslint-disable-next-line react/display-name
            formatter: (cell, prod, index) => index + 1,
          },
          {
            dataField: "name",
            text: "Blog Name",
            sort: true,
  
          },
          
          
          {
            dataField: "",
            text: "Status",
            sort: true,
            formatter: (cell, prod, index) =>
              prod.status == true ? <p>ON</p> : <p>OFF</p>,
          },
          {
            dataField: "action",
            isDummyField: true,
            text: "Action",
            // eslint-disable-next-line react/display-name
            formatter: (cell, prod, index) => (
              <>
                <div className="d-flex gap-3">
                  <Link to="#" className="text-success">
                    <i
                      className="mdi mdi-pencil font-size-18"
                      id="edittooltip"
                      onClick={e => changeTable(e, "Edit", 3,prod)}
                    />
                  </Link>
                 
                  <Link to="#" className="text-danger">
                    <i
                      className="mdi mdi-eye font-size-18"
                      id="deletetooltip"
                      onClick={e => changeTable(e, "View", 3,prod)}
                    />
                  </Link>
                </div>
              </>
            ),
          },
         
        ],
        inputType: [
            { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
            { title: 'Header Advertisements', name: 'headeradv',type: 'select', value: [], outputDropDown:'name', outputData:getSalesPromo,table:'Advertisements Master',status:true, required: true, gride: 4 },
            { title: 'Left Side Advertisements', name: 'leftadv',type: 'select', value: [], outputDropDown:'name', outputData:getSalesPromo,table:'Advertisements Master',status:true, required: true, gride: 4 },
            { title: 'Footer Advertisements', name: 'footeradv',type: 'select', value: [], outputDropDown:'name', outputData:getSalesPromo,table:'Advertisements Master',status:true, required: true, gride: 4 },
        ],
        isLoading: true,
        addButton:false,
    },
    {
      name: 'Home',
      title: 'Home',
       total:0,
      active:0,
      inactive:0,
      statusList:'All',
      getStatusData: getSalesPromoStatus,
      getData: getSalesPromo,
      getPostData:postSalesPromo,
    
      HeaderColumns: [
        {
          dataField: "_id",
          text: "SL. No",
          sort: true,
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => index + 1,
        },
        {
          dataField: "typeofpage",
          text: "Page Name",
          sort: true,
  
        },
        
        
        {
          dataField: "",
          text: "Status",
          sort: true,
          formatter: (cell, prod, index) =>
            prod.status == true ? <p>ON</p> : <p>OFF</p>,
        },
        {
          dataField: "action",
          isDummyField: true,
          text: "Action",
          // eslint-disable-next-line react/display-name
          formatter: (cell, prod, index) => (
            <>
              <div className="d-flex gap-3">
                <Link to="#" className="text-success">
                  <i
                    className="mdi mdi-pencil font-size-18"
                    id="edittooltip"
                    onClick={e => changeTable(e, "Edit", 4,prod)}
                  />
                </Link>
               
                <Link to="#" className="text-danger">
                  <i
                    className="mdi mdi-eye font-size-18"
                    id="deletetooltip"
                    onClick={e => changeTable(e, "View", 4,prod)}
                  />
                </Link>
              </div>
            </>
          ),
        },
       
      ],
      inputType: [
          { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
          { title: 'Header Advertisements', name: 'headeradv',type: 'select', value: [], outputDropDown:'name', outputData:getSalesPromo,table:'Advertisements Master',status:true, required: true, gride: 4 },
          { title: 'Left Side Advertisements', name: 'leftadv',type: 'select', value: [], outputDropDown:'name', outputData:getSalesPromo,table:'Advertisements Master',status:true, required: true, gride: 4 },
          { title: 'Pre Footer Advertisements', name: 'footeradv',type: 'select', value: [], outputDropDown:'name', outputData:getSalesPromo,table:'Advertisements Master',status:true, required: true, gride: 4 },
          { title: 'Footer Advertisements', name: 'footeradv',type: 'select', value: [], outputDropDown:'name', outputData:getSalesPromo,table:'Advertisements Master',status:true, required: true, gride: 4 },
      ],
      isLoading: true,
      addButton:false,
  },
  {
    name: 'About Us',
    title: 'About Us',
     total:0,
    active:0,
    inactive:0,
    statusList:'All',
    getStatusData: getSalesPromoStatus,
    getData: getSalesPromo,
    getPostData:postSalesPromo,
  
    HeaderColumns: [
      {
        dataField: "_id",
        text: "SL. No",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cell, prod, index) => index + 1,
      },
      {
        dataField: "typeofpage",
        text: "Page Name",
        sort: true,

      },
      
      
      {
        dataField: "",
        text: "Status",
        sort: true,
        formatter: (cell, prod, index) =>
          prod.status == true ? <p>ON</p> : <p>OFF</p>,
      },
      {
        dataField: "action",
        isDummyField: true,
        text: "Action",
        // eslint-disable-next-line react/display-name
        formatter: (cell, prod, index) => (
          <>
            <div className="d-flex gap-3">
              <Link to="#" className="text-success">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={e => changeTable(e, "Edit", 5,prod)}
                />
              </Link>
     
              <Link to="#" className="text-danger">
                <i
                  className="mdi mdi-eye font-size-18"
                  id="deletetooltip"
                  onClick={e => changeTable(e, "View", 5,prod)}
                />
              </Link>
            </div>
          </>
        ),
      },
     
    ],
    inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Header Advertisements', name: 'headeradv',type: 'select', value: [], outputDropDown:'name', outputData:getSalesPromo,table:'Advertisements Master',status:true, required: true, gride: 4 },
        { title: 'Left Side Advertisements', name: 'leftadv',type: 'select', value: [], outputDropDown:'name', outputData:getSalesPromo,table:'Advertisements Master',status:true, required: true, gride: 4 },
        { title: 'Footer Advertisements', name: 'footeradv',type: 'select', value: [], outputDropDown:'name', outputData:getSalesPromo,table:'Advertisements Master',status:true, required: true, gride: 4 },
    ],
    isLoading: true,
    addButton:false,
},
  {
    name: 'Contact Us',
    title: 'Contact Us',
    total:0,
    active:0,
    inactive:0,
    statusList:'All',
    getStatusData: getSalesPromoStatus,
    getData: getSalesPromo,
    getPostData:postSalesPromo,

    HeaderColumns: [
      {
        dataField: "_id",
        text: "SL. No",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: (cell, prod, index) => index + 1,
      },
      {
        dataField: "typeofpage",
        text: "Page Name",
        sort: true,

      },
      
      
      {
        dataField: "",
        text: "Status",
        sort: true,
        formatter: (cell, prod, index) =>
          prod.status == true ? <p>ON</p> : <p>OFF</p>,
      },
      {
        dataField: "action",
        isDummyField: true,
        text: "Action",
        // eslint-disable-next-line react/display-name
        formatter: (cell, prod, index) => (
          <>
            <div className="d-flex gap-3">
              <Link to="#" className="text-success">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={e => changeTable(e, "Edit", 6,prod)}
                />
              </Link>
    
              <Link to="#" className="text-danger">
                <i
                  className="mdi mdi-eye font-size-18"
                  id="deletetooltip"
                  onClick={e => changeTable(e, "View", 6,prod)}
                />
              </Link>
            </div>
          </>
        ),
      },
    
    ],
    inputType: [
        { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
        { title: 'Header Advertisements', name: 'headeradv',type: 'select', value: [], outputDropDown:'name', outputData:getSalesPromo,table:'Advertisements Master',status:true, required: true, gride: 4 },
        { title: 'Left Side Advertisements', name: 'leftadv',type: 'select', value: [], outputDropDown:'name', outputData:getSalesPromo,table:'Advertisements Master',status:true, required: true, gride: 4 },
        { title: 'Footer Advertisements', name: 'footeradv',type: 'select', value: [], outputDropDown:'name', outputData:getSalesPromo,table:'Advertisements Master',status:true, required: true, gride: 4 },
    ],
    isLoading: true,
    addButton:false,
  },
 
    ])

    useEffect(async () => {
      const cardElementStatus = [...cardElement]
      for(let i=0;i<cardElementStatus.length;i++){
          cardElementStatus[i].getStatusData(cardElementStatus[i].name,cardElementStatus[i].statusList).then((res) => {
          cardElementStatus[i].active = res.active
          cardElementStatus[i].inactive = res.inactive
          cardElementStatus[i].total = res.total
          cardElementStatus[i].isLoading = false
          setCardElement([...cardElementStatus])
        })
      }
    setIsLoading(false)
    }, [])
    const changeTable = async (e, type, index,prod) => {
      e.preventDefault()
      setIsLoading(true)
      setIndexValue(index)
      const cardElementList = [...cardElement]
      setAddButton(cardElementList[index].addButton)
      setTitle(cardElementList[index].title)
  
    if (type == 'Table' || type == true || type == false) {
        setColumns(cardElementList[index].HeaderColumns)
        setOperationTable('Table')
      await cardElement[index].getData(cardElementList[index].name,type == 'Table'? "All" :type).then(result => {
          
          if (result.success) {
            setData(result.data)
          } else {
            setData([])
          }
        })
        setIsLoading(false)
      }else if (type == 'Add') {
      for(let i=0;i<cardElementList[index].inputType.length;i++){
        if (cardElementList[index].inputType[i].type === 'multiple' || cardElementList[index].inputType[i].type === 'select') {
          cardElementList[index].inputType[i].value=[]
          await cardElementList[index].inputType[i].outputData(cardElementList[index].inputType[i].table,cardElementList[index].inputType[i].status).then(results => {
              for (let k = 0; k < results.data.length; k++) {
             
                      let data = {
                          label: results.data[k][Object.keys(results.data[k]).filter(q => q === cardElementList[index].inputType[i].outputDropDown)],
                          value: results.data[k]._id,
                      }
                      cardElementList[index].inputType[i].value.push(data)
                    }
              })
            }
        }
     
  
        setInputType([...cardElementList[index].inputType])
        setOperationTable('Add')
  
        setIsLoading(false)
      }else{
      
        setInputType([...cardElement[index].inputType])
        if(type == 'Edit' || type == 'View' || type == 'Deleted'){
          if(cardElement[index].name === 'Different Page Relation'){
            setSortTreeStatus(true)
          }
          await cardElement[index].getData(cardElement[index].name,prod._id).then(async results=>{
            setSingleItem(results.data)
            for(let i=0;i<cardElementList[index].inputType.length;i++){
          
              if (cardElementList[index].inputType[i].type === 'multiple' || cardElementList[index].inputType[i].type === 'select') {
                cardElementList[index].inputType[i].value=[]
                await cardElementList[index].inputType[i].outputData(cardElementList[index].inputType[i].table,cardElementList[index].inputType[i].status).then(results => {
                    for (let k = 0; k < results.data.length; k++) {
                   
                            let data = {
                                label: results.data[k][Object.keys(results.data[k]).filter(q => q === cardElementList[index].inputType[i].outputDropDown)],
                                value: results.data[k]._id,
                            }
                            cardElementList[index].inputType[i].value.push(data)
                          }
                    })
                  }
              }
           
            setInputType([...cardElementList[index].inputType])
            setOperationTable(type)
            setIsLoading(false)
          })
        }
  
      }
    }
  
  
    const postData = (e, values) => {
      const cardElementList = [...cardElement]
      if (values[Object.keys(values).filter(k => k == 'status')] === 'false') {
        values[Object.keys(values).filter(k => k == 'status')] = false
      }
      if (operationsTable == 'Add') {
        delete values['_id'];
      }
      setIsLoading(true)
      cardElementList[indexValue].getPostData(isEmpty(values),cardElementList[indexValue].name, operationsTable).then(result => {
        if (result.success) {
          setColumns([...cardElementList[indexValue].HeaderColumns])
          setOperationTable('Table')
          cardElementList[indexValue].getData(cardElementList[indexValue].name, status).then(result => {
            if (result.success) {
              setData(result.data)
              setIsLoading(false)
            } else {
              setData([])
              setIsLoading(false)
            }
  
          })
        } else {
          alert('Hello')
        }
      })
    }
   
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Advertisements | {url.title}</title>
                </MetaTags>
                <Container fluid>
                    {
                        operationsTable == '' ?
                            <Breadcrumbs
                                title={"Adv Dashboard"}
                                breadcrumbItem={"Advertisements"}
                                bredcumlink={"promo-dashboard"}
                            />
                            : <Breadcrumbs
                                title={'Advertisements'}
                                breadcrumbItem={title}
                                bredcumlink={'sales-promo'}
                            />
                    }

                    <Row>
                        {

                            operationsTable == '' ?
                                cardElement.map((d, index) => (
                                    <Col sm={4} md={3} key={index}>
                                        {
                                            isLoading ?

                                                <UiLoading />

                                                :
                                                <GalleryCardElementwithEvent
                                                    data={d}
                                                    changeTable={(e, type,) => changeTable(e, type, index)}
                                                    addButton={d.addButton}
                                                />
                                        }

                                    </Col>
                                ))
                                :
                                operationsTable == 'Table' ?
                                    <Card>
                                        {
                                            isLoading ?
                                                <UiLoading />
                                                : <ReactTable
                                                    title={title}
                                                    datas={data}
                                                    changeTable={(e, type, title) => changeTable(e, type, indexValue)}
                                                    columns={columns}
                                                    addButton={addButton}
                                                />
                                        }

                                    </Card>
                                    :
                                    operationsTable == 'Add' || operationsTable == 'Edit' || operationsTable == 'Deleted' || operationsTable == 'View' ?
                                        <>
                                            {
                                                isLoading ?
                                                    <UiLoading />
                                                    :
                                                    <InputFormElement
                                                        operationsTable={operationsTable}
                                                        singleItem={singleItem}

                                                        bredcumlink={'catalog-list'}
                                                        viewIcon={false}
                                                        filetype='single'

                                                        title={title}
                                                        changeTable={(e, type) => changeTable(e, type, indexValue)}
                                                        inputType={inputType}
                                                        postData={postData}
                                                     
                                                    />
                                            }
                                        </>
                                        : ''
                        }

                    </Row>
                </Container>
            </div>
        </React.Fragment >
    )
}



export default SalesPromoList
