import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardBody
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
//i18n
import { withTranslation } from "react-i18next"
import { url } from "routes"
import { Link } from "react-router-dom"
import GalleryCardElementwithEvent from "UI/GalleryCardElementWithEvent"
import ReactTable from "UI/Table"
import { getDepartment,getDepartmentStatus,postDepartment} from "data/department"
import InputFormElement from "UI/InputFormElement"
import { getpageStructure } from "data/systemadmin/employee"
import { getMiscellaneous } from "data/systemadmin/miscellaneous"
import { getEmployee } from "data/systemadmin/employee"
import { isEmpty } from "data/middlefunctions"
import UiLoading from "UI/loading"

const StudyTourLink = () => {
  const [allPageLoading,setAllPageLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [indexValue, setIndexValue] = useState('')
  // const [addButton,setAddButton]=useState(false)
  const [operationsTable, setOperationTable] = useState('Card')
  const [singleItem, setSingleItem] = useState({pagedescription:'',status:true})
  const [status, setStatus] = useState('All')
  const [addButton, setAddButton] = useState(false)
  const [department, setdepartment] = useState('')
  const [title, setTitle] = useState('')
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const [cardValue,setCardValue] = useState({})
  const [inputType, setInputType] = useState([
    { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
    {title:`department`,name:'department',type:'hidden',value:'department',disabled:'',},
    {title:`uploadtype`,name:'uploadtype',type:'hidden',value:'seminar',disabled:'',},
    {title:`Department `,name:'pagedescription',type:'text',value:'',disabled:true,gride:3},
    { title: 'Study Tour', name: 'name', type: 'text', value: '', disabled: '', required: true, gride: 3 },
    { title: 'Study Tour Date', name: 'studydate', type: 'date', value: '', disabled: '', required: true, gride: 3 },
    { title: 'Accademic Year', name: 'accademicyear', type: 'select', value: [], outputDropDown:'name', outputData:getMiscellaneous,table:'Academic Year',status:true, required: true, gride: 3 },
    { title: 'Seminar description', name: 'shortdescription', type: 'textarea', value: '', disabled: '', required: true, gride: 12 },
    { title: 'Study Tour Image', name: 'image', type: 'file', value: [], label:'Seminar Image',uploadtype:'seminar', disabled: '', required: true, gride: 12,multiple:true },
    { title: 'Description', name: 'description', type: 'description', value: '', disabled: '', required: true, gride: 12 },
    { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
  ])

  const [cardElement, setCardElement] = useState([])

  useEffect(async () => {
    const cardElementStatus = [...cardElement]
    await getEmployee('Department','All').then(async result=>{
      const d=[]
      if(result.success){
        for(let i=0; i < result.data.length;i++){
          await getDepartmentStatus(result.data[i]._id,'Study Tour').then(results=>{
            d.push({
              _id: result.data[i]._id,
              name: result.data[i].name,
              title: result.data[i].name,
              total:results.total,
              active:results.active,
              inactive:results.inactive,
              getStatusData: getDepartmentStatus,
              getData: getDepartment,
              getPostData:postDepartment,
              statusList:'All',
              HeaderColumns: [
                {
                  dataField: "_id",
                  text: "SL. No",
                  sort: true,
                  // eslint-disable-next-line react/display-name
                  formatter: (cell, prod, index) => index + 1,
                },
                {
                  dataField: "name",
                  text: "Content Title",
                  sort: true,
        
                },
                {
                  dataField: "",
                  text: "Status",
                  sort: true,
                  formatter: (cell, prod, index) =>
                    prod.status == true ? <p>ON</p> : <p>OFF</p>,
                },
                {
                  dataField: "action",
                  isDummyField: true,
                  text: "Action",
                  // eslint-disable-next-line react/display-name
                  formatter: (cell, prod, index) => (
                    <>
                      <div className="d-flex gap-3">
                        <Link to="#" className="text-success">
                          <i
                            className="mdi mdi-pencil font-size-18"
                            id="edittooltip"
                            onClick={e => changeTable(e, "Edit", parseInt(d.length-1), prod, result.data[parseInt(d.length-1)]._id)}
                          />
                        </Link>
                        <Link to="#" className="text-danger">
                          <i
                            className="mdi mdi-delete font-size-18"
                            id="deletetooltip"
                            onClick={e => changeTable(e, "Edit", parseInt(d.length-1), prod, result.data[parseInt(d.length-1)]._id)}
                          />  
                        </Link>
                        <Link to="#" className="text-danger">
                          <i
                            className="mdi mdi-eye font-size-18"
                            id="deletetooltip"
                            onClick={e => changeTable(e, "Edit", parseInt(d.length-1), prod, result.data[parseInt(d.length-1)]._id)}
                          />
                        </Link>
                      </div>
                    </>
                  ),
                },
               
               
              ],
      
              isLoading:false,
              addButton: true,
            })
          })
          
        }
        setCardElement(d)
        setAllPageLoading(false)
      }
    })
  
    
    setIsLoading(false)
  
  }, [])
  const changeTable = async (e, type, index, prod, pagedescriptionid) => {
    console.log(prod)
    setdepartment(pagedescriptionid)
    e.preventDefault()
    setCardValue(prod)

    setIndexValue(index)
    setAllPageLoading(true)
    const cardElementList = [...cardElement]
    const inputLists = [...inputType]
    if (cardElementList.length !== 0) {
      setAddButton(cardElementList[index].addButton)
      setTitle(cardElementList[index].name)
    } else {
      setAddButton(false)
      setTitle(prod.name)
    }

    if (type == 'Table' || type == true || type == false) {
      setColumns(cardElementList[index].HeaderColumns)
      setOperationTable('Table')

      await cardElement[index].getData(cardElementList[index]._id, type == 'Table' ? "Study Tour-All" : `Study Tour-${type}`).then(result => {

        if (result.success) {
          setData(result.data)
          setAllPageLoading(false)
        } else {
          setData([])
          setAllPageLoading(false)
        }
      })

    } else if (type == 'Add') {

      if (prod.name !== undefined) {
        setTitle(prod.name)
      } else {
        setTitle(cardElementList[index].title)
      }



      for (let i = 0; i < inputLists.length; i++) {
        if (inputLists[i].type === 'multiple' || inputLists[i].type === 'select') {
          inputLists[i].value = []
          await inputLists[i].outputData(inputLists[i].table, inputLists[i].status).then(results => {
            for (let k = 0; k < results.data.length; k++) {

              let data = {
                label: results.data[k][Object.keys(results.data[k]).filter(q => q === inputLists[i].outputDropDown)],
                value: results.data[k]._id,
              }
              inputLists[i].value.push(data)
            }
          })
        }
      }


      console.log(singleItem)
      setSingleItem({ status: true,pagedescription:prod.pagedescription, department: pagedescriptionid })

      setInputType([...inputLists])
      setOperationTable('Add')
      setAllPageLoading(false)
      // setIsLoading(false)
    } else {

      setInputType([...inputLists])
      if (type == 'Edit' || type == 'View' || type == 'Deleted') {

        await getDepartment('Study Tour', prod._id).then(async results => {
      setTitle(results.data.department.name)
          setSingleItem({ pagedescription: results.data.department.name,department:results.data.department._id, ...results.data })
          for (let i = 0; i < inputLists.length; i++) {

            if (inputLists[i].type === 'multiple' || inputLists[i].type === 'select') {
              inputLists[i].value = []
              await inputLists[i].outputData(inputLists[i].table, inputLists[i].status).then(results => {
                for (let k = 0; k < results.data.length; k++) {

                  let data = {
                    label: results.data[k][Object.keys(results.data[k]).filter(q => q === inputLists[i].outputDropDown)],
                    value: results.data[k]._id,
                  }
                  inputLists[i].value.push(data)
                }
              })
            }
          }

          setInputType([...inputLists])
          setOperationTable(type)
          setAllPageLoading(false)
        })
      }

    }
  }


  const postData = (e, values) => {
    console.log(indexValue)
    const cardElementList = [...cardElement]
    if (values[Object.keys(values).filter(k => k == 'status')] === 'false') {
      values[Object.keys(values).filter(k => k == 'status')] = false
    }
    if (operationsTable == 'Add') {
      delete values['_id'];
    }
    if (operationsTable == 'Edit') {
      delete values['pagedescriptionid'];
    }
    if (values.name !== undefined) {
      values['slug'] = values.name.replace(/[^\w\s]/gi, '').split(' ').join('-').toLowerCase()
      values['metatitle'] = values.name;
    }
    if (values.title !== undefined) {
      values['slug'] = values.title.replace(/[^\w\s]/gi, '').split(' ').join('-').toLowerCase()
      values['metatitle'] = values.title;
    }
    setAllPageLoading(true)
    postDepartment(isEmpty(values), 'Study Tour', operationsTable).then(result => {
      if (result.success) {
        setColumns([...cardElementList[indexValue].HeaderColumns])
        setOperationTable('Table')
        getDepartment(result.data.department, 'Study Tour-All').then(result => {
          if (result.success) {
            setData(result.data)
            setAllPageLoading(false)
          } else {
            setData([])
            setAllPageLoading(false)
          }

        })
      } else {
        alert('Hello')
      }
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Seminar | {url.title}</title>
        </MetaTags>
        <Container fluid>
          {
            operationsTable == 'Card' ?
              <Breadcrumbs
                title={"Department Dashboard"}
                breadcrumbItem={"Study Tour"}
                bredcumlink={"department-dashboard"}
              />
              : <Breadcrumbs
                title={'Study Tour'}
                breadcrumbItem={title}
                bredcumlink={'study-tour'}
              />
          }

          <Row>
            {
        
  
          allPageLoading?
          <UiLoading cardElementOption={!allPageLoading} />
          :
              operationsTable == 'Card' ?
                cardElement.map((d, index) => (
                  <Col sm={4} md={3} key={index}> 
                
                        <GalleryCardElementwithEvent
                          data={d}
                          changeTable={(e, type) => changeTable(e, type, index, { pagedescription: d.name }, d._id)}
                          addButton={d.addButton}
                          isLoading={isLoading}
                        />
                  </Col>
                ))
                :
                operationsTable == 'Table' ?
                  <Card>
                     <ReactTable
                          title={title}
                          datas={data}
                          changeTable={(e, type) => changeTable(e, type, indexValue, { pagedescription: title }, department)}
                          columns={columns}
                          addButton={addButton}
                          
                        />
                    

                  </Card>
                  :
              
                         
                          <InputFormElement
                            operationsTable={operationsTable}
                            singleItem={singleItem}

                            bredcumlink={'InformationPage'}
                            viewIcon={false}
                            filetype='single'

                            title={title}
                            changeTable={(e, type) => changeTable(e, type, indexValue, { pagedescription: title }, department)}
                            inputType={inputType}
                            postData={postData}
                            isLoading={isLoading}
                          />
                      
                  
            }

          </Row>
        </Container>
      </div>
    </React.Fragment >
  )
}



export default StudyTourLink
