import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardBody
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"

import { url } from "routes"
import { Link } from "react-router-dom"

import GalleryCardElementwithEvent from "UI/GalleryCardElementWithEvent"
import ReactTable from "UI/Table"


import { getpageStructureLinkStatus,getpageStructurLink,postPageStructureLink} from "data/pageStructureLink"
import InputFormElement from "UI/InputFormElement"
import { getpageStructure } from "data/pageStructure"
import { getMiscellaneous } from "data/systemadmin/miscellaneous"
import { getEmployee } from "data/systemadmin/employee"
import { isEmpty } from "data/middlefunctions"
import UiLoading from "UI/loading"

const pageStructureLink = () => {
  const [allPageLoading,setAllPageLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [indexValue, setIndexValue] = useState('')
  const [addButton,setAddButton]=useState(false)
  const [operationsTable, setOperationTable] = useState('Card')
  const [singleItem, setSingleItem] = useState({pagedescription:'',status:true})
  const [status, setStatus] = useState('All')
  const [title, setTitle] = useState('')
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const [cardValue,setCardValue] = useState({})
  const [inputType, setInputType] = useState([
    { title: '_id', name: '_id', type: 'hidden', value: '_id', disabled: '' },
    {title:`pagedescriptionid`,name:'pagedescriptionid',type:'hidden',value:'pagedescriptionid',disabled:'',},
    {title:`New's Category`,name:'pagedescription',type:'text',value:'',disabled:true,gride:4},
    { title: 'Content Title', name: 'name', type: 'text', value: '', disabled: '', required: true, gride: 4 },
    { title: 'Member', name: 'member', type: 'multiple', value: [], outputDropDown:'name', outputData:getEmployee,table:'Employee',status:true, required: true, gride: 4 },
    { title: 'Accademic Year', name: 'accademicyear', type: 'select', value: [], outputDropDown:'name', outputData:getMiscellaneous,table:'Academic Year',status:true, required: true, gride: 4 },
    { title: 'Gallery', name: 'galleryyear', type: 'multiple', value: [], outputDropDown:'name', outputData:getMiscellaneous,table:'Academic Year',status:true, required: true, gride: 4 },
    { title: 'Report Year', name: 'reportyear', type: 'multiple', value: [], outputDropDown:'name', outputData:getMiscellaneous,table:'Academic Year',status:true, required: true, gride: 4 },
    { title: 'Document Url', name: 'documenturl', type: 'text', value: '', disabled: '', required: '', gride: 4 },
    { title: 'Display Image', name: 'image', type: 'file', value: [], label:'Display Image', disabled: '', required: true, gride: 4 },
    { title: 'Headline', name: 'sortdescription', type: 'textarea', value: '', disabled: '', required: true, gride: 12 },
    { title: 'Status', name: 'status', type: 'radio', value: '', disabled: '', required: '', statustype: [{ title: 'Active', name: 'status', value: true }, { title: 'In Active', name: 'status', value: false }] }
  ])

  const [cardElement, setCardElement] = useState([])

  useEffect(async () => {
    const cardElementStatus = [...cardElement]
    await getpageStructure('Different Page Design','All').then(async result=>{
      const d=[]
      if(result.success){
        for(let i=0; i < result.data.length;i++){
          await getpageStructureLinkStatus(result.data[i]._id,'All').then(results=>{
            d.push({
              _id: result.data[i]._id,
              name: result.data[i].name,
              title: result.data[i].name,
              total:results.total,
              active:results.active,
              inactive:results.inactive,
              getStatusData: getpageStructurLink,
              getData: getpageStructurLink,
              getPostData:postPageStructureLink,
              statusList:'All',
              HeaderColumns: [
                {
                  dataField: "_id",
                  text: "SL. No",
                  sort: true,
                  // eslint-disable-next-line react/display-name
                  formatter: (cell, prod, index) => index + 1,
                },
                {
                  dataField: "name",
                  text: "Content Title",
                  sort: true,
        
                },
                {
                  dataField: "",
                  text: "Status",
                  sort: true,
                  formatter: (cell, prod, index) =>
                    prod.status == true ? <p>ON</p> : <p>OFF</p>,
                },
                {
                  dataField: "action",
                  isDummyField: true,
                  text: "Action",
                  // eslint-disable-next-line react/display-name
                  formatter: (cell, prod, index) => (
                    <>
                      <div className="d-flex gap-3">
                        <Link to="#" className="text-success">
                          <i
                            className="mdi mdi-pencil font-size-18"
                            id="edittooltip"
                            onClick={e => changeTable(e, "Edit", i,prod)}
                          />
                        </Link>
                        <Link to="#" className="text-danger">
                          <i
                            className="mdi mdi-delete font-size-18"
                            id="deletetooltip"
                            onClick={e => changeTable(e, "Deleted", i,prod)}
                          />  
                        </Link>
                        <Link to="#" className="text-danger">
                          <i
                            className="mdi mdi-eye font-size-18"
                            id="deletetooltip"
                            onClick={e => changeTable(e, "View", i,prod)}
                          />
                        </Link>
                      </div>
                    </>
                  ),
                },
               
               
              ],
      
              isLoading:false,
              addButton: true,
            })
          })
          
        }
        setCardElement(d)
        setAllPageLoading(false)
      }
    })
    console.log(cardElementStatus)
    
    setIsLoading(false)
  
  }, [])
  const changeTable = async (e, type, index,prod) => {
    console.log(prod)
    e.preventDefault()
    setCardValue(prod)
    console.log(index)
    setIndexValue(index)
    const cardElementList = [...cardElement]
    const inputLists =[...inputType]
    if(cardElementList.length !== 0){
      setAddButton(cardElementList[index].addButton)
    }else{
      setAddButton(false)
    setTitle(prod.name)
    }
    setIsLoading(true)
  if (type == 'Table' || type == true || type == false) {
      setColumns(cardElementList[index].HeaderColumns)
      setOperationTable('Table')
      setTitle(cardElementList[index].title)
    await cardElement[index].getData(cardElementList[index]._id,type == 'Table'? "All" :type).then(result => {
        
        if (result.success) {
          setData(result.data)
          setIsLoading(false)
        } else {
          setData([])
          setIsLoading(false)
        }
      })
      
    }else if (type == 'Add') {
      
      for(let i=0;i<inputLists.length;i++){
        if (inputLists[i].type === 'multiple' || inputLists[i].type === 'select') {
          inputLists[i].value=[]
          await inputLists[i].outputData(inputLists[i].table,inputLists[i].status).then(results => {
              for (let k = 0; k < results.data.length; k++) {
             
                      let data = {
                          label: results.data[k][Object.keys(results.data[k]).filter(q => q === inputLists[i].outputDropDown)],
                          value: results.data[k]._id,
                      }
                      inputLists[i].value.push(data)
                    }
              })
            }
        }
        if(prod !== undefined ){
          singleItem.pagedescription=prod.name
          singleItem.pagedescriptionid =prod._id
          setTitle(prod.name)
        }else{
          singleItem.pagedescription=cardValue.name
          singleItem.pagedescriptionid =cardValue._id
          setTitle(cardValue.name)
        }
        
         console.log(singleItem)
        setSingleItem({...singleItem})
        
        setInputType([...inputLists])
        setOperationTable('Add')
 
        setIsLoading(false)
    }else{
    
      setInputType([...inputLists])
      if(type == 'Edit' || type == 'View' || type == 'Deleted'){
     
        await getpageStructurLink(prod.name,prod._id).then(async results=>{
        
            // results.data.pagedescription=results.pagedescriptionid.name
           
            
            setTitle(results.data.pagedescriptionid.name)
          setSingleItem({pagedescription:results.data.pagedescriptionid.name ,...results.data})
          for(let i=0;i<inputLists.length;i++){
        
            if (inputLists[i].type === 'multiple' || inputLists[i].type === 'select') {
              inputLists[i].value=[]
              await inputLists[i].outputData(inputLists[i].table,inputLists[i].status).then(results => {
                  for (let k = 0; k < results.data.length; k++) {
                 
                          let data = {
                              label: results.data[k][Object.keys(results.data[k]).filter(q => q === inputLists[i].outputDropDown)],
                              value: results.data[k]._id,
                          }
                          inputLists[i].value.push(data)
                        }
                  })
                }
            }
         
          setInputType([...inputLists])
          setOperationTable(type)
          setIsLoading(false)
        })
      }

    }
  }


  const postData = (e, values) => {

    const cardElementList = [...cardElement]
    if (values[Object.keys(values).filter(k => k == 'status')] === 'false') {
      values[Object.keys(values).filter(k => k == 'status')] = false
    }
    if (operationsTable == 'Add') {
      delete values['_id'];
    }
    if (operationsTable == 'Edit') {
      delete values['pagedescriptionid'];
    }
    if(values.name !== undefined ){
      values['slug'] =values.name.replace(/[^\w\s]/gi, '').split(' ').join('-').toLowerCase()
      values['metatitle'] = values.name;
    }
    if( values.title !== undefined){
      values['slug'] =values.title.replace(/[^\w\s]/gi, '').split(' ').join('-').toLowerCase()
      values['metatitle'] = values.title;
    }
    setIsLoading(true)
    postPageStructureLink(isEmpty(values),cardElementList[indexValue].name, operationsTable).then(result => {
      if (result.success) {
        setColumns([...cardElementList[indexValue].HeaderColumns])
        setOperationTable('Table')
        getpageStructurLink(cardElementList[indexValue]._id, 'All').then(result => {
          if (result.success) {
            setData(result.data)
            setIsLoading(false)
          } else {
            setData([])
            setIsLoading(false)
          }

        })
      } else {
        alert('Hello')
      }
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Page Manager | {url.title}</title>
        </MetaTags>
        <Container fluid>
          {
            operationsTable == 'Card' ?
              <Breadcrumbs
                title={"Dynamic Page Dashboard"}
                breadcrumbItem={"Page Manager"}
                bredcumlink={"dynamic-page-structure"}
              />
              : <Breadcrumbs
                title={'Page Manager'}
                breadcrumbItem={title}
                bredcumlink={'page-structure-link'}
              />
          }

          <Row>
            {
        
  
          allPageLoading?
          <UiLoading cardElementOption={!allPageLoading} />
          :
              operationsTable == 'Card' ?
                cardElement.map((d, index) => (
                  <Col sm={4} md={3} key={index}> 
                
                        <GalleryCardElementwithEvent
                          data={d}
                          changeTable={(e, type) => changeTable(e, type, index,d)}
                          addButton={d.addButton}
                          isLoading={isLoading}
                        />
                  </Col>
                ))
                :
                operationsTable == 'Table' ?
                  <Card>
                     <ReactTable
                          title={title}
                          datas={data}
                          changeTable={(e, type) => changeTable(e, type, indexValue)}
                          columns={columns}
                          addButton={addButton}
                          
                        />
                    

                  </Card>
                  :
              
                         
                          <InputFormElement
                            operationsTable={operationsTable}
                            singleItem={singleItem}

                            bredcumlink={'InformationPage'}
                            viewIcon={false}
                            filetype='single'

                            title={title}
                            changeTable={(e, type) => changeTable(e, type, indexValue,cardValue)}
                            inputType={inputType}
                            postData={postData}
                            isLoading={isLoading}
                          />
                      
                  
            }

          </Row>
        </Container>
      </div>
    </React.Fragment >
  )
}



export default pageStructureLink
