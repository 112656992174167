import React, { useState } from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { Card, CardBody, Row, Col, CardTitle, Button } from "reactstrap"
import { AvForm, AvField } from "availity-reactstrap-validation"
import ImageList from "UI/Image"
import TextEditor from "UI/TextEditor"
import SingleDropDown from "UI/SingleDropDown"
import MultipleDropDown from "UI/MultipledropDown"
import { postUploadFile,deleteUploadFile } from "data/fileuplaod"
import UiLoading from "UI/loading"

const InputFormElement = ({
  isLoading,
  postData,
  inputType,
  title,
  singleItem,
  changeTable,
  operationsTable,
  errorMessage,
  detedPurchaseProduct
}) => {
  const [changeDropDown,setChangeDropDown] = useState(false)
  const [singleItems, setSingleItems] = useState(singleItem)
  const [inputTypes, setInputTypes] = useState(inputType)
  const [imageLoading,setImageLoading] = useState(false)
  const  [imageMeassageloading,setImageLoadingMessage] = useState('')
  const [description, setDescription] = useState(
    singleItem.description !== undefined ? singleItem.description : ""
  )

  const selectValidation = (e, d) => {
    setChangeDropDown(true)
    singleItems[d.name] = e
    setSingleItems({ ...singleItems })
  }

  const onEditorChange = (e, d) => {
    setDescription(e.editor.getData())
  }
  const onChangeRadio = e => {
    const { name, value } = e.target

    if (value === "false" || value === false) {
      singleItems[name] = false
      setSingleItems({ ...singleItems })
    } else if (value === "true" || value === true) {
      singleItems[name] = true
      setSingleItems({ ...singleItems })
    } else  {
      singleItems[name] = value
      setSingleItems({ ...singleItems })
    }
  }
  const selctdropDownList = (e,select, d) => {
    if(select !== undefined){
      let difference = select.filter(x => !e.includes(x));
      console.log('Removed: ', difference); 
  
      if(detedPurchaseProduct !== undefined){
        detedPurchaseProduct(difference)
      }
    }

   
    const ds= []
    for (let i = 0; i < e.length; i++) {
      ds.push(e[i].value)
    }
   singleItems[d] = ds
    setSingleItems({ ...singleItems })
  }
  const uploadImage = async (e, name, type) => {
    console.log(name)
    setImageLoading(true)
    setImageLoadingMessage('Image Loading...')
    if(singleItems[name] !== undefined){
      const photoData = [...singleItems[name]]
      
      for (let i = 0; i < e.target.files.length; i++) {
        await postUploadFile(e.target.files[i], type).then(result => {
          if (result.success) {
            photoData.push(result.photo)
            setImageLoading(false)
            setImageLoadingMessage('Image Loading Successfully')
          } else {
            singleItems[name] = ""
            setSingleItems({ ...singleItems })
            setImageLoading(false)
            setImageLoadingMessage('Image Loading Error')
          }
        })
      }
      singleItems[name] = photoData
  
      setSingleItems({ ...singleItems })
    }else{
      const photoData = []
      
      for (let i = 0; i < e.target.files.length; i++) {
        await postUploadFile(e.target.files[i], type).then(result => {
          if (result.success) {
            photoData.push(result.photo)
          } else {
            singleItems[name] = ""
            setSingleItems({ ...singleItems })
          }
        })
      }
      singleItems[name] = photoData
  
      setSingleItems({ ...singleItems })
    }
   
  }
  const deleteUploadFiles = (e, ind, d,uploadFileType,index) => {
    console.log(d)
    console.log(uploadFileType)
    const inputTypeData = [...inputTypes]
   let  photoData = [...singleItems[inputTypeData[index].name]];
    const filteD  = photoData.filter((d,i)=> i !==ind);
    singleItems[inputTypeData[index].name] =filteD;
    setSingleItems({ ...singleItems })
   deleteUploadFile(photoData[ind],uploadFileType).then(result=>{
    console.log(result)
      if(result.success){
        // const filteD  = photoData.filter((d,i)=> i !==ind);
        // singleItems[type] =filteD;
        setSingleItems({ ...singleItems })
      }else{

      }
    })
  }
 
  const deletedAreaPincode=(area)=>{
    const pincodes =[...singleItems.pincode]
    const findInde = pincodes.findIndex(d=> d.area == area)
    const filterData = pincodes.filter((_,ik)=> ik !== findInde )
    singleItems.pincode =filterData
    setSingleItems({ ...singleItems })
  }
  const changeArea=(e,d,i)=>{
   const {name,value}= e.target;
    const pincodes =[...singleItems.pincode]
    pincodes[i].area=value
    singleItems.pincode =pincodes
    setSingleItems({ ...singleItems })
  }
  const changePincode=(e,d,i,picodeList)=>{
    const {name,value} =e.target;
    const pincodes =[...singleItems.pincode]
    pincodes[i].pincode=value.split(',')
    singleItems.pincode =pincodes
    setSingleItems({ ...singleItems })
    
  }
  const changeVarient=(e,i)=>{
    const {name,value} =e.target;
    const varientlist =[...singleItems.varientlist]
    console.log(varientlist)
    varientlist[i].name=value
    singleItems.varientlist =varientlist;
    setSingleItems({ ...singleItems })
  }
 const deletedVarient=(name)=>{
  const varientlist =[...singleItems.varientlist]
  const findInde = varientlist.findIndex(d=> d.name == name)
  const filterData = varientlist.filter((_,ik)=> ik !== findInde )
  singleItems.varientlist =filterData
  setSingleItems({ ...singleItems })
  }
  console.log(singleItems)
  return (
    <Card>
      <CardBody>
    
        <>
        <div className="row">
          <div className="col-md-11">
            <CardTitle className="mb-4">
              {operationsTable} {title}
            </CardTitle>
          </div>
          <div className="col-md-1">
            <Button
              color="success"
              onClick={e => changeTable(e, "Table", title)}
            >
              Back
            </Button>
          </div>
        </div>
        <p style={{color:'red',textAlign:'center'}}>{errorMessage}</p>
        <AvForm onValidSubmit={(e,value)=>postData(e,value,singleItems)}>
          <div className="row">
            {inputTypes.map((d, index) => (
              <>
                {d.type === "radio" ? (
                  <>
                    <AvField
                      type="hidden"
                      name={d.name}
                      className="form-control"
                      value={
                        singleItems[
                          Object.keys(singleItems).filter(k => k == d.name)
                        ] == false
                          ? "false"
                          : singleItems[
                              Object.keys(singleItems).filter(k => k == d.name)
                            ]
                      }
                      disabled={
                        operationsTable == "View" || operationsTable == "Delete"
                          ? true
                          : false
                      }
                    />
                    <Row>
                      <Col sm={`${d.gride !== undefined ? d.gride : 12}`}>
                      <div className="mb-3"></div>
                        <CardTitle className="mb-4">{d.title}</CardTitle>

                        <Row>
                          {d.statustype.map((s, index) => (
                            <Col
                              sm={`${s.gride !== undefined ? s.gride : 3}`}
                              key={index}
                            >
                              <div className="mb-3"></div>
                              <input
                                className="form-check-input"
                                type="radio"
                                name={`${s.name}`}
                                value={s.value}
                                checked={
                                  `${
                                    singleItems[
                                      Object.keys(singleItems).filter(
                                        k => k == s.name
                                      )
                                    ]
                                  }` == `${s.value}`
                                    ? true
                                    : false
                                }
                                onClick={e => onChangeRadio(e, d.name)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="exampleRadios1"
                              >
                                {s.title}
                              </label>
                            </Col>
                          ))}
                        </Row>
                      </Col>
                    </Row>
                  </>
                ) : d.type == "file" ? (
                  <Col sm={`${d.gride !== undefined ? d.gride : 12}`}>
                    {/* <div className="mb-3"></div> */}
                    <AvField
                      type="hidden"
                      name={`${d.name}`}
                      value={
                        singleItems[
                          Object.keys(singleItems).filter(k => k == d.name)
                        ] !== undefined
                          ? singleItems[
                              Object.keys(singleItems).filter(k => k == d.name)
                            ]
                          : ""
                      }
                      disabled={
                        operationsTable == "View" || operationsTable == "Delete"
                          ? true
                          : false
                      }
                    />
                    <ImageList
                      numberfile={d.multiple}
                      uploadImages={
                        singleItems[
                          Object.keys(singleItems).filter(k => k == d.name)
                        ] !== undefined
                          ? singleItems[
                              Object.keys(singleItems).filter(k => k == d.name)
                            ]
                          : d.value
                      }
                      uploadFileType={d.uploadtype}

                      uploadImage={e => uploadImage(e, d.name, d.uploadtype)}
                      deleteUploadFiles={(e, ind, d,uploadFileType) => deleteUploadFiles(e, ind, d,uploadFileType,index)}
                      // title={`${d.file !== undefined ? d.file : d.label}`}
                      label={`${d.label !== undefined ? d.label : d.file}`}
                      imageaccept={`${d.imageaccept}`}
                      typeOfImage={d.name}
                      imageLoading={imageLoading}
                      imageMeassageloading={imageMeassageloading}
                    />
                  </Col>
                ) : d.name == "description" ? (
                  <Col sm={`${d.gride !== undefined ? d.gride : 12}`}>
                    <div className="mb-3"></div>
                    <AvField
                      type="hidden"
                      name={`${d.name}`}
                      value={description}
                      disabled={
                        operationsTable == "View" || operationsTable == "Delete"
                          ? true
                          : false
                      }
                    />
                    <TextEditor
                      title={`${d.title}`}
                      description={description}
                      onEditorChange={e => onEditorChange(e, d.name)}
                      disabled={
                        operationsTable == "View" || operationsTable == "Delete"
                          ? true
                          : false
                      }
                    />
                  </Col>
                ) : d.type == "select" || d.type == "consType" ? (
                  <Col sm={`${d.gride !== undefined ? d.gride : 12}`}>
                    <div className="mb-3"></div>
                    <AvField
                      type="hidden"
                      name={`${d.name}`}
                      value={
                        singleItems[
                          Object.keys(singleItems).filter(k => k == d.name)
                        ] !== undefined
                          ?changeDropDown? singleItems[
                              Object.keys(singleItems).filter(k => k == d.name)
                            ].value:singleItems[
                              Object.keys(singleItems).filter(k => k == d.name)
                            ]
                          : ""
                      }
                    />
                    <SingleDropDown
                      title={`${d.title}`}
                      dropDownList={d.value}
                      selectDrop={
                        singleItems[d.name]
                        
                      }
                      selctdropDownList={e => selectValidation(e, d)}
                      disabled={
                      d.disabled
                          ? true
                          : false
                      }
                    />
                  </Col>
                ) : d.type == "multiple" || d.type == "multiConsType"? (
                  <Col sm={`${d.gride !== undefined ? d.gride : 12}`}>
                    <div className="mb-3"></div>
                  
                    <AvField
                      type="hidden"
                      name={`${d.name}`}
                      value={
                        singleItems[
                          Object.keys(singleItems).filter(k => k == d.name)
                        ] !== undefined
                          ? singleItems[
                              Object.keys(singleItems).filter(k => k == d.name)
                            ]
                          : []
                      }
                    />
                    <MultipleDropDown
                      title={d.title}
                      dropDownList={d.value}
                      selectDrop={
                        singleItems[
                          Object.keys(singleItems).filter(k => k == d.name)
                        ]
                      }
                      disabled={d.disabled}
                      selctdropDownList={(e,select) => selctdropDownList(e,select, d.name)}
                    />
                  </Col>
                ) :d.type == "dependecy" ? (
                  <Col sm={`${d.gride !== undefined ? d.gride : 12}`}>
                    <div className="mb-3"></div>
                  
                    <AvField
                      type="hidden"
                      name={`${d.name}`}
                      value={
                        singleItems[
                          Object.keys(singleItems).filter(k => k == d.name)
                        ] !== undefined
                          ? singleItems[
                              Object.keys(singleItems).filter(k => k == d.name)
                            ]
                          : []
                      }
                    />
                    <MultipleDropDown
                      title={d.title}
                      dropDownList={d.value}
                      selectDrop={
                        singleItems[
                          Object.keys(singleItems).filter(k => k == d.name)
                        ]
                      }
                      disabled={d.disabled}
                      selctdropDownList={(e,select) => selctdropDownList(e,select, d.name)}
                    />
                  </Col>
                ):d.type == "addmore" ? (
                  <Col sm={`${d.gride !== undefined ? d.gride : 12}`}>
                    
                    {singleItem[
                      Object.keys(singleItem).filter(k => k == d.name)
                    ] !== undefined ? (
                      <AvField
                        type="hidden"
                        name={`${d.name}`}
                        value={`${
                          singleItem[
                            Object.keys(singleItem).filter(k => k == d.name)
                          ]
                        }`}
                    
                      />
                    ) : d.value !== "" ? (
                      <AvField
                        type={`${d.type}`}
                        name={`${d.name}`}
                        value={`${d.value}`}
                        disabled={
                          operationsTable == "View" ||
                          operationsTable == "Delete"
                            ? true
                            : false
                        }
                      />
                    ) : (
                      ""
                    )}
                  </Col>
                ): d.type == "hiddens" ? (
                  <Col sm={`${d.gride !== undefined ? d.gride : 12}`}>
                    
                    {singleItem[
                      Object.keys(singleItem).filter(k => k == d.name)
                    ] !== undefined ? (
                      <AvField
                        type="hidden"
                        name={`${d.name}`}
                        value={`${
                          singleItem[
                            Object.keys(singleItem).filter(k => k == d.name)
                          ]
                        }`}
                    
                      />
                    ) : d.value !== "" ? (
                      <AvField
                        type={`${d.type}`}
                        name={`${d.name}`}
                        value={`${d.value}`}
                        disabled={
                          operationsTable == "View" ||
                          operationsTable == "Delete"
                            ? true
                            : false
                        }
                      />
                    ) : (
                      ""
                    )}
                  </Col>
                ):d.type == "hidden" ? (
                  <Col sm={`${d.gride !== undefined ? d.gride : 12}`}>
                    
                    {singleItem[
                      Object.keys(singleItem).filter(k => k == d.value)
                    ] !== undefined ? (
                      <AvField
                        type={`${d.type}`}
                        name={`${d.name}`}
                        value={`${
                          singleItem[
                            Object.keys(singleItem).filter(k => k == d.value)
                          ]
                        }`}
                        disabled={
                          operationsTable == "View" ||
                          operationsTable == "Delete"
                            ? true
                            : false
                        }
                      />
                    ) : d.value !== "" ? (
                      <AvField
                        type={`${d.type}`}
                        name={`${d.name}`}
                        value={`${d.value}`}
                        disabled={
                          operationsTable == "View" ||
                          operationsTable == "Delete"
                            ? true
                            : false
                        }
                      />
                    ) : d.value == "" ? (
                      <AvField
                        type={`${d.type}`}
                        name={`${d.name}`}
                        value={`${d.title}`}
                        disabled={
                          operationsTable == "View" ||
                          operationsTable == "Delete"
                            ? true
                            : false
                        }
                      />
                    ) : (
                      "")}
                  </Col>
                ) :  d.type == "inlineedi"?
               
                
                singleItems[d.name]!== undefined !== undefined &&  singleItems[d.name] !== null?
                      
                        d.name == 'varientlist' ?
                        singleItems[d.name].map((d,i)=>(
                          < >
                            <Col sm={2} lg={2} key={i}>
                              <AvField
                              name={`varient`}
                              value={d.name}
                              label={'Pincode'}
                              className="form-control"
                              onChange={(e)=>changeVarient(e,i)}
                              />
                            </Col>
                            
                            <Col sm={1} lg={1} >
                            <div className="mb-3"></div>
                            <div className="mb-3"></div>
                            <div className="mb-3"></div>
                            <Link to="#" className="text-danger">
                    <i
                        className="mdi mdi-delete font-size-18"
                      id="deletetooltip"
                      onClick={(e)=>deletedVarient(d.name)}
                    />
                  </Link>
  
                            </Col>
                      
                          </>
                        ))
                        :
                      
                      singleItems[d.name].map((d,i)=>(
                        <Row key={i}>
                          <Col sm={2} lg={2} >
                            <AvField
                            name={`area`}
                            value={d.area}
                            label={'Pincode'}
                            className="form-control"
                            onChange={(e)=>changeArea(e,d,i)}
                            />
                          </Col>
                          <Col sm={8} lg={8} >
                            <AvField
                            label="Pincode"
                            type="textarea"
                            name={`area`}
                            value={d.pincode.toString()}
                            className="form-control"
                            onChange={(e)=>changePincode(e,d,i,d.pincode.toString())}
                            />
                          </Col>
                          <Col sm={1} lg={1} >
                          <div className="mb-3"></div>
                          <div className="mb-3"></div>
                          <div className="mb-3"></div>
                          <Link to="#" className="text-danger">
                  <i
                      className="mdi mdi-delete font-size-18"
                    id="deletetooltip"
                    onClick={(e)=>deletedAreaPincode(d.area)}
                  />
                </Link>

                          </Col>
                    
                        </Row>
                      ))
                      :''
                
                :d.type == "slug" ? (
                  
                  <Col sm={`${d.gride !== undefined ? d.gride : 12}`}>
                    <div className="mb-3"></div>
                  <AvField
                    type='text'
                    name='slug'
                    className="form-control"
                    id="taxtype-Input"
                   
                    value={`${slug}`}
                   
                    label={
                      <>
                        {title} {d.title}{" "}
                        {d.required != "" ? (
                          <span style={{ color: "red" }}>*</span>
                        ) : (
                          ""
                        )}{" "}
                      </>
                    }
                    errorMessage="This is required"
                    disabled={
                      operationsTable == "View" || operationsTable == "Delete"
                        ? true
                        : false
                    }
                  />
                </Col>
                
                ):
                (
                  <Col sm={`${d.gride !== undefined ? d.gride : 12}`}>
                    <div className="mb-3"></div>
                    <AvField
                      type={`${d.type}`}
                      name={`${d.name}`}
                      className="form-control"
                      id="taxtype-Input"
                      // disabled={disableddata}
                     
                      value={`${
                        d.type === "date"
                          ? singleItem[
                              Object.keys(singleItem).filter(k => k == d.name)
                            ] !== undefined
                            ? singleItem[
                                Object.keys(singleItem).filter(k => k == d.name)
                              ].split("T")[0]
                            : ""
                          : singleItem[
                              Object.keys(singleItem).filter(k => k == d.name)
                            ] !== undefined
                          ? singleItem[
                              Object.keys(singleItem).filter(k => k == d.name)
                            ]
                          : ""
                      }`}
                      required={`${d.required}`}
                      label={
                        <>
                          {d.title}{" "}
                          {d.required != "" ? (
                            <span style={{ color: "red" }}>*</span>
                          ) : (
                            ""
                          )}{" "}
                        </>
                      }
                      errorMessage="This is required"
                      disabled={
                        operationsTable == "View" || operationsTable == "Delete"  || d.disabled 
                          ? true
                          : false
                      }
                    
                    />
                  </Col>
                )}
              </>
            ))}
          </div>

          <Row>
            <div className="mb-3"></div>
            <div className="mb-3"></div>
            <div className="mb-3"></div>
            <div className="mb-3"></div>
            <div className="mb-3"></div>
            <div className="mb-3"></div>
            <div className="mb-3"></div>
            <div className="mb-3"></div>

            <Col lg={2}></Col>
            <Col lg={10}>
              <Row>
                <Col lg={11}></Col>

                <Col lg={1}>
                  <div className="mb-3">
                    <Button
                      type="submit"
                      color="success"
                      disabled={
                        operationsTable == "View" || operationsTable == "Delete"
                          ? true
                          : false
                      }
                    >
                      {operationsTable}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </AvForm>
        </>
      
      </CardBody>
    </Card>
  )
}
InputFormElement.propTypes = {
  isLoading: PropTypes.bool,
  inputType: PropTypes.array,
  singleItem: PropTypes.object,
  changeTable: PropTypes.func,
  title: PropTypes.string,
  operationsTable: PropTypes.string,
  postData: PropTypes.func,
  errorMessage:PropTypes.string,
  detedPurchaseProduct:PropTypes.func
}
export default InputFormElement
